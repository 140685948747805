



// data.js

const data = [

   {
      id : 41519651,
      "Title" : "Angaaragan", 
      "IMDbRating" : "5.9", 
      "RunningTime" : "128 minutes", 
      "Genres" : "N/A", 
      "Introduction" : "About mysterious events that unfold in a forest resort during a twelve-hour investigation in a non-linear play. It highlights multiple hidden secrets of paranormal activities related to a late-Queen who was the first owner of this estate.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODZiMDAyY2EtMGQyZi00Yjc2LTljMzItNjkxNjU2ZTM1OWY3XkEyXkFqcGdeQXVyMTA4MzQ4NzMw._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Angaaragan%20(2023)/Angaaragan%20(2023)%20Tamil%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Angaaragan%20(2023)/Angaaragan%20(2023)%20Tamil%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20720p.mp4"},
        "SD480p":{ "size" : "816 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Angaaragan%20(2023)/Angaaragan%20(2023)%20Tamil%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://mega.nz/file/Uz9m3B6J#SEnQ4Oud1XreeBIu9fhEbzks3VQ-wCop_MxsLDoTmy4"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://mega.nz/file/czsWwJwR#sMatv4uCUQljEvbeaRpcVczHFAHcYKe_BWOg6XRU5Ts"},
        "SD480p":{ "size" : "816 MB", "Link": "https://mega.nz/file/V70jBYTQ#VHB1KZ60Amx0M2dsVxxubkP8uqfQyRqSB3N52wcnEIM"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://ddl.sinhalasub.net/AgADER1909"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://ddl.sinhalasub.net/AgADtQ1907"},
        "SD480p":{ "size" : "816 MB", "Link": "https://ddl.sinhalasub.net/AgADtA1905"}
       }
   },
   
   {
      id : 49575845,
      "Title" : "Black Noise", 
      "IMDbRating" : "2.7", 
      "RunningTime" : "86 minutes", 
      "Genres" : "Action, Sci-Fi", 
      "Introduction" : "Members of an elite security team deployed to rescue a VIP on an exclusive island.The rescue mission becomes a desperate attempt to survive, escape the island and elude the sinister presence that seeks to harm them.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOTkzNzYzMzUtZGY4Mi00ZTNiLWJkNDQtMGNhMjQ0MDY3YThmXkEyXkFqcGdeQXVyMTQ5NjM1NjYy._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.15 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Black%20Noise%20(2023)/Black%20Noise%20(2023)%20English%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.06 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Black%20Noise%20(2023)/Black%20Noise%20(2023)%20English%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20720p.mp4"},
        "SD480p":{ "size" : "550 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Black%20Noise%20(2023)/Black%20Noise%20(2023)%20English%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.15 GB", "Link": "https://mega.nz/file/s3FSkLDD#gkml7kHVTnCeVg5zdqd6G_J41ATwqy_nLgXmJjB16iA"},
        "HD720p":{ "size" : "1.06 GB", "Link": "https://mega.nz/file/NqtxmJZT#Rei5ex0sEoUCEzIMXLOsu-pwi8t-P6hR4hrPfICLqrQ"},
        "SD480p":{ "size" : "550 MB", "Link": "https://mega.nz/file/0381AQ6Z#pekwOB_XONoF5Bk8zbRylt8pQbuGypIVYAUieb7RsgM"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.15 GB", "Link": "https://ddl.sinhalasub.net/AgADlR1903"},
        "HD720p":{ "size" : "1.06 GB", "Link": "https://ddl.sinhalasub.net/AgADMg1901"},
        "SD480p":{ "size" : "550 MB", "Link": "https://ddl.sinhalasub.net/AgADMQ1899"}
       }
   },
   
   {
      id : 17163421,
      "Title" : "Killers of the Flower Moon", 
      "IMDbRating" : "8.0", 
      "RunningTime" : "206 minutes", 
      "Genres" : "Crime, Drama, History, Mystery, Romance, Western", 
      "Introduction" : "When oil is discovered in 1920s Oklahoma under Osage Nation land, the Osage people are murdered one by one - until the FBI steps in to unravel the mystery.", 
       "MovieImage" : "https://resizing.flixster.com/GGQKtXozu58SHWKH0Cz-5jQ9U38=/ems.cHJkLWVtcy1hc3NldHMvbW92aWVzLzZhOWE1N2ZkLTY4MzgtNDA3Yi05MDEwLWQzN2QwMTFkZDdmNS5qcGc=",
    "severid3":{
        "FHD1080p":{ "size" : "3.24 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Moon%20(2023)/The%20Moon%20(2023)%20Korean%20WEBRip%20%5BSinhalaSub.Net%5D%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.59 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Moon%20(2023)/The%20Moon%20(2023)%20Korean%20WEBRip%20%5BSinhalaSub.Net%5D%20-%20720p.mp4"},
        "SD480p":{ "size" : "822 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Moon%20(2023)/The%20Moon%20(2023)%20Korean%20WEBRip%20%5BSinhalaSub.Net%5D%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.24 GB", "Link": "https://mega.nz/file/gzcThK7R#BrZDmERyMO_ntCGKUhP23Efv_QsduLhou1VGdBWYvAw"},
        "HD720p":{ "size" : "1.59 GB", "Link": "https://mega.nz/file/c6sUkAAI#mSbgrJmb7VTiK_3JtaIhN904ChbX2x2AfX2cKMdMTpE"},
        "SD480p":{ "size" : "822 MB", "Link": "https://mega.nz/file/xyVSiLrY#Dal91mtJ9QT8z_dprdRza2KG7SVH_ByBlgHFjvdQdFI"},

      },
      "severid1":{
        "FHD1080p":{ "size" : "3.24 GB", "Link": "https://ddl.sinhalasub.net/AgADkB1897"},
        "HD720p":{ "size" : "1.59 GB", "Link": "https://ddl.sinhalasub.net/AgADJQ1895"},
        "SD480p":{ "size" : "822 MB", "Link": "https://ddl.sinhalasub.net/AgADJg1893"}
       }
   },

   
   {
      id : 17103863,
      "Title" : "Perdida", 
      "IMDbRating" : "6.0", 
      "RunningTime" : "115 minutes", 
      "Genres" : "Romance", 
      "Introduction" : "Film adaptation of the best-seller Perdida, by Carina Rissi.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMTBmY2U4YmItZDdlYi00ZTM2LWE0NDQtMmE2ZDM1MTlhZWE0XkEyXkFqcGdeQXVyMjQ3NzUxOTM@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Perdida%20(2023)/Perdida%20(2023)%20%20WEB-DL%20%5BSinhalaSub.LK%5D%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Perdida%20(2023)/Perdida%20(2023)%20%20WEB-DL%20%5BSinhalaSub.LK%5D%20-%20720p.mp4"},
        "SD480p":{ "size" : "736 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Perdida%20(2023)/Perdida%20(2023)%20%20WEB-DL%20%5BSinhalaSub.LK%5D%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://mega.nz/file/xiFXjDYB#7AewUc98xmQbI65ZhCrsrYf7oNLnckcfwTA6IVfYcGE"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://mega.nz/file/c6NzxIKZ#MGh1J-6InQ8TgT_1k463nSOjKZK5rJdUUKferMj9dOM"},
        "SD480p":{ "size" : "736 MB", "Link": "https://mega.nz/file/kv10QSCK#ypzO6TwmOppiqEHbo0fhvVO_9Vsy4l8RdBzB7Sb4BxI"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://ddl.sinhalasub.net/AgADTg1806"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://ddl.sinhalasub.net/AgADjh1804"},
        "SD480p":{ "size" : "736 MB", "Link": "https://ddl.sinhalasub.net/AgADkB1802"}
       }
   },
   
   {
      id : 35224187,
      "Title" : "Ghost", 
      "IMDbRating" : "8.1", 
      "RunningTime" : "132 minutes", 
      "Genres" : "Action, Thriller", 
      "Introduction" : "A Group of some people hijacked the jail in the city and demanding the release of ghost or elsewhere all the criminals will be set free.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNTlmYTU1YjktN2Y3MS00OGU2LWE2NzUtNWE4MzdmNWI3OGFhXkEyXkFqcGdeQXVyMTU1MjYxNTc4._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.14 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ghost%20(2023)/Ghost%20(2023)%20Kannada%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.55 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ghost%20(2023)/Ghost%20(2023)%20Kannada%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20720p.mp4"},
        "SD480p":{ "size" : "803 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ghost%20(2023)/Ghost%20(2023)%20Kannada%20WEB-DL%20%5BSinhalaSub.Net%5D%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.14 GB", "Link": "https://mega.nz/file/xjsD3YrK#5uTcU9hIeuetI40e8qoTfPhvpFNXnCXNtyqcuYaduQ4"},
        "HD720p":{ "size" : "1.55 GB", "Link": "https://mega.nz/file/9ykmVTSb#efRHIN26sTa-raUgcmgu9PXDk6oIBn7NET0DLbnL6kQ"},
        "SD480p":{ "size" : "803 MB", "Link": "https://mega.nz/file/xn1mRA5C#VjU7axgQ5zKOu84AnEwWMyo1ju0foLga0SK2XZdN2OM"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.14 GB", "Link": "https://ddl.sinhalasub.net/AgADWR1732"},
        "HD720p":{ "size" : "1.55 GB", "Link": "https://ddl.sinhalasub.net/AgADAh1730"},
        "SD480p":{ "size" : "803 MB", "Link": "https://ddl.sinhalasub.net/AgADAR1728"}
       }
   },
   
   {
      id : 56371753,
      "Title" : "Tiger 3", 
      "IMDbRating" : "7.6", 
      "RunningTime" : "154 minutes", 
      "Genres" : "Action, Adventure, Thriller", 
      "Introduction": "Tiger and Zoya are back - to save the country and their family. This time it's personal.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYzQwMGZlYTUtODUwNi00ZjQxLWEzODAtZGU3Zjc0MmNhMzhkXkEyXkFqcGdeQXVyNTkzNDQ4ODc@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.68 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/5v875cantgdehn4"},
        "HD720p":{ "size" : "1.91 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/5uhl5irye1zgylz"},
        "SD480p":{ "size" : "989 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/4xwt67n5pp7vrop"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.68 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Tiger%203%20(2023)/Tiger%203%20(2023)%20Hindi%20CAMCopy%201080p.mp4"},
        "HD720p":{ "size" : "1.91 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Tiger%203%20(2023)/Tiger%203%20(2023)%20Hindi%20CAMCopy%20720p.mp4"},
        "SD480p":{ "size" : "989 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Tiger%203%20(2023)/Tiger%203%20(2023)%20Hindi%20CAMCopy%20480p.mp4"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.68 GB", "Link": "https://mega.nz/file/BukWzAzZ#e1utkIfpd6VeOM8HKVJCMOHmuBA-nMnyTRM_heL6r_U"},
        "HD720p":{ "size" : "1.91 GB", "Link": "https://mega.nz/file/Ay02SDaK#Ck8_lOUIgNBixfz0p2qEXtrkUeXsKQy-ZWPSHXgQjiQ"},
        "SD480p":{ "size" : "989 MB", "Link": "https://mega.nz/file/5rVH3S6L#v0eGOT2Fi6q7z4m4QXqEW5DNlAFb71fVrG-xSPb71BI"}
       }
   },
   
   {
      id : 39520411,
      "Title" : "My Sweet Monster", 
      "IMDbRating" : "4.9", 
      "RunningTime" : "98 minutes", 
      "Genres" : "Animation, Adventure, Family, Fantasy", 
      "Introduction" : "Princess Barbara is secretly in love with Prince Edward. When the sneaky post clerk Weasel demands the king to marry him to Barbara, she flees. Sweet monster Boogey does everything in his might to help Barbara save the kingdom.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZDZkMDYxZjAtNzVmYS00NmJjLWIzYzgtYjZiMTI3ZjVjYzA1XkEyXkFqcGdeQXVyOTg4MDYyNw@@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.40 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/My%20Sweet%20Monster%20(2021)/My%20Sweet%20Monster%20(2021)%20English%20WEBRip%201080p.mp4"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/My%20Sweet%20Monster%20(2021)/My%20Sweet%20Monster%20(2021)%20English%20WEBRip%20720p.mp4"},
        "SD480p":{ "size" : "618 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/My%20Sweet%20Monster%20(2021)/My%20Sweet%20Monster%20(2021)%20English%20WEBRip%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.40 GB", "Link": "https://mega.nz/file/krsG3bAI#tBeHGJPSbVc4SK3aLc4LKowVPb2KEzVqaKAFOBuU3gI"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://mega.nz/file/Ij8mmIDY#NMHipEeUrOQKE_KPHpqEFSpl8JQUJAsoU7ggnBVY-4U"},
        "SD480p":{ "size" : "618 MB", "Link": "https://mega.nz/file/Zqkl3DyK#HixNe4PCCeGHEzuO5CofWDOnHjRFHYGerOKYrPreIt4"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.40 GB", "Link": "https://ddl.sinhalasub.net/AgADLx1711"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://ddl.sinhalasub.net/AgAD2R1709"},
        "SD480p":{ "size" : "618 MB", "Link": "https://ddl.sinhalasub.net/AgAD2B1707"}
       }
   },
   
   {
      id : 126247,
      "Title" : "The Creator", 
      "IMDbRating" : "6.9", 
      "RunningTime" : "133 minutes", 
      "Genres" : "Action, Adventure, Drama, Sci-Fi, Thriller", 
      "Introduction" : "Against the backdrop of a war between humans and robots with artificial intelligence, a former soldier finds the secret weapon, a robot in the form of a young child.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNDUyNTIzNDQtYTZmMi00M2FlLTgyZjUtYWViZWNhMDYzMjE4XkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.33 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Creator%20(2023)/The%20Creator%20(2023)%20English%20WEB-DL%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.64 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Creator%20(2023)/The%20Creator%20(2023)%20English%20WEB-DL%20-%20720p.mp4"},
        "SD480p":{ "size" : "851 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Creator%20(2023)/The%20Creator%20(2023)%20English%20WEB-DL%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.33 GB", "Link": "https://mega.nz/file/VnljBbLC#a9iuFChOo1UsUxOlt0t13fodzkPtKmB_XIQ1OtxBiL4"},
        "HD720p":{ "size" : "1.64 GB", "Link": "https://mega.nz/file/drMhxLYD#LnJRPGqAOk6VKBG0IL6KQZaiWZ4nA2hGh6vbQpL34ck"},
        "SD480p":{ "size" : "851 MB", "Link": "https://mega.nz/file/AuV1WR6I#PZUNiiFYZJr7SWVjPF1hU4gdSLu250Q1MEnrmSz8m1I"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.33 GB", "Link": "https://ddl.sinhalasub.net/AgADJR1705"},
        "HD720p":{ "size" : "1.64 GB", "Link": "https://ddl.sinhalasub.net/AgADrB1703"},
        "SD480p":{ "size" : "851 MB", "Link": "https://ddl.sinhalasub.net/AgADrR1701"}
       }
   },
   
   {
      id : 27016896,
      "Title" : "Rockstar", 
      "IMDbRating" : "7.7", 
      "RunningTime" : "159 minutes", 
      "Genres" : "Drama, Music, Romance", 
      "Introduction" : "Janardhan Jakhar chases his dreams of becoming a big Rock star, during which he falls in love with Heer.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOTc3NzAxMjg4M15BMl5BanBnXkFtZTcwMDc2ODQwNw@@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.99 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Rockstar%20(2011)/Rockstar%20(2011)%20Hindi%20BluRay%201080p.mp4"},
        "HD720p":{ "size" : "1.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Rockstar%20(2011)/Rockstar%20(2011)%20Hindi%20BluRay%20720p.mp4"},
        "SD480p":{ "size" : "1012 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Rockstar%20(2011)/Rockstar%20(2011)%20Hindi%20BluRay%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.99 GB", "Link": "https://mega.nz/file/07d1SZKJ#KVBsryKdJX8cQyiOD5oYm4OnxzniUg4KUzI-nRDMRp4"},
        "HD720p":{ "size" : "1.96 GB", "Link": "https://mega.nz/file/4iNjnJJK#H3KIvyNa_WM5uH8_ar941lA25G3OJFWhlvQvdLm1ZdY"},
        "SD480p":{ "size" : "1012 MB", "Link": "https://mega.nz/file/gusHGA6Q#uzn_8cS2SVU9qUaKy2f2gZ2n5V_dpD65uiD4A678hoI"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.99 GB", "Link": "https://ddl.sinhalasub.net/AgADXg1699"},
        "HD720p":{ "size" : "1.96 GB", "Link": "https://ddl.sinhalasub.net/AgADXw1697"},
        "SD480p":{ "size" : "1012 MB", "Link": "https://ddl.sinhalasub.net/AgADxB1695"}
       }
   },
   
   {
      id : 61332941,
      "Title" : "Anukoni Athidhi", 
      "IMDbRating" : "7.0", 
      "RunningTime" : "136 minutes", 
      "Genres" : "Drama, Mystery, Romance, Thriller", 
      "Introduction" : "In an isolated hospital in the high ranges of Kerala, an autistic patient with special skills piques the interest of a psychiatrist, whose investigation into her past leads to startling revelations.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODM5MzdmNGUtYmY1MS00Y2I4LTk0YzktZjE0MTk1ZDExMzQ0XkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.39 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Athiran%20(2019)/Athiran%20(2019)%20Malayalam%20DVDRip%201080p.mp4"},
        "HD720p":{ "size" : "1.67 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Athiran%20(2019)/Athiran%20(2019)%20Malayalam%20DVDRip%20720p.mp4"},
        "SD480p":{ "size" : "862 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Athiran%20(2019)/Athiran%20(2019)%20Malayalam%20DVDRip%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.39 GB", "Link": "https://mega.nz/file/srkXWTCQ#R-xL9PFdiD8TAmkoKuOZkTIM9F_x_h4yaOw0nDvr8b4"},
        "HD720p":{ "size" : "1.67 GB", "Link": "https://mega.nz/file/QudT1YwT#Czr7RebMnneRupOXahKNOkALMb6L9YaC6N39V1Hbykg"},
        "SD480p":{ "size" : "862 MB", "Link": "https://mega.nz/file/9uk0wAKA#C-3EPXyF7iHed4XPvJIRLtN2mlHhpjgObZTlfAAP9BA"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.39 GB", "Link": "https://ddl.sinhalasub.net/AgADjA1561"},
        "HD720p":{ "size" : "1.67 GB", "Link": "https://ddl.sinhalasub.net/AgADOR1559"},
        "SD480p":{ "size" : "862 MB", "Link": "https://ddl.sinhalasub.net/AgADOh1557"}
       }
   },
   
   {
      id : 54876371,
      "Title" : "All the Bright Places", 
      "IMDbRating" : "6.6", 
      "RunningTime" : "107 minutes", 
      "Genres" : "Drama, Romance", 
      "Introduction": "The story of Violet and Theodore, who meet and change each others lives forever. As they struggle with the emotional and physical scars of their past, they discover that even the smallest places and moments can mean something.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BY2FkY2E1OTgtYmFhYi00NzczLThjYjktNDljN2ZiZGY4OGIzXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.69 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/All%20The%20Bright%20Places%20(2020)/All%20The%20Bright%20Places%20(2020)%20English%20WEBRip%201080p.mp4"},
        "HD720p":{ "size" : "1.33 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/All%20The%20Bright%20Places%20(2020)/All%20The%20Bright%20Places%20(2020)%20English%20WEBRip%20720p.mp4"},
        "SD480p":{ "size" : "689 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/All%20The%20Bright%20Places%20(2020)/All%20The%20Bright%20Places%20(2020)%20English%20WEBRip%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.69 GB", "Link": "https://mega.nz/file/0jEngDJS#YPdKK2CdOPrZpuOGibYgy09JXbq3fZG8ZDgQ1txt2I4"},
        "HD720p":{ "size" : "1.33 GB", "Link": "https://mega.nz/file/MyNEHChC#__uzjK7NudihTn4AOqe1dH8zZJRR1UxbA84jJBRzOBI"},
        "SD480p":{ "size" : "689 MB", "Link": "https://mega.nz/file/06EyRSbb#bpaxOY8lFBc-lk170HXffzIognwPZSDUheNwGFvWdqM"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.69 GB", "Link": "https://ddl.sinhalasub.net/AgADqw1685"},
        "HD720p":{ "size" : "1.33 GB", "Link": "https://ddl.sinhalasub.net/AgADlw1683"},
        "SD480p":{ "size" : "689 MB", "Link": "https://ddl.sinhalasub.net/AgADlg1681"}
       }
   },
   

   {
      id : 38691964,
      "Title" : "Charlie and the Chocolate Factory", 
      "IMDbRating" : "6.7", 
      "RunningTime" : "115 minutes", 
      "Genres" : "Adventure, Comedy, Family, Fantasy, Musical", 
      "Introduction": "A young boy wins a tour through the most magnificent chocolate factory in the world, led by the worlds most unusual candy maker.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNjcxMjg1Njg2NF5BMl5BanBnXkFtZTcwMjQ4NzMzMw@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Charlie%20and%20the%20Chocolate%20Factory%20(2005)/Charlie%20and%20the%20Chocolate%20Factory%20(2005)%20English%20BrRip%201080p.mp4"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Charlie%20and%20the%20Chocolate%20Factory%20(2005)/Charlie%20and%20the%20Chocolate%20Factory%20(2005)%20English%20BrRip%20720p.mp4"},
        "SD480p":{ "size" : "737 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Charlie%20and%20the%20Chocolate%20Factory%20(2005)/Charlie%20and%20the%20Chocolate%20Factory%20(2005)%20English%20BrRip%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://mega.nz/file/diNmCQYD#CYwmnhrO0AL9zjLBoi_Ue_5Rg_wpMGw0WHFirCdMD3o"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://mega.nz/file/1y92gQZQ#C7TKu7nZ1ja-V1a3v9zrPy3w2C_FBRAqCXe4xaD7_dQ"},
        "SD480p":{ "size" : "737 MB", "Link": "https://mega.nz/file/c6F3DTxS#JAvSlbGy_1ROIhQVcx78xnSRiY7_WMKUIv_mcHps4Dk"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.89 GB", "Link": "https://ddl.sinhalasub.net/AgAD-R1677"},
        "HD720p":{ "size" : "1.42 GB", "Link": "https://ddl.sinhalasub.net/AgADQx1675"},
        "SD480p":{ "size" : "737 MB", "Link": "https://ddl.sinhalasub.net/AgADQh1673"}
       }
   },
   
   {
      id : 22305424,
      "Title" : "Prema katha Chitram 2", 
      "IMDbRating" : "3.4", 
      "RunningTime" : "134 minutes", 
      "Genres" : "Thriller", 
      "Introduction": "Bindu (Siddhi Idnani) is head over heels for Sudheer (Sumanth Ashwin) but he falls for another girl Nandu (Nandita Swetha). Bindu who is unable to bear that commits suicide. Her spirit possesses Nandu and gives a hard time to Sudheer and his friends. How Sudheer drives the spirit away from Nandu s body and saves her life is the rest of the story of Prema Kadha Chitram 2.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2JkY2ZiZDEtNThlYS00MTBkLTg1NTEtNTVkNTY5YTI4YjI5XkEyXkFqcGdeQXVyNjE4MzM2Mjk@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "4.04 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Katha%20Chitram%202%20(2019)/Prema%20Katha%20Chitram%202%20(2019)%20Telugu%20Proper%20HDRip%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.62 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Katha%20Chitram%202%20(2019)/Prema%20Katha%20Chitram%202%20(2019)%20Telugu%20Proper%20HDRip%20-%20720p.mp4"},
        "SD480p":{ "size" : "851 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Katha%20Chitram%202%20(2019)/Prema%20Katha%20Chitram%202%20(2019)%20Telugu%20Proper%20HDRip%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "4.04 GB", "Link": "https://mega.nz/file/w2s0jLSC#CwU1Clf9FkLvxT40jXPbIXrmskJC1bzMqvSJPdIGBjg"},
        "HD720p":{ "size" : "1.62 GB", "Link": "https://mega.nz/file/wn81VLCb#lBl7YahH7tiYVDRbPABew_ksctBuNIhPW58mnMEH-ZM"},
        "SD480p":{ "size" : "851 MB", "Link": "https://mega.nz/file/xzdTHJ6C#dayfn1ZrOopSHGpf5POQ25No8Z2ZtGAzX8lAEwUuYEQ"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.31 GB", "Link": "https://ddl.sinhalasub.net/AgAD0R1679"},
        "HD720p":{ "size" : "1.62 GB", "Link": "https://ddl.sinhalasub.net/AgADCB1670"},
        "SD480p":{ "size" : "851 MB", "Link": "https://ddl.sinhalasub.net/AgADBx1668"}
       }
   },
   
   {
      id : 63968493,
      "Title" : "Contorted", 
      "IMDbRating" : "4.7", 
      "RunningTime" : "91 minutes", 
      "Genres" : "Horror, Mystery", 
      "Introduction": "A family moves to a quiet and isolated place, but suffers from nightmares on their very first day in the new home. The wife is anxious about a strange sound she hears in the warehouse, but her husband ignores her concern. Meanwhile, the family's nightmares are getting worse, to the point that they can hardly tell the difference between dream and reality.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTFjMTY2OGUtMGViYS00OThjLTg0NzctYmQ5ZTcxYWNhY2ZlXkEyXkFqcGdeQXVyMTA5NzUzODM4._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.26 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Contorted%20(2022)/Contorted%20(2022)%20Korean%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.12 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Contorted%20(2022)/Contorted%20(2022)%20Korean%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "579 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Contorted%20(2022)/Contorted%20(2022)%20Korean%20WEB-DL%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.26 GB", "Link": "https://mega.nz/file/Mudm3SoI#jJwtV6sPiqROynZGf5f_5XEco9VlMxSp2cMY_h6fGZI"},
        "HD720p":{ "size" : "1.12 GB", "Link": "https://mega.nz/file/d2kCQS4b#xLWf8NX0Q414s7w7995lSSdpk6AX4TyCV27sStU5v7A"},
        "SD480p":{ "size" : "579 MB", "Link": "https://mega.nz/file/8yskBQgT#5mrd64ofwugxr2M-H2Y_LDkDVXa6NCGpmg5Jgz9B2-A"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.26 GB", "Link": "https://ddl.sinhalasub.net/AgADJA1666"},
        "HD720p":{ "size" : "1.12 GB", "Link": "https://ddl.sinhalasub.net/AgADUw1664"},
        "SD480p":{ "size" : "579 MB", "Link": "https://ddl.sinhalasub.net/AgADUg1662"}
       }
   },
   
   {
      id : 26518300,
      "Title" : "When Evil Lurks", 
      "IMDbRating" : "7.0", 
      "RunningTime" : "99 minutes", 
      "Genres" : "Horror", 
      "Introduction" : "In a remote village, two brothers find a demon-infected man just about to give birth to evil itself. They decide to get rid of the body, only to end up unintentionally spreading chaos.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODE4Y2NlZTYtMGYxNy00MGI2LTkzZjEtMDNkNDVkMmMwZjkyXkEyXkFqcGdeQXVyMTE1MzI2NzIz._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.50 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/i8tzgffu2j0e4h3"},
        "HD720p":{ "size" : "1.23 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/ov5ojm7y5lemln0"},
        "SD480p":{ "size" : "636 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/b0f3eleapnq51jg"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.50 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/When%20Evil%20Lurks%20(2023)/When%20Evil%20Lurks%20(2023)%20English%20WEB%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.23 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/When%20Evil%20Lurks%20(2023)/When%20Evil%20Lurks%20(2023)%20English%20WEB%20-%20720p.mp4"},
        "SD480p":{ "size" : "636 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/When%20Evil%20Lurks%20(2023)/When%20Evil%20Lurks%20(2023)%20English%20WEB%20-%20480p.mp4"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.50 GB", "Link": "https://mega.nz/file/s6sngCqC#q3_cxHfbVyLXrtk8P_V6dzTvxs5ximNVeGgo3bfsVkk"},
        "HD720p":{ "size" : "1.23 GB", "Link": "https://mega.nz/file/oysixQJQ#4iCYrjUH3rhHJJvXNKNcI9Kd-uLAsqVxrC89o8l89B8"},
        "SD480p":{ "size" : "636 MB", "Link": "https://mega.nz/file/17lWQQzI#VWZuPeMvPMlMbAHRAAAlpEapNHYziSmC94OkuWqAkPo"}
       }
   },
   
   {
      id : 57478835,
      "Title" : "Muzzle", 
      "IMDbRating" : "5.4", 
      "RunningTime" : "100 minutes", 
      "Genres" : "Action, Crime, Thriller", 
      "Introduction" : "After his dog is killed in Skid Row, Jake Rosser plummets deep into a sinister underworld to uncover the truth about who may be responsible.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNTE1NGQ1OGMtODYzOC00OGFhLWFlOGQtN2ZjNGYzYjk2NzRmXkEyXkFqcGdeQXVyNjE2MzI5ODM@._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/i6iy9fnbs4n0wld"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/qp0cdqli8d6g2dz"},
        "SD480p":{ "size" : "635 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/ui7jb1h7zp0u39k"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Muzzle%20(2023)/Muzzle%20(2023)%20English%20WEB-DL-%201080p.mp4"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Muzzle%20(2023)/Muzzle%20(2023)%20English%20WEB-DL-%20720p.mp4"},
        "SD480p":{ "size" : "635 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Muzzle%20(2023)/Muzzle%20(2023)%20English%20WEB-DL-%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://mega.nz/file/dnE1yDbS#lfzoaLR-XoZDSrn3iJ6Sj30OSkJUNYsi954vx-pY-Sc"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://mega.nz/file/0r9XlYTb#IUzvTFPIuH5fh6P7Ezp_6BdXJCndCtQ0wHtARg7qKw4"},
        "SD480p":{ "size" : "635 MB", "Link": "https://mega.nz/file/FjUm1CjA#2uhCfK3mlW6RDhKMXqVG6_TU7URGWcxp0zX9u7xJJ6o"}
       }
   },
   
   {
      id : 64846697,
      "Title" : "The Exorcist: Believer", 
      "IMDbRating" : "4.9", 
      "RunningTime" : "111 minutes", 
      "Genres" : "Horror", 
      "Introduction": "When two girls disappear into the woods and return three days later with no memory of what happened to them, the father of one girl seeks out Chris MacNeil, who's been forever altered by what happened to her daughter fifty years ago.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BN2M3ODhmOTItMmZmMS00ZGE4LTkzMDQtMWIwNzhiODBkODA1XkEyXkFqcGdeQXVyMTY3ODkyNDkz._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.77 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Exorcist%20Believer%20(2023)/The%20Exorcist%20Believer%20(2023)%20English%20WEB%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.37 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Exorcist%20Believer%20(2023)/The%20Exorcist%20Believer%20(2023)%20English%20WEB%20-%20720p.mp4"},
        "SD480p":{ "size" : "709 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Exorcist%20Believer%20(2023)/The%20Exorcist%20Believer%20(2023)%20English%20WEB%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.77 GB", "Link": "https://mega.nz/file/d30QzCSB#MclSwYCTXC4vAkf6M2XZPvLhIiJarEFMocfrWc8aP9A"},
        "HD720p":{ "size" : "1.37 GB", "Link": "https://mega.nz/file/snM1Fb5L#6PyqJ0UivxmyRypOfqxZWnZboRNWLu7u-fHcd_R2dKg"},
        "SD480p":{ "size" : "709 MB", "Link": "https://mega.nz/file/JytQDRRa#q6O2TqUphpvn0NBd3cxEFkQOfrwsjNOkyZ0ljcGafKg"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.77 GB", "Link": "https://ddl.sinhalasub.net/AgADdA1645"},
        "HD720p":{ "size" : "1.37 GB", "Link": "https://ddl.sinhalasub.net/AgADQB1643"},
        "SD480p":{ "size" : "709 MB", "Link": "https://ddl.sinhalasub.net/AgADPx1641"}
       }
   },
   




   {
      id : 7279231,
      "Title" : "Deep Fear", 
      "IMDbRating" : "3.8", 
      "RunningTime" : "84 minutes", 
      "Genres" : "Action, Thriller", 
      "Introduction" : "Storm-stricken drug traffickers force a yachtswoman to recover their sunken cocaine stash from shark-infested waters.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZTZlMWYyNTgtMTMyNS00MWNjLTk1ZDYtMWQ1NmUxNmU0YjVlXkEyXkFqcGdeQXVyMjE5MzU2MA@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.13 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Deep%20Fear%20(2023)/Deep%20Fear%20(2023)%20English%20WEBRip%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.05 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Deep%20Fear%20(2023)/Deep%20Fear%20(2023)%20English%20WEBRip%20-%20720p.mp4"},
        "SD480p":{ "size" : "546 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Deep%20Fear%20(2023)/Deep%20Fear%20(2023)%20English%20WEBRip%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.13 GB", "Link": "https://mega.nz/file/Y7kE1LKK#smzL-PqECDsCGtIq5N4DVkCT-E0C3TLc74YwSdD-cGA"},
        "HD720p":{ "size" : "1.05 GB", "Link": "https://mega.nz/file/AyV0EBRD#UuZdkNMkk-80MsWa8ALXUUNlExLA7ZOZ_-EMgY0myNI"},
        "SD480p":{ "size" : "546 MB", "Link": "https://mega.nz/file/hqdS3Jpa#B7khGHyquVaCArVdYitKfOu6zAozaG-v-BSBBY8-bT4"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.13 GB", "Link": "https://ddl.sinhalasub.net/AgADPB1638"},
        "HD720p":{ "size" : "1.05 GB", "Link": "https://ddl.sinhalasub.net/AgADsA1636"},
        "SD480p":{ "size" : "546 MB", "Link": "https://ddl.sinhalasub.net/AgADtQ1634"}
       }
   },
   
   {
      id : 1530106,
      "Title" : "Mad", 
      "IMDbRating" : "7.5", 
      "RunningTime" : "127 minutes", 
      "Genres" : "Comedy", 
      "Introduction" : "Set in an engineering college and revolves around the antics of the students there, primarily the boys, who get a kick out of torturing the hostel warden.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNmUzMjU2MTctNTlmYi00MTI3LWIxMzMtZDI0YTA0NTNmNzk4XkEyXkFqcGdeQXVyMTUyNjIwMDEw._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "3.11 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/pg97k4ypjpwd124"},
        "HD720p":{ "size" : "1.53 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/3pm40fuhfu5hsux"},
        "SD480p":{ "size" : "791 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/cypdurv0hw7nicq"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.11 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Mad%20(2023)/Mad%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.53 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Mad%20(2023)/Mad%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20720p.mp4"},
        "SD480p":{ "size" : "791 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Mad%20(2023)/Mad%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "3.11 GB", "Link": "https://mega.nz/file/VytxXJpb#mKRe9wT2A0dBItmiANohOFk31KEKR6Umso529G4qopc"},
        "HD720p":{ "size" : "1.53 GB", "Link": "https://mega.nz/file/JqURCAbK#1vNy6kG8HKeY9QeS3jklXWBe5qziuhJPtGsDST2C4PI"},
        "SD480p":{ "size" : "791 MB", "Link": "https://mega.nz/file/kv0T2bgK#_lpkZbj-oQB0ZK8BzmENd-dMXcwD6d0GU_gRU4rh4oE"}
       }
   },
   
   {
      id : 49382755,
      "Title" : "Chanakya Thanthram", 
      "IMDbRating" : "5.1", 
      "RunningTime" : "130 minutes", 
      "Genres" : "Crime, Mystery, Thriller", 
      "Introduction" : "A fresh-out-of-college criminologist, finds himself pit against an evil mastermind, whom he can only outdo by using his wits and courage.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZWEzMDNlNGEtMTZmMi00MWI3LTk0NmMtNzAwZGM2NmMxZTQwXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chanakya%20Thanthram%20(2018)/Chanakya%20Thanthram%20(2018)%20Malayalam%20HDRip%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.58 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chanakya%20Thanthram%20(2018)/Chanakya%20Thanthram%20(2018)%20Malayalam%20HDRip%20-%20720p.mp4"},
        "SD480p":{ "size" : "818 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chanakya%20Thanthram%20(2018)/Chanakya%20Thanthram%20(2018)%20Malayalam%20HDRip%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://mega.nz/file/ki0RjTqD#iQVe09o4Hdu2z_VDTxpGgHS3V1aRI9uXbGYzj7Ajvh8"},
        "HD720p":{ "size" : "1.58 GB", "Link": "https://mega.nz/file/0z0HBQIB#qBgDTgKZtGSEUfw4y4ZkFsJ6SOHx2Vkq2yFI9I6CiKA"},
        "SD480p":{ "size" : "818 MB", "Link": "https://mega.nz/file/pukCmC7C#_pGi5XPo8gkXsNakUA1NENdYSVlWFz4Z1TII51BCHXA"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.20 GB", "Link": "https://ddl.sinhalasub.net/AgADNh1626"},
        "HD720p":{ "size" : "1.58 GB", "Link": "https://ddl.sinhalasub.net/AgADoA1624"},
        "SD480p":{ "size" : "818 MB", "Link": "https://ddl.sinhalasub.net/AgADnw1622"}
       }
   },
   
   {
      id : 74066465,
      "Title" : "The Banshees of Inisherin", 
      "IMDbRating" : "7.7", 
      "RunningTime" : "114 minutes", 
      "Genres" : "Comedy, Drama", 
      "Introduction" : "Two lifelong friends find themselves at an impasse when one abruptly ends their relationship, with alarming consequences for both of them.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2NlZDI0ZDktNTg5OS00ZjQ1LWI4MDEtN2I0MDE5NWRiNzA4XkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.86 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/l3r8y7e2vicaf4f"},
        "HD720p":{ "size" : "1.40 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/shon6dat7ku3v0s"},
        "SD480p":{ "size" : "727 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/d3jsse4yaz03mye"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.86 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Banshees%20of%20Inisherin%20(2022)/The%20Banshees%20of%20Inisherin%20(2022)%20English%201080p.mp4"},
        "HD720p":{ "size" : "1.40 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Banshees%20of%20Inisherin%20(2022)/The%20Banshees%20of%20Inisherin%20(2022)%20English%20720p.mp4"},
        "SD480p":{ "size" : "727 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Banshees%20of%20Inisherin%20(2022)/The%20Banshees%20of%20Inisherin%20(2022)%20English%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.86 GB", "Link": "https://mega.nz/file/Yz0AGDLJ#naZNFIJ2-xbeubCwGHI2VveTaoTXABLibub77OWqIR8"},
        "HD720p":{ "size" : "1.40 GB", "Link": "https://mega.nz/file/BulFiTab#h_5l683pImaZR-kFEBT9ZjSi-34iRv5ENfa6IgcIUbE"},
        "SD480p":{ "size" : "727 MB", "Link": "https://mega.nz/file/YnMHiBgS#UHZWUctR6yqQ6wBPsLKKebaH3A9MSCfm0sB2oCUHsRM"}
       }
   },
   
   {
      id : 81744205,
      "Title" : "Boudica: Queen of War", 
      "IMDbRating" : "4.4", 
      "RunningTime" : "101 minutes", 
      "Genres" : "Action, Drama", 
      "Introduction": "The eponymous Celtic warrior who rules the Iceni people alongside her husband, Prasutagus. The King dies at the hands of Roman soldiers, leaving Boudica's kingdom without a male heir, and the Romans seize her land and property.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2RhMzQwMGUtYTNjYy00Y2FiLTg4NzctNjRhNmMwMTk0NGQyXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Boudica%20Queen%20of%20War%20(2023)/Boudica%20Queen%20of%20War%20(2023)%20English%20WEBRip%20-1080p.mp4"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Boudica%20Queen%20of%20War%20(2023)/Boudica%20Queen%20of%20War%20(2023)%20English%20WEBRip%20-720p.mp4"},
        "SD480p":{ "size" : "636 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Boudica%20Queen%20of%20War%20(2023)/Boudica%20Queen%20of%20War%20(2023)%20English%20WEBRip%20-480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://mega.nz/file/Qr1nRDZT#kkN6zlU33suAYHy89u3d0RxOWC4siV8PyuJ9pR0Bntg"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://mega.nz/file/My0CzaKY#4BVhm_hdU2mHhuWYjbgoX7bFU8G8MkhD7T2yNYhEZHE"},
        "SD480p":{ "size" : "636 MB", "Link": "https://mega.nz/file/xrFDEADY#xG2u9q91qGCFkmcv6QZLdn3vXH6EI3OH26x9o_FgsAQ"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.48 GB", "Link": "https://ddl.sinhalasub.net/AgADbx1607"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://ddl.sinhalasub.net/AgADvA1605"},
        "SD480p":{ "size" : "636 MB", "Link": "https://ddl.sinhalasub.net/AgADuw1603"},
   
       }
   },
   

   
   {
      id : 16237893,
      "Title" : "The Killer", 
      "IMDbRating" : "6.8", 
      "RunningTime" : "118 minutes", 
      "Genres" : "Action, Adventure, Crime, Drama, Mystery, Thriller", 
      "Introduction": "After a fateful near-miss, an assassin battles his employers and himself, on an international manhunt he insists isnt personal.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZGJkMDUwZWQtYTMzMS00NTg5LWE1ZTYtOTVhMDI4NGI1YjMyXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Killer%20(2023)/The%20Killer%20(2023)%20NF%20WEB-DL%20[SinhalaSub.Net]-%201080p.mp4"},
        "HD720p":{ "size" : "1.46 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Killer%20(2023)/The%20Killer%20(2023)%20NF%20WEB-DL%20[SinhalaSub.Net]-%20720p.mp4"},
        "SD480p":{ "size" : "756 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Killer%20(2023)/The%20Killer%20(2023)%20NF%20WEB-DL%20[SinhalaSub.Net]-%20480p.mp4"}
      },
      "Severid:2":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "https://mega.nz/file/k7ESDaaS#Q5K6Jf3GLP4kdigKZhvv0tb1xeyxAX08OVQE7sMnRlQ"},
        "HD720p":{ "size" : "1.46 GB	", "Link": "https://mega.nz/file/YvtRWLjK#EvXFz1XH2Zh-IYPvhjAwUCAWrxz9gl3NMN18Ik2NgR8"},
        "SD480p":{ "size" : "756 MB", "Link": "https://mega.nz/file/sjEECboK#g2wzMDjw8WC1gJ1G4_HFEXNAEILwL41xdSrMdHdk6YQ"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "http://ddl.sinhalasub.net/1888/The+Killer+%282023%29+NF+WEB-DL+%5BSinhalaSub.Net%5D-+720p.mp4?hash=AgADsh"},
        "HD720p":{ "size" : "1.46 GB	", "Link": "https://ddl.sinhalasub.net/AgADsh1888"},
        "SD480p":{ "size" : "756 MB", "Link": "https://ddl.sinhalasub.net/AgADsx1886"}
       }
   },
   
   {
      id : 53734285,
      "Title" : "Seetimaarr", 
      "IMDbRating" : "5.0", 
      "RunningTime" : "139 minutes", 
      "Genres" : "Action, Drama, Sport", 
      "Introduction" : "All a full-time bank employee and part-time kabaddi coach wants is to bring national relevance to the school his father built and help young kabaddi players in his village win the national championship. It might prove difficult than just coaching them though.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMWM5ZTRkOTgtMzAxYS00ZDEwLWE4MjgtOWRmYWU0NTc1NDYyXkEyXkFqcGdeQXVyMTA1MTMwNDc@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "4.25 GB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Telugu/Seeti%20Maar%20(2021)/www.SinhalaSub.net%20-%20Seeti%20Maar%20(2021)%20Telugu%20TRUE%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.52 GB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Telugu/Seeti%20Maar%20(2021)/www.SinhalaSub.net%20-%20Seeti%20Maar%20(2021)%20Telugu%20TRUE%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "788 MB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Telugu/Seeti%20Maar%20(2021)/www.SinhalaSub.net%20-%20Seeti%20Maar%20(2021)%20Telugu%20TRUE%20WEB-DL%20480p.mp4"}
      },
      "Severid:2":{
        "FHD1080p":{ "size" : "3.36 GB", "Link": "https://ddl.sinhalasub.net/AgADeB1841"},
        "HD720p":{ "size" : "1.52 GB", "Link": "https://ddl.sinhalasub.net/AgAD8w1822"},
        "SD480p":{ "size" : "788 MB", "Link": "https://ddl.sinhalasub.net/AgAD8Q1820"}
       }
   },
   
   {
      id : 51566142,
      "Title" : "Sick", 
      "IMDbRating" : "6.0", 
      "RunningTime" : "83 minutes", 
      "Genres" : "Horror, Thriller", 
      "Introduction" : "Due to the pandemic, Parker and her best friend decide to quarantine at the family lake house alone - or so they think.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNDJiNDNjYzEtNjM4YS00NjU2LTg2ZTQtOGExM2I3ZjRjOTVjXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.05 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sick%20(2022)/Sick%20(2022)%20English%20WEB-DL%20%5BSinhalaSub.LK%5D%201080p.mp4"},
        "HD720p":{ "size" : "1.02 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sick%20(2022)/Sick%20(2022)%20English%20WEB-DL%20%5BSinhalaSub.LK%5D%20720p.mp4"},
        "SD480p":{ "size" : "528 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sick%20(2022)/Sick%20(2022)%20English%20WEB-DL%20%5BSinhalaSub.LK%5D%20480p.mp4"}
      },
      "Severid:2":{
        "FHD1080p":{ "size" : "2.05 GB", "Link": "https://mega.nz/file/pu8nULTA#zCWrnHsipZzwP9rIhYMMcNcxGwA-ACPaxya1ytNM0IM"},
        "HD720p":{ "size" : "1.02 GB", "Link": "https://mega.nz/file/Yu9UiDiK#9CiRQ5QZQebNLF0yFAVbimJ7xervZJbQOkq-jQgZ6C0"},
        "SD480p":{ "size" : "528 MB", "Link": "https://mega.nz/file/IiNUBZqZ#asplNHbJtRN0fq57ZnGFZ2ypgtH8SWXJ8qyyPzpwaaY"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.05 GB", "Link": "https://ddl.sinhalasub.net/AgADUA1812"},
        "HD720p":{ "size" : "1.02 GB", "Link": "https://ddl.sinhalasub.net/AgADkx1810"},
        "SD480p":{ "size" : "528 MB", "Link": "https://ddl.sinhalasub.net/AgADkh1808"}
       }
   },
  

   
   
   
   {
      id : 8846188,
      "Title" : "Asvins", 
      "IMDbRating" : "4.7", 
      "RunningTime" : "111 minutes", 
      "Genres" : "Horror, Thriller", 
      "Introduction" : "A group of Youtubers who accidentally unleash a 1500-year-old evil that crosses over from the realm of darkness to the human world.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODM2MTUyZmYtNjIxMC00ZWYxLWJiZjItZDU0YTkyYTg4NjliXkEyXkFqcGdeQXVyMTU0ODI1NTA2._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.75 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/gdbwauwyo297qp1"},
        "HD720p":{ "size" : "1.36 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/edelbe3phnlrmog"},
        "SD480p":{ "size" : "704 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/qqscoh26wyjdubc"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.75 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Asvins%20(2023)/Asvins%20(2023)%20Tamil%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.36 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Asvins%20(2023)/Asvins%20(2023)%20Tamil%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "704 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Asvins%20(2023)/Asvins%20(2023)%20Tamil%20WEB-DL%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.75 GB", "Link": "https://mega.nz/file/Zy1EjTaI#nbHN-soYYZR6lmxLU_a07Xs-6TA6a11VtTfB-0mEaL4"},
        "HD720p":{ "size" : "1.36 GB", "Link": "https://mega.nz/file/0rsmFZLS#ezcOhYmgFGfiqEFjta8qO91x4UtAJa4_c1LTeWOoqGo"},
        "SD480p":{ "size" : "704 MB", "Link": "https://mega.nz/file/Bv1m0JoA#QOKtSar_GNB6cDqxkZ6CGxIhDaiwCsuKPcoMrITg7uE"}
       }
   },
   
   {
      id : 20484529,
      "Title" : "Corona Dhavan", 
      "IMDbRating" : "6.1", 
      "RunningTime" : "130 minutes", 
      "Genres" : "Comedy", 
      "Introduction" : "Revolves around the lives of a group of alcoholics who face hardships during the COVID-19 lockdown.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNTc5MTNmYzItZGIxZS00MDViLTljNWUtMmJkYzBhNGMwNmQwXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "3.19 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/0k6kywvup5izjur"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/9lic72kfao81kk7"},
        "SD480p":{ "size" : "816 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/4ektl8reayfawym"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Corona%20Dhavan%20(2023)/Corona%20Dhavan%20(2023)%20Malayalam%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Corona%20Dhavan%20(2023)/Corona%20Dhavan%20(2023)%20Malayalam%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "816 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Corona%20Dhavan%20(2023)/Corona%20Dhavan%20(2023)%20Malayalam%20WEB-DL%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "3.19 GB", "Link": "https://mega.nz/file/NnUl0QhR#C_VsxHVigquvXqIt4WMAVqDZ9vIkkoVujVwh7kMsPwA"},
        "HD720p":{ "size" : "1.57 GB", "Link": "https://mega.nz/file/47sSmb5L#8n12Q4YmU2DbAMi495Hli2UohWyTaJfYAgN_j_Z3NO0"},
        "SD480p":{ "size" : "816 MB", "Link": "https://mega.nz/file/13METTTI#5GwAgpaPAE5KjXLbP1crRKZnoa2va4CIP_PY27N3nHc"}
       }
   },
   
   {
      id : 58898614,
      "Title" : "The Unknown Saint", 
      "IMDbRating" : "6.4", 
      "RunningTime" : "100 minutes", 
      "Genres" : "Comedy, Crime", 
      "Introduction" : "A criminal returns to the fake grave where he buried his loot years before and discovers that it has become the shrine of an unknown saint and a thriving little village.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BY2QwOTM2MWEtODAwNS00MmE1LWE1MDctMGMzM2NkZTQzZTQ0XkEyXkFqcGdeQXVyNjgzMjQ0MTA@._V1_.jpg",
    "severid2":{
        "FHD1080p":{ "size" : "2.49 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Unknown%20Saint%20(2019)/The%20Unknown%20Saint%20(2019)%20Arabic%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Unknown%20Saint%20(2019)/The%20Unknown%20Saint%20(2019)%20Arabic%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "636 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Unknown%20Saint%20(2019)/The%20Unknown%20Saint%20(2019)%20Arabic%20WEB-DL%20480p.mp4"}
      },
      "Severid:3":{
        "FHD1080p":{ "size" : "2.49 GB", "Link": "https://mega.nz/file/Qyc11SxI#Bt5yeFQfx-9hUj0ikhSShSRLRT3nefJLogREo7qi8do"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://mega.nz/file/YmdzSTDJ#q173BwN64--ZFhIGvBmqpJZ6iKVm9C7aXRdSZrlsUMc"},
        "SD480p":{ "size" : "636 MB", "Link": "https://mega.nz/file/4zFBRbwZ#JII-3LZ9XbzUymya8UR7b7u7jowUB7WGj9BwMBMgR9Q"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.49 GB", "Link": "https://ddl.sinhalasub.net/AgAD9w1588"},
        "HD720p":{ "size" : "1.22 GB", "Link": "https://ddl.sinhalasub.net/AgAD_g1586"},
        "SD480p":{ "size" : "636 MB", "Link": "https://ddl.sinhalasub.net/AgAD_Q1584"}
       }
   },
   
   {
      id : 28182875,
      "Title" : "Oppenheimer", 
      "IMDbRating" : "8.5", 
      "RunningTime" : "180 minutes", 
      "Genres" : "Biography, Drama, History", 
      "Introduction": "The story of American scientist, 'J. Robert Oppenheimer' (qv), and his role in the development of the atomic bomb.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMDBmYTZjNjUtN2M1MS00MTQ2LTk2ODgtNzc2M2QyZGE5NTVjXkEyXkFqcGdeQXVyNzAwMjU2MTY@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "4.53 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/%20Oppenheimer%20(2023)/%20Oppenheimer%20(2023)%20English%20BluRay%201080p.mp4"},
        "HD720p":{ "size" : "2.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/%20Oppenheimer%20(2023)/Oppenheimer%20(2023)%20English%20BluRay%20720p.mp4"},
        "SD480p":{ "size" : "1.12 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/%20Oppenheimer%20(2023)/Oppenheimer%20(2023)%20English%20BluRay%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "4.53 GB", "Link": "https://mega.nz/file/N6sBWajK#NRZrYouHXSY3VOP7LQTNxEHaS7_lf3qg7-ReUlnmfnI"},
        "HD720p":{ "size" : "2.22 GB", "Link": "https://mega.nz/file/RrczXQQQ#wm2LZCAkxIA2DFXWXT07zC2ziNaXUyZWwkMnEU0-ChQ"},
        "SD480p":{ "size" : "1.12 GB", "Link": "https://mega.nz/file/EucRxZ5S#Gkgt5AXz8L376Y-A58twCKNKfQmOzD9rhFNc0FFGvBY"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.83 GB", "Link": "https://ddl.sinhalasub.net/AgADiQ1570"},
        "HD720p":{ "size" : "2.22 GB", "Link": "https://ddl.sinhalasub.net/AgADig1568"},
        "SD480p":{ "size" : "1.12 GB", "Link": "https://ddl.sinhalasub.net/AgADDQ1566"}
       }
   },
   
   {
      id : 10244076,
      "Title" : "Kadina Kadoramee Andakadaham", 
      "IMDbRating" : "6.4", 
      "RunningTime" : "118 minutes", 
      "Genres" : "Comedy, Drama, Family", 
      "Introduction" : "Bachu, a young man who wishes to become a businessman, is in a big crisis. His family forces him to go to Gulf but he is not ready to change his decision. When lockdown is imposed he rethink his actions.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOTgzN2JiOGItYWVlNC00OTU4LTg0MjMtODZmZGM3OTRmMmZiXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_FMjpg_UX1000_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/u60xo5yllbyds1d"},
        "HD720p":{ "size" : "1.45 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/1jcocbb1zm4ur8l"},
        "SD480p":{ "size" : "753 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/s05ods5spx5zozc"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kadina%20Kadoramee%20Andakadaham%20(2023)/Kadina%20Kadoramee%20Andakadaham%20(2023)%20Malayalam%20WEB-DL%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.45 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kadina%20Kadoramee%20Andakadaham%20(2023)/Kadina%20Kadoramee%20Andakadaham%20(2023)%20Malayalam%20WEB-DL%20-%20720p.mp4"},
        "SD480p":{ "size" : "753 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kadina%20Kadoramee%20Andakadaham%20(2023)/Kadina%20Kadoramee%20Andakadaham%20(2023)%20Malayalam%20WEB-DL%20-%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.96 GB", "Link": "https://mega.nz/file/Yy9FnBgB#y0I3kA7Gwu325FRDI_HQ46fLettjwBLHU_36iQ9eyzY"},
        "HD720p":{ "size" : "1.45 GB", "Link": "https://mega.nz/file/0q9khbzY#CpqvocF-bnk8l4KEFiGgdHInBtJ9IrlxcAy7m0WhI0Q"},
        "SD480p":{ "size" : "753 MB", "Link": "https://mega.nz/file/gnMEGLgQ#PoFtpS0SfGVGipBpHGfzDyJArUiIVgw8dl4zh1YGpxo"}
       }
   },
   
   {
      id : 85111641,
      "Title" : "Prey for the Devil", 
      "IMDbRating" : "5.3", 
      "RunningTime" : "93 minutes", 
      "Genres" : "Horror, Mystery, Thriller", 
      "Introduction" : "A nun prepares to perform an exorcism and comes face to face with a demonic force with mysterious ties to her past.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNzNjNmE4YTktMTZiNS00YTQ0LWExY2YtZTU2MGNiNzBiMzI5XkEyXkFqcGdeQXVyMTA0OTI1NDM2._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.33 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/cgodbndq0pijr4k"},
        "HD720p":{ "size" : "1.15 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/h1r3dtbblt15juc"},
        "SD480p":{ "size" : "594 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/he133sp51wl6qum"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.33 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prey%20for%20the%20Devil%20(2022)/Prey%20for%20the%20Devil%20(2022)%20English%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.15 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prey%20for%20the%20Devil%20(2022)/Prey%20for%20the%20Devil%20(2022)%20English%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "594 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prey%20for%20the%20Devil%20(2022)/Prey%20for%20the%20Devil%20(2022)%20English%20WEB-DL%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.33 GB", "Link": "https://mega.nz/file/guUVALTL#NFOzofBWbXiflKsW3qVloOfiU79ISAaQBY3L5T5bnmA"},
        "HD720p":{ "size" : "1.15 GB", "Link": "https://mega.nz/file/g28SjaQR#1j7G4eIrTRZcfP8iFvALQ7I7A6gCxeeG-d7gaQeKZ1E"},
        "SD480p":{ "size" : "594 MB", "Link": "https://mega.nz/file/U3dUSY5a#2DZMwaqo6qqKfG43xDJ1iUy9TbN0vAlkQS7Wrhow4zk"}
       }
   },
   
   {
      id : 38969399,
      "Title" : "Peddha Kapu 1", 
      "IMDbRating" : "6.0", 
      "RunningTime" : "147 minutes", 
      "Genres" : "Action, Drama, Family", 
      "Introduction": "A common man enters a village s political arena, which has been dominated by two political parties", 
      "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYzMwZTJhMTAtM2Y4Ny00MWExLTg4MzMtMjAzNjc0NmIwZmM4XkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_FMjpg_UX1000_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "3.53 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/j6cf6rw8wppkhl8"},
        "HD720p":{ "size" : "1.74 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/t0wfxoq6d7i9ov9"},
        "SD480p":{ "size" : "902 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/ysdflar9r6depq5"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.53 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Peddha%20Kapu%201%20(2023)/Peddha%20Kapu%201%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.74 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Peddha%20Kapu%201%20(2023)/Peddha%20Kapu%201%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20720p.mp4"},
        "SD480p":{ "size" : "902 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Peddha%20Kapu%201%20(2023)/Peddha%20Kapu%201%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "3.53 GB", "Link": "https://mega.nz/file/VulG2SSK#VNvfmeTR9JxT3ADrVU8aPeGNlwupYvzmm-QNOAkND0g"},
        "HD720p":{ "size" : "1.74 GB", "Link": "https://mega.nz/file/1rMnzSKY#H6reWmmBSOSrC7dRf3yrcOKLpPzxIdMLqG7EIVudnFg"},
        "SD480p":{ "size" : "902 MB", "Link": "https://mega.nz/file/RzUyVYBY#xFEnLX6uzq4RLkv3B_Vm5MKaxmFULppQO8g-dtGEAY0"}
       }
   },
   
   {
      id : 43287863,
      "Title" : "No Bears", 
      "IMDbRating" : "7.3", 
      "RunningTime" : "106 minutes", 
      "Genres" : "Drama, Romance", 
      "Introduction" : "Two parallel love stories in which the partners are thwarted by hidden, inevitable obstacles, the force of superstition, and the mechanics of power.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYmY5MWM4OWItZDRhNy00NmFjLWFkNGQtMjQyMmU4YzkwNDIxXkEyXkFqcGdeQXVyNjQyMTI3MTM@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.67 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/No%20Bears%20(2022)/No%20Bears%20(2022)%20Turkish%20BluRay%201080p.mp4"},
        "HD720p":{ "size" : "1.32 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/No%20Bears%20(2022)/No%20Bears%20(2022)%20Turkish%20BluRay%20720p.mp4"},
        "SD480p":{ "size" : "681 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/No%20Bears%20(2022)/No%20Bears%20(2022)%20Turkish%20BluRay%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.67 GB", "Link": "https://mega.nz/file/FvtATTyJ#ktGfGf487LxIskdk7ZvmI58LAgmfKh1ZElaqFTYE3PQ"},
        "HD720p":{ "size" : "1.32 GB", "Link": "https://mega.nz/file/Vrc2TAKT#p24PZYGhr0CAkcvTQsN35e2pO1DkNwzfq-9AtPj3ilc"},
        "SD480p":{ "size" : "681 MB", "Link": "https://mega.nz/file/kyVmGQpJ#BRbNc6_jXMgqvCjtZEgiC_4yBNVuEdVBQjlxTsp3s5o"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.67 GB", "Link": "https://ddl.sinhalasub.net/AgADYh1478"},
        "HD720p":{ "size" : "1.32 GB", "Link": "https://ddl.sinhalasub.net/AgAD6x1476"},
        "SD480p":{ "size" : "681 MB", "Link": "https://ddl.sinhalasub.net/AgADDx1474"}
       }
   },
   
   {
      id : 29878961,
      "Title" : "Skanda: The Attacker", 
      "IMDbRating" : "4.2", 
      "RunningTime" : "167 minutes", 
      "Genres" : "Action, Drama, Romance", 
      "Introduction" : "When his sister is put into a coma, a man vows to get revenge on the corrupt men who injured her.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNTgzYjlkYTQtYTZjNi00ODRlLTgwNDYtMjJiNzM2MTRjYzBkXkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "4.13 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/tz866p2ea41f22s"},
        "HD720p":{ "size" : "2.03 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/lis0oftfuhz27v6"},
        "SD480p":{ "size" : "1.03 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/2oy7wrg3zg9vh92"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "4.13 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Skanda%20(2023)/Skanda%20(2023)%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "2.03 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Skanda%20(2023)/Skanda%20(2023)%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "1.03 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Skanda%20(2023)/Skanda%20(2023)%20WEB-DL%20480p.mp4"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "4.13 GB", "Link": "https://mega.nz/file/Qz9DxaCJ#CX8ETgoURUu-iOoWjdSjNXYxQSAWPJPNpjqBlGMegpc"},
        "HD720p":{ "size" : "2.03 GB", "Link": "https://mega.nz/file/om9llC6D#lAAh_e7rEh6Btd8Hu8GdqDICBvm49k7bTFynrDtpcTk"},
        "SD480p":{ "size" : "1.03 GB", "Link": "https://mega.nz/file/U3tHiKpa#ruQwY1HhJRZnDyzvKslRLdPZ05eWULcteLvDSlX3Nw8"}
       }
   },
   
   {
      id : 12777696,
      "Title" : "Dragon Fist", 
      "IMDbRating" : "6.1", 
      "RunningTime" : "97 minutes", 
      "Genres" : "Action, Drama", 
      "Introduction": "Tong Huo-wan (Jackie Chan (qv)) seeks redress from the man responsible for his master's death, but becomes entangled in a feud between a local clan and gangsters.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODI3MzQ2MTQyOV5BMl5BanBnXkFtZTcwNTIyMzIyMQ@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.43 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dragon%20Fist%20(1979)/Dragon%20Fist%20(1979)%20English%20BluRay%201080p.mp4"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dragon%20Fist%20(1979)/Dragon%20Fist%20(1979)%20English%20BluRay%20720p.mp4"},
        "SD480p":{ "size" : "618 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dragon%20Fist%20(1979)/Dragon%20Fist%20(1979)%20English%20BluRay%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.43 GB", "Link": "https://mega.nz/file/8rljxaZA#k4Zr_epacDl91ZxpNPApGmWbl0gnpeCw61l0e-3N3CQ"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://mega.nz/file/snFWELjL#8KHVJrrcjK_reyg9ALPN8xSWFzkaRKbKUTQxLMhP654"},
        "SD480p":{ "size" : "618 MB", "Link": "https://mega.nz/file/hic1VLab#d3fH68O8tRaj5pLuyFccAm3by3IPCwjiv-wBcNkQZeQ"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.43 GB", "Link": "https://ddl.sinhalasub.net/AgADbB1442"},
        "HD720p":{ "size" : "1.19 GB", "Link": "https://ddl.sinhalasub.net/AgADox1440"},
        "SD480p":{ "size" : "618 MB", "Link": "https://ddl.sinhalasub.net/AgADpR1438"}
       }
   },
   
   {
      id : 65249215,
      "Title" : "Kurukkan", 
      "IMDbRating" : "5.4", 
      "RunningTime" : "125 minutes", 
      "Genres" : "Crime", 
      "Introduction": "A self deprecating police officer who is down on luck and hasn t had any career promotion s tries to frame an innocent to make fame, but it backfires on him.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYWFjYTcyZTYtYzU0Ni00Njc0LWI4NzItOTJmNjI0ZWU1NDQwXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_FMjpg_UX1000_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.09 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kurukkan%20(2023)/Kurukkan%20(2023)%20Malayalam%20TRUE%20WEB-DL%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.52 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kurukkan%20(2023)/Kurukkan%20(2023)%20Malayalam%20TRUE%20WEB-DL%20-%20720p.mp4"},
        "SD480p":{ "size" : "787 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kurukkan%20(2023)/Kurukkan%20(2023)%20Malayalam%20TRUE%20WEB-DL%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.09 GB", "Link": "https://mega.nz/file/km9DGABL#3kB4rjYx3rGkAqkvg7V3KGSkGMj4MVjeUA14azP1BeU"},
        "HD720p":{ "size" : "1.52 GB", "Link": "https://mega.nz/file/tuEinCDL#1ERyPgs_S1aIAqnF4dk6cLfiuANVdgm_UV_QvVh12qs"},
        "SD480p":{ "size" : "787 MB", "Link": "https://mega.nz/file/tjknkDzb#I3ASvaV6aujKHq3E_fbcS5ENfhqQf-c98xTgxxzZ-5U"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.09 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/gceudt0ltt2hvry"},
        "HD720p":{ "size" : "1.52 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/cv9yudywh61232n"},
        "SD480p":{ "size" : "787 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/cbbqargtw9kf2d5"}
       }
   },
      
   {
      id : 42562685,
      "Title" : "Khufiya", 
      "IMDbRating" : "6.0", 
      "RunningTime" : "157 minutes", 
      "Genres" : "Action, Biography, Crime, Mystery, Thriller", 
      "Introduction": "Krishna Mehra is an operative at Indian spy agency known as R&AW. She is assigned to track down the mole selling Indias defense secrets, while all along grappling with her dual identity as a spy and a lover.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNWYzMWFhYmQtNmI0Ni00OWJkLWFiMjktM2JlZmEzZjgzNjE1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Khufiya%20(2023)/Khufiya%20(2023)%20Hindi%20HDRip%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.95 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Khufiya%20(2023)/Khufiya%20(2023)%20Hindi%20HDRip%20-%20720p.mp4"},
        "SD480p":{ "size" : "1.09 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Khufiya%20(2023)/Khufiya%20(2023)%20Hindi%20HDRip%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.96 GB", "Link": "https://mega.nz/file/Aislib6A#inHBSEozgPkXxInHF_KxsZsoNXUei4usQiV0pxZ-bco"},
        "HD720p":{ "size" : "1.95 GB", "Link": "https://mega.nz/file/06VCWApD#H5wtRtBl-yWw-ZAFVQs5isL2sxGEKtih_xFHXXr9SlM"},
        "SD480p":{ "size" : "1.09 GB", "Link": "https://mega.nz/file/ovdGxB6A#MBG5onGQf8O9nrGsA5kPjSw4yTLKhGHFRXhzyXrAJgs"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.74 GB", "Link": "https://ddl.sinhalasub.net/AgAD3Q1371"},
        "HD720p":{ "size" : "1.95 GB", "Link": "https://ddl.sinhalasub.net/AgAD_B1368"},
        "SD480p":{ "size" : "1.09 GB", "Link": "https://ddl.sinhalasub.net/AgAD-h1366"}
       }
   },
   
   {
      id : 5432484,
      "Title" : "Dream Girl 2", 
      "IMDbRating" : "5.3", 
      "RunningTime" : "134 minutes", 
      "Genres" : "Comedy, Drama, Romance", 
      "Introduction" : "Karam, who is trying to live a serious life in Mathura and falls in love with Pari but life is hell bent on not taking him seriously. In a turn of events Karam becomes Pooja which creates further chaos in his already chaotic life.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNjczMDBlM2UtYjFjNi00MTlmLTgzYzAtMzRiNDRkYzg2YWQ3XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "3.18 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dream%20Girl%202%20(2023)/Dream%20Girl%202%20(2023)%C2%A0Hindi%20HQ%20HDRip%20-%201080p.mp4"},
        "HD720p":{ "size" : "1.56 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dream%20Girl%202%20(2023)/Dream%20Girl%202%20(2023)%C2%A0Hindi%20HQ%20HDRip%20-%20720p.mp4"},
        "SD480p":{ "size" : "809 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Dream%20Girl%202%20(2023)/Dream%20Girl%202%20(2023)%C2%A0Hindi%20HQ%20HDRip%20-%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.18 GB", "Link": "https://mega.nz/file/ErVinIYZ#pKjmEY0TNDBlLkun7LJskH1sAQDcpUOHrP14JTvwXp8"},
        "HD720p":{ "size" : "1.56 GB", "Link": "https://mega.nz/file/8mlxXY5C#TqH_j5RNA8XilJe89eEY7CO3zll_DyDW0DECSRlUtHc"},
        "SD480p":{ "size" : "809 MB", "Link": "https://mega.nz/file/FzUDEBCL#hvvCfbaycSwLjKdDJYWVTwplQNexl9CtqAqea1w2gSQ"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "3.18 GB", "Link": "https://ddl.sinhalasub.net/AgADdB1364"},
        "HD720p":{ "size" : "1.56 GB", "Link": "https://ddl.sinhalasub.net/AgADhh1362"},
        "SD480p":{ "size" : "809 MB", "Link": "https://ddl.sinhalasub.net/AgADhB1360"}
       }
   },
   
   {
      id : 10786749,
      "Title" : "Case 30", 
      "IMDbRating" : "7.9", 
      "RunningTime" : "120 minutes", 
      "Genres" : "Crime, Romance, Thriller", 
      "Introduction" : "CASE 30 tells the story of a murder case which has been assigned to Crime Branch Officer Arjun bharadwaj. How Arjun investigates and unthreads the case is the rest of the story.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNmYyZDc0NzYtM2I5ZS00YzVlLWEwMGQtOTg5ODMyMGQyNjlkXkEyXkFqcGdeQXVyODY1NDkzNTU@._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "2.74 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/0ysxc4izo103uhx"},
        "HD720p":{ "size" : "1.35 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/ilz8iydhl2udrea"},
        "SD480p":{ "size" : "697 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/6qb1hs0mx7hk03n"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.74 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Case%2030%20(2023)/Case%2030%20(2023)%20Telugu%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.35 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Case%2030%20(2023)/Case%2030%20(2023)%20Telugu%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "697 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Case%2030%20(2023)/Case%2030%20(2023)%20Telugu%20WEB-DL%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "2.74 GB", "Link": "https://mega.nz/file/5ncDRRhB#1cfpk8eusj_UpMN7u899rBLGBEqDQYzfTQ5ugiCCOeQ"},
        "HD720p":{ "size" : "1.35 GB", "Link": "https://mega.nz/file/AiUlAaJC#EfA4THXVcz4_MD0uCHnkStI67VLdvaUpHMgevLotKcE"},
        "SD480p":{ "size" : "697 MB", "Link": "https://mega.nz/file/EnUTBaiZ#nEHIMvfQSXVFBp-eRwDFE_bm2ein5KLQP726qbcjh94"}
       }
   },
   
   {
      id : 33035212,
      "Title" : "Miss Shetty Mr Polishetty", 
      "IMDbRating" : "7.0", 
      "RunningTime" : "149 minutes", 
      "Genres" : "Comedy, Drama, Romance, Thriller", 
      "Introduction" : "Miss Shetty is a feminist living in London and wants to be single forever. Mr. Polishetty from Hyderabad in Telangana, India, wants to be in a committed relationship. The two appear to be in different stages of life but somehow connect.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNWE5NGZkOWYtODlkZS00Mzk5LWIzZjgtMjk4NzAxNzFmODA3XkEyXkFqcGdeQXVyMTQ3Mzk2MDg4._V1_.jpg",
    "severid1":{
        "FHD1080p":{ "size" : "3.71 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/s7qokjnpal9bxdz"},
        "HD720p":{ "size" : "1.82 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/y6u701ymbbruohp"},
        "SD480p":{ "size" : "944 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/omd5opmfbeutpsi"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "3.71 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Miss%20Shetty%20Mr%20Polishetty%20(2023)/Miss%20Shetty%20Mr%20Polishetty%20(2023)%20Telugu%20WEB-DL%201080p.mp4"},
        "HD720p":{ "size" : "1.82 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Miss%20Shetty%20Mr%20Polishetty%20(2023)/Miss%20Shetty%20Mr%20Polishetty%20(2023)%20Telugu%20WEB-DL%20720p.mp4"},
        "SD480p":{ "size" : "944 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Miss%20Shetty%20Mr%20Polishetty%20(2023)/Miss%20Shetty%20Mr%20Polishetty%20(2023)%20Telugu%20WEB-DL%20480p.mp4"}
      },
      "severid3":{
        "FHD1080p":{ "size" : "3.71 GB", "Link": "https://mega.nz/file/Fq9yQQQI#vQQSudT80_Sbo9ujZSEdgfaAhwCUkFKWpzcY9T4sfSo"},
        "HD720p":{ "size" : "1.82 GB", "Link": "https://mega.nz/file/EuUVyBhb#HQkTjuY3ikXijd_8xbvuh7yNFlz-ghvFFFmAaidpSnQ"},
        "SD480p":{ "size" : "944 MB", "Link": "https://mega.nz/file/ZuFlSILS#kr4lNPf0pDEliGeR98s-hy-TG152NltJDmkxJc4BGoU"}
       }
   },
   
   {
      id : 23368128,
      "Title" : "Bridge to Terabithia", 
      "IMDbRating" : "7.2", 
      "RunningTime" : "96 minutes", 
      "Genres" : "Drama, Family, Fantasy", 
      "Introduction": "A preteen s life turns upside down when he befriends the new girl in school and they imagine a whole new fantasy world to escape reality.", 
       "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMTMzOTk1MzIyN15BMl5BanBnXkFtZTcwNTM3MjczMQ@@._V1_.jpg",
    "severid3":{
        "FHD1080p":{ "size" : "2.39 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Bridge%20to%20Terabithia%20(2007)/Bridge%20to%20Terabithia%20(2007)%20English%20BrRip%201080p.mp4"},
        "HD720p":{ "size" : "1.17 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Bridge%20to%20Terabithia%20(2007)/Bridge%20to%20Terabithia%20(2007)%20English%20BrRip%20720p.mp4"},
        "SD480p":{ "size" : "607 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Bridge%20to%20Terabithia%20(2007)/Bridge%20to%20Terabithia%20(2007)%20English%20BrRip%20480p.mp4"}
      },
      "severid2":{
        "FHD1080p":{ "size" : "2.39 GB", "Link": "https://mega.nz/file/F7ECxZLQ#r9S5ycv6ko1-tfIHjnlxXpEFcH1k-mc1QO1YK7MXT0w"},
        "HD720p":{ "size" : "1.17 GB", "Link": "https://mega.nz/file/NrkUzZqa#FVf-7ROXMekaZQyyMO1026cu0rAfnKJG0UtffvHYx0M"},
        "SD480p":{ "size" : "607 MB", "Link": "https://mega.nz/file/xjci2aTL#Kh7EMin_MSVKvytAGOH7qO1q-ETYbI-aO-Iwka6PZmw"}
      },
      "severid1":{
        "FHD1080p":{ "size" : "2.39 GB", "Link": "https://ddl.sinhalasub.net/AgADhx1285"},
        "HD720p":{ "size" : "1.17 GB", "Link": "https://ddl.sinhalasub.net/AgADgQ1283"},
        "SD480p":{ "size" : "607 MB", "Link": "https://ddl.sinhalasub.net/AgADfg1281"}
       }
   },
   
   
   {
    id : 86479344,
    "Title" : "The Albanian", 
    "IMDbRating" : "7.1", 
    "RunningTime" : "105 minutes", 
    "Genres" : "Drama", 
    "Introduction": "Handsome Albanian villager Arben wants to marry Etleva, daughter of a neighboring clan, but her father has promised her to another man who is offering a 10,000 Euros bride price. But when it turns out Etleva is carrying Arben s child, the pressure is on for him to come up with the dowry before the baby is born -- and before her brothers take revenge for the dishonor he has brought their family. Fleeing to Berlin without papers, experience or knowledge of the language, Arben soon learns the ways of survival.—Palm Springs International Film Festival", 
     "MovieImage" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOgFcU-cgV36tc73VFN0_wh4BuDGAfOQpJ28MxX0pAJm9sR7_5",
  "severid3":{
      "FHD1080p":{ "size" : "2.56 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Albanian%20aka%20Der%20Albaner%20(2010)/The%20Albanian%20aka%20Der%20Albaner%20(2010)%20German%20DVDRip%201080p.mp4"},
      "HD720p":{ "size" : "1.26 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Albanian%20aka%20Der%20Albaner%20(2010)/The%20Albanian%20aka%20Der%20Albaner%20(2010)%20German%20DVDRip%20720p.mp4"},
      "SD480p":{ "size" : "656 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Albanian%20aka%20Der%20Albaner%20(2010)/The%20Albanian%20aka%20Der%20Albaner%20(2010)%20German%20DVDRip%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "2.56 GB", "Link": "https://mega.nz/file/wrsGnLbJ#vSbB0D8vfx6ofSIP0dAtZ7HkYr17fhICcnsToB7Et_8"},
      "HD720p":{ "size" : "1.26 GB", "Link": "https://mega.nz/file/ZndVGaLB#KL4yBaEvFralbKY7iwIMB_MtsD_tFyQrLkqWmRJuE7o"},
      "SD480p":{ "size" : "656 MB", "Link": "https://mega.nz/file/lz8RHYrK#FZnZ1x32bILxJwmWF_WfYlBURVk-UrOHJrmAXnWd5rw"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "2.56 GB", "Link": "https://ddl.sinhalasub.net/AgADfh1279"},
      "HD720p":{ "size" : "1.26 GB", "Link": "https://ddl.sinhalasub.net/AgADcw1277"},
      "SD480p":{ "size" : "656 MB", "Link": "https://ddl.sinhalasub.net/AgADdg1275"}
     }
 },
 
 {
    id : 38529394,
    "Title" : "America: The Motion Picture", 
    "IMDbRating" : "5.6", 
    "RunningTime" : "98 minutes", 
    "Genres" : "Animation, Action, Adventure, Comedy, Fantasy, History, Sci-Fi, War", 
    "Introduction" : "A chainsaw-wielding George Washington teams with beer-loving bro Sam Adams to take down the Brits in a tongue-in-cheek riff on the American Revolution.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZDk0YmYwZDAtNGMzNi00YWY1LThhOTctNWRiMGY2YzZmMjNiXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_.jpg",
  "severid1":{
      "FHD1080p":{ "size" : "2.43 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/d86bodq0gtkimfs"},
      "HD720p":{ "size" : "1.19 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/7dc9qz2713mbx0j"},
      "SD480p":{ "size" : "619 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/hnjrpk3573ynvp5"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "2.43 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/America%20the%20Motion%20Picture%20(2021)/America%20the%20Motion%20Picture%20(2021)%20English%20WEB-DL%201080p.mp4"},
      "HD720p":{ "size" : "1.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/America%20the%20Motion%20Picture%20(2021)/America%20the%20Motion%20Picture%20(2021)%20English%20WEB-DL%20720p.mp4"},
      "SD480p":{ "size" : "619 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/America%20the%20Motion%20Picture%20(2021)/America%20the%20Motion%20Picture%20(2021)%20English%20WEB-DL%20480p.mp4"}
    },
    "severid33":{
      "FHD1080p":{ "size" : "2.43 GB", "Link": "https://mega.nz/file/xnVGyKCL#ebzBia2hSDQezbkz3QckiXq1WCvxS_4GksQws2uLxMk"},
      "HD720p":{ "size" : "1.19 GB", "Link": "https://mega.nz/file/J39W0Qib#0YgMh_Y3m579l5cuqz-h9Nc--huHL2aQI1DtHZNfTzw"},
      "SD480p":{ "size" : "619 MB", "Link": "https://mega.nz/file/M3MRgK6C#rdPh7k7jEHyhHyrbRNjkbg98wgUkPyAD1r0jygveRHw"}
     }
 },
 
 {
    id : 24114669,
    "Title" : "Leo", 
    "IMDbRating" : "7.7", 
    "RunningTime" : "164 minutes", 
    "Genres" : "Action, Crime, Drama, Thriller", 
    "Introduction" : "Parthiban is a mild-mannered cafe owner in Kashmir, who fends off a gang of murderous thugs and gains attention from a drug cartel claiming he was once a part of them.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODcwZDhjNzMtMDA5Mi00OTlkLWI5MTktZWExODA1NDA5ZWE5XkEyXkFqcGdeQXVyMTUyNjIwMDEw._V1_.jpg",
  "severid3":{
      "FHD1080p":{ "size" : "4.03 GB	", "Link": "https://mega.nz/file/F7dGDIxD#tdLXzkEgF15ibDHmJE3f-od3Gr9oj-x7B_w5qA3wIX4"},
      "HD720p":{ "size" : "1.94 GB", "Link": "https://mega.nz/file/BikA0bwQ#ZFYSbxr2nFp3Z4j8zvf4VptV8CDU-Fnc1yyA0T2yAU8"},
      "SD480p":{ "size" : "1 GB", "Link": "https://mega.nz/file/FucFHILC#am0dXvcqiy2K93WL_kYfnk6jPvyQP42c9ZIE70BhEvM"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "4.03 GB", "Link": "https://ddl.sinhalasub.net/AgADBR1262"},
      "HD720p":{ "size" : "1.94 GB", "Link": "https://ddl.sinhalasub.net/AgADDB1266"},
      "SD480p":{ "size" : "1 GB", "Link": "https://ddl.sinhalasub.net/AgADfw1264"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "4.03 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Leo%20(2023)/Leo%20(2023)%20Tamil%20HC-HDRip%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.94 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Leo%20(2023)/Leo%20(2023)%20Tamil%20HC-HDRip%20-%20720p.mp4"},
      "SD480p":{ "size" : "1 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Leo%20(2023)/Leo%20(2023)%20Tamil%20HC-HDRip%20-%20480p.mp4"}
     }
 },
 
 {
    id : 79509566,
    "Title" : "Paramporul", 
    "IMDbRating" : "7.5", 
    "RunningTime" : "146 minutes", 
    "Genres" : "Action, Thriller", 
    "Introduction" : "Revolves around the smuggling mafia of sacred temple idols and a cop who involves with a smuggler to make money out of a sacred idol, possessed by a murder victim.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNDg2ZDc4NDQtYzQxYi00NTUzLTk5ODQtYWRlM2Q0MThlNzliXkEyXkFqcGdeQXVyMTY3ODkyNDkz._V1_.jpg",
  "severid3":{
      "FHD1080p":{ "size" : "3.61 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paramporul%20(2023)/Paramporul%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.78 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paramporul%20(2023)/Paramporul%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20720p.mp4"},
      "SD480p":{ "size" : "922 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paramporul%20(2023)/Paramporul%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "3.61 GB", "Link": "https://mega.nz/file/MqNy3CrS#SIuV3SFAxlBl-UVUcm6oje-W7daVpLV1LQwOwjHHD-0"},
      "HD720p":{ "size" : "1.78 GB", "Link": "https://mega.nz/file/UjMnwL4C#juDOI_LIyZp9Ef9Xbe8AQfvf4-Y5NJe614hAAoHva-s"},
      "SD480p":{ "size" : "922 MB", "Link": "https://mega.nz/file/B3kEmIwa#S4Kg6-sgAdGbKA0VRGczWA2E-7rxwkVnIigyvyOtWgU"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "3.61 GB", "Link": "https://ddl.sinhalasub.net/AgAD_h1260"},
      "HD720p":{ "size" : "1.78 GB", "Link": "https://ddl.sinhalasub.net/AgADfQ1258"},
      "SD480p":{ "size" : "922 MB", "Link": "https://ddl.sinhalasub.net/AgADfA1256"}
     }
 },
 
 {
    id : 22247762,
    "Title" : "The White Storm 3: Heaven or Hell", 
    "IMDbRating" : "5.8", 
    "RunningTime" : "125 minutes", 
    "Genres" : "Action, Crime, Drama", 
    "Introduction" : "An undercover cop who invade a drug cartel led by a notorious Thai drug lord. In classic heroic bloodshed fashion, the two develop a bond of brotherhood.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOGE4NDI0MDItOTQ4NC00N2Q3LTk1MmYtOWUwMzQyMWU2YWU3XkEyXkFqcGdeQXVyODMyOTYyMTM@._V1_.jpg",
  "severid3":{
      "FHD1080p":{ "size" : "3.12 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)%20Korean%20BluRay%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.53 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)%20Korean%20BluRay%20-%20720p.mp4"},
      "SD480p":{ "size" : "794 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)/The%20White%20Storm%203%20Heaven%20Or%20Hell%20(2023)%20Korean%20BluRay%20-%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "3.12 GB", "Link": "https://mega.nz/file/kuFzXBJS#zMMPdJEiExx4NLut4CMymkJAubLB8zW_JJVdxvB9ba8"},
      "HD720p":{ "size" : "1.53 GB", "Link": "https://mega.nz/file/FzcXDJDZ#w8bcpxmoqVY4XdqVR_tpsb0dZQ7RCmscHUT0p6gitKU"},
      "SD480p":{ "size" : "794 MB", "Link": "https://mega.nz/file/t7N2nQzK#yP6AbVO5doUfB07LxCeWWUyxTcdU96cO0yDWXBXFkMU"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "3.12 GB", "Link": "https://ddl.sinhalasub.net/AgAD6x1254"},
      "HD720p":{ "size" : "1.53 GB", "Link": "https://ddl.sinhalasub.net/AgADeg1252"},
      "SD480p":{ "size" : "794 MB", "Link": "https://ddl.sinhalasub.net/AgADew1250"}
     }
 },
 
 {
    id : 35733369,
    "Title" : "Jawan", 
    "IMDbRating" : "7.1", 
    "RunningTime" : "169 minutes", 
    "Genres" : "Action, Thriller", 
    "Introduction" : "A high-octane action thriller which outlines the emotional journey of a man who is set to rectify the wrongs in the society.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOWI5NmU3NTUtOTZiMS00YzA1LThlYTktNDJjYTU5NDFiMDUxXkEyXkFqcGdeQXVyMTUzNjEwNjM2._V1_FMjpg_UX1000_.jpg",
  "severid1":{
      "FHD1080p":{ "size" : "4.29 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/uigffrasnw67ofw"},
      "HD720p":{ "size" : "2.11 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/oowp9eclsu7ezuz"},
      "SD480p":{ "size" : "1.06 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/uj1gd0aq9u8p9m2"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "4.29 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jawan%20(2023)/Jawan%20(2023)%20Hindi%20WEB-DL%201080p.mp4"},
      "HD720p":{ "size" : "2.11 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jawan%20(2023)/Jawan%20(2023)%20Hindi%20WEB-DL%20720p.mp4"},
      "SD480p":{ "size" : "1.06 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jawan%20(2023)/Jawan%20(2023)%20Hindi%20WEB-DL%20480p.mp4"}
    },
    "severid3":{
      "FHD1080p":{ "size" : "4.29 GB", "Link": "https://mega.nz/file/Zy8n3Qpb#MJFQqhcqoREzqDLukDkyHR_1CoQk2cdpYkjrRLG2fw4"},
      "HD720p":{ "size" : "2.15 GB", "Link": "https://mega.nz/file/gyU2lKbK#3YgAnonSMguTkkk1myWo4WeTWHU_99fq7qKTkSPNCD4"},
      "SD480p":{ "size" : "1.06 GB", "Link": "https://mega.nz/file/4zsnxSgC#rZc1159IMtg-FWVWtwaShvUQqMvOvzK-OlKQUjoleNo"}
     }
 },
 
 {
    id : 34266933,
    "Title" : "Sister Death", 
    "IMDbRating" : "5.7", 
    "RunningTime" : "91 minutes", 
    "Genres" : "Horror, Mystery", 
    "Introduction" : "After a childhood marked by a miracle, Narcisa, a novice nun joins a school to teach young girls.", 
     "MovieImage" : "https://resizing.flixster.com/QSZxX8NjrXJGX70N8ZyfoF8Lpko=/206x305/v2/https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/p25556709_v_v13_ac.jpg",
  "severid3":{
      "FHD1080p":{ "size" : "2.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sister%20Death%20(2023)/Sister%20Death%20(2023)%20Spanish%20WEB-DL%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.10 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sister%20Death%20(2023)/Sister%20Death%20(2023)%20Spanish%20WEB-DL%20-%20720p.mp4"},
      "SD480p":{ "size" : "570 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Sister%20Death%20(2023)/Sister%20Death%20(2023)%20Spanish%20WEB-DL%20-%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "2.22 GB", "Link": "https://mega.nz/file/NnsjQCgA#YYiWhoQrxzoskOCiIoOfznvxuGKCdtfGkuA5eLLRksM"},
      "HD720p":{ "size" : "1.10 GB", "Link": "https://mega.nz/file/Yut1hBRZ#mahfivUri4Qf-waRVhUq4ypMvYwogPa_h7asZgKK8EI"},
      "SD480p":{ "size" : "570 MB", "Link": "https://mega.nz/file/h69y3L4Z#RCgM5ahBQvqElz734zV03pHiXUKz3-99AU8EwTDKc0A"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "2.22 GB", "Link": "https://ddl.sinhalasub.net/AgADgx1233"},
      "HD720p":{ "size" : "1.10 GB", "Link": "https://ddl.sinhalasub.net/AgADLR1231"},
      "SD480p":{ "size" : "570 MB", "Link": "https://ddl.sinhalasub.net/AgADLB1229"}
     }
 },
 
 {
    id : 64690912,
    "Title" : "A Haunting in Venice", 
    "IMDbRating" : "6.6", 
    "RunningTime" : "103 minutes", 
    "Genres" : "Crime, Drama, Horror, Mystery, Thriller", 
    "Introduction" : "In post-World War II Venice, Poirot, now retired and living in his own exile, reluctantly attends a seance. But when one of the guests is murdered, it is up to the former detective to once again uncover the killer.", 
     "MovieImage" : "https://lumiere-a.akamaihd.net/v1/images/p_ahauntinginvenice_1890_v2_4ae663c2.jpeg",
  "severid3":{
      "FHD1080p":{ "size" : "2.58 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/A%20Haunting%20in%20Venice%20(2023)/A%20Haunting%20in%20Venice%20(2023)%20English%20WEB-DL%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.27 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/A%20Haunting%20in%20Venice%20(2023)/A%20Haunting%20in%20Venice%20(2023)%20English%20WEB-DL%20-%20720p.mp4"},
      "SD480p":{ "size" : "662 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/A%20Haunting%20in%20Venice%20(2023)/A%20Haunting%20in%20Venice%20(2023)%20English%20WEB-DL%20-%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "2.58 GB", "Link": "https://mega.nz/file/cvN20QIR#pyj2Q6juVRtaKvC1WQ0wDaif0Rxo0ph4PM1WgVvOKlw"},
      "HD720p":{ "size" : "1.27 GB", "Link": "https://mega.nz/file/138THLqB#WlrGf4rxJXYiYJxIEyn08XdK1LLRgcnrlNV3ci-60Fw"},
      "SD480p":{ "size" : "662 MB", "Link": "https://mega.nz/file/4jtjATSC#vsdkQq6wHwapFaUOfT5O93WMbijstmuRCW6W6-yk85k"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "2.58 GB", "Link": "https://ddl.sinhalasub.net/AgADZx1227"},
      "HD720p":{ "size" : "1.27 GB", "Link": "https://ddl.sinhalasub.net/AgADJB1225"},
      "SD480p":{ "size" : "662 MB", "Link": "https://ddl.sinhalasub.net/AgADHx1223"}
     }
 },
 
 {
    id : 9007462,
    "Title" : "Iraivan", 
    "IMDbRating" : "4.6", 
    "RunningTime" : "154 minutes", 
    "Genres" : "Action, Crime, Drama, Horror, Thriller", 
    "Introduction" : "A city lives in fear of a psychotic killer, and confidence in the police wanes. Arjun and Andrew finally arrest the killer, but he escapes and chaos reigns again.", 
     "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMDk2OTRhNmEtMDAwMy00MjliLThiZTItNDE5ZGEwZDg1ODRmXkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_.jpg",
  "severid3":{
      "FHD1080p":{ "size" : "3.35 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Iraivan%20(2023)/Iraivan%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%201080p.mp4"},
      "HD720p":{ "size" : "1.65 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Iraivan%20(2023)/Iraivan%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20720p.mp4"},
      "SD480p":{ "size" : "851 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Iraivan%20(2023)/Iraivan%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20480p.mp4"}
    },
    "severid2":{
      "FHD1080p":{ "size" : "3.35 GB", "Link": "https://mega.nz/file/1uNBgZwA#kT0MHhzOMh_eKzQ3unCKG7mhGzUCqyXN8NOBllSq5DY"},
      "HD720p":{ "size" : "1.65 GB", "Link": "https://mega.nz/file/M6VXUBKT#8qTcJuwy5tlYPoITNEkMHftCz7vrCZTevgWi-kkYF4E"},
      "SD480p":{ "size" : "851 MB", "Link": "https://mega.nz/file/gqsEgBhS#TfMn_ifXeqR2munpK1BpO8H7KU-kTPFRoaGkY5aj_dE"}
    },
    "severid1":{
      "FHD1080p":{ "size" : "3.35 GB", "Link": "https://ddl.sinhalasub.net/AgAD7x1221"},
      "HD720p":{ "size" : "1.65 GB", "Link": "https://ddl.sinhalasub.net/AgADHh1219"},
      "SD480p":{ "size" : "851 MB", "Link": "https://ddl.sinhalasub.net/AgADGx1217"}
     }
 },

      
 {
  id : 88432266,
    "Title": "Five Nights at Freddy's", 
  "IMDbRating" : "5.5", 
  "RunningTime" : "109 minutes", 
  "Genres" : "Horror, Mystery, Thriller", 
  "Introduction": "A troubled security guard begins working at Freddy Fazbear s Pizza. During his first night on the job, he realizes that the night shift won't be so easy to get through. Pretty soon he will unveil what actually happened at Freddy's.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMjNkM2FlODYtZmY5My00MmE3LWFhMGItOGU5MWJmM2M2ZWYxXkEyXkFqcGdeQXVyMTY3ODkyNDkz._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.75 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Five%20Nights%20at%20Freddys%20(2023)/Five%20Nights%20at%20Freddys%20(2023)%20English%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.35 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Five%20Nights%20at%20Freddys%20(2023)/Five%20Nights%20at%20Freddys%20(2023)%20English%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "700 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Five%20Nights%20at%20Freddys%20(2023)/Five%20Nights%20at%20Freddys%20(2023)%20English%20WEB-DL%20-%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.75 GB", "Link": "https://mega.nz/file/UrkwgBrS#QE0c5R-5UODBWRmKjUH-pgYcUePwWcCkMNUSI3t3Y5c"},
    "HD720p":{ "size" : "1.35 GB", "Link": "https://mega.nz/file/ZukTmIrC#gNfkUmJiRBpQlWPg3VkxiIaU0SaS4uqrLMMusQTJ9iQ"},
    "SD480p":{ "size" : "700 MB", "Link": "https://mega.nz/file/tyE3UZxL#BoW3aW6ZwomuMIy2wszl8c_OqLkIQYjr4TxwgU84Mq8"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.75 GB", "Link": "https://ddl.sinhalasub.net/AgADkR1215"},
    "HD720p":{ "size" : "1.35 GB", "Link": "https://ddl.sinhalasub.net/AgAD3A1213"},
    "SD480p":{ "size" : "700 MB", "Link": "https://ddl.sinhalasub.net/AgAD2w1211"}
   }
},

{
  id : 33113292,
    "Title" : "Red Sandal Wood", 
  "IMDbRating" : "7.5", 
  "RunningTime" : "94 minutes", 
  "Genres" : "Action, Thriller", 
  "Introduction": "An aspiring boxer hailing from North Madras travels to Renigunta looking for his Girlfriend's missing brother. He finds him and uncovers a scandal regarding Red Sandalwood smuggling.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYzM4N2UyMTctODBlOC00M2U4LTk1ZDktZWQ2NDU2YjlhNWU2XkEyXkFqcGdeQXVyMTA4MzQ4NzMw._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.27 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Red%20Sandal%20Wood%20(2023)/Red%20Sandal%20Wood%20(2023)%20Tamil%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.12 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Red%20Sandal%20Wood%20(2023)/Red%20Sandal%20Wood%20(2023)%20Tamil%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "580 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Red%20Sandal%20Wood%20(2023)/Red%20Sandal%20Wood%20(2023)%20Tamil%20WEB-DL%20-%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.27 GB", "Link": "https://mega.nz/file/QrVByI6C#7HOAzv2HITiXuO0nVOh1bCEeWY4PzJOzL9DJr_gsq-g"},
    "HD720p":{ "size" : "1.12 GB", "Link": "https://mega.nz/file/FvMBiBzB#unEhM2tPMs8KTjAKIGADj9x8i_32E5fCtYPmRLfptvQ"},
    "SD480p":{ "size" : "580 MB", "Link": "https://mega.nz/file/Qn8FSZ7I#4sGYsxi0lgJ47Yp_Y88VNDQu53wW-F0Y53dk7nPThm8"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.27 GB", "Link": "https://ddl.sinhalasub.net/AgADQx1206"},
    "HD720p":{ "size" : "1.12 GB", "Link": "https://ddl.sinhalasub.net/AgADtB1204"},
    "SD480p":{ "size" : "580 MB", "Link": "https://ddl.sinhalasub.net/AgADsh1202"}
   }
},

{
  id : 11179558,
    "Title" : "Thudikkum Karangal", 
  "IMDbRating" : "6.1", 
  "RunningTime" : "140 minutes", 
  "Genres" : "Action", 
  "Introduction" : "Vetri, a YouTuber, discovers an old man fainting while searching for his son. He uses his channel to help this old man and ends up attracting antisocial people seeking revenge.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTM4YTIzZDMtYzE2YS00MGVlLTk5OTAtYWNhZGY0MGQ5YzYxXkEyXkFqcGdeQXVyMTU0ODI1NTA2._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thudikkum%20Karangal%20(2023)/Thudikkum%20Karangal%20(2023)%20Tamil%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.57 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thudikkum%20Karangal%20(2023)/Thudikkum%20Karangal%20(2023)%20Tamil%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "814 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thudikkum%20Karangal%20(2023)/Thudikkum%20Karangal%20(2023)%20Tamil%20WEB-DL%20-%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.19 GB", "Link": "https://mega.nz/file/MyFkDAwT#hyAz6i5iHDYyH74_uUH8xZpP7fFBFGYCYKBs0HhKYHE"},
    "HD720p":{ "size" : "1.57 GB", "Link": "https://mega.nz/file/86lSETiZ#JzBcqa06X8mvJaMgXxBS6p9TZmIqsdSY6wYsXUAKWIY"},
    "SD480p":{ "size" : "814 MB", "Link": "https://mega.nz/file/B38XlQRD#IBajHHSk5wa4QkjjXhCeQV3ExEEVKQtsZkBTjnEfnWI"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.19 GB", "Link": "https://ddl.sinhalasub.net/AgADbx1188"},
    "HD720p":{ "size" : "1.57 GB", "Link": "https://ddl.sinhalasub.net/AgADeR1186"},
    "SD480p":{ "size" : "814 MB", "Link": "https://ddl.sinhalasub.net/AgADbB1184"}
   }
},

{
  id : 41262676,
    "Title" : "Awareness", 
  "IMDbRating" : "5.2", 
  "RunningTime" : "112 minutes", 
  "Genres" : "Mystery, Sci-Fi, Thriller", 
  "Introduction": "A teenager who lives with his father on the outskirts by running small scams using Ian's ability to generate visual illusions upon unsuspecting victims. Things get complicated when his powers get out of control in public.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNzM4YzYzM2ItYTIxNy00MDIyLWE0ZTEtMTIxMmRiODE5MzQxXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.82 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Awareness%20(2023)/Awareness%20(2023)%20Spanish%20WEBRip%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.39 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Awareness%20(2023)/Awareness%20(2023)%20Spanish%20WEBRip%20-%20720p.mp4"},
    "SD480p":{ "size" : "722 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Awareness%20(2023)/Awareness%20(2023)%20Spanish%20WEBRip%20-%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.82 GB", "Link": "https://mega.nz/file/kqUW0C6Q#o65TYNJavVgKzLwc_hypYFg6wQ3zzuaTBP6vcFD9ang"},
    "HD720p":{ "size" : "1.39 GB", "Link": "https://mega.nz/file/lyl1TDzb#UxWPJrdiMaC_ecXycF9MRJ0EeJO6f9YZyd_vAX3xGBg"},
    "SD480p":{ "size" : "722 MB", "Link": "https://mega.nz/file/Uy002IjB#jMEQH7PcvdOH2_1zqGV8Nki2z6wF4thVkMAY1TVEMVw"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.82 GB", "Link": "https://ddl.sinhalasub.net/AgADOR1200"},
    "HD720p":{ "size" : "1.39 GB", "Link": "https://ddl.sinhalasub.net/AgADpR1198"},
    "SD480p":{ "size" : "722 MB", "Link": "https://ddl.sinhalasub.net/AgADpB1196"}
   }
},

{
  id : 18276014,
    "Title" : "Saw", 
  "IMDbRating" : "7.6", 
  "RunningTime" : "103 minutes", 
  "Genres" : "Horror, Mystery, Thriller", 
  "Introduction": "Two strangers awaken in a room with no recollection of how they got there, and soon discover they're pawns in a deadly game perpetrated by a notorious serial killer.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2M1MzI1MWYtYmM2ZC00OWY3LTk0ZGMtNmRkNzU1NzEzMWE5XkEyXkFqcGdeQXVyODUwOTkwODk@._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.57 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20(2004)/Saw%20(2004)%20English%20BluRay%201080p.mp4"},
    "HD720p":{ "size" : "1.27 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20(2004)/Saw%20(2004)%20English%20BluRay%20720p.mp4"},
    "SD480p":{ "size" : "659 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20(2004)/Saw%20(2004)%20English%20BluRay%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.57 GB", "Link": "https://mega.nz/file/tnlwzBAS#x18L6Pv7IbBbMaWxllCS2x7jp8IyxkYE3Et6rTo3ISg"},
    "HD720p":{ "size" : "1.27 GB", "Link": "https://mega.nz/file/Fqk1Aa5K#JcYqYx4h0NG6qGl0MxtRsToaHOVK1wZnVDRVh5VGPxI"},
    "SD480p":{ "size" : "659 MB", "Link": "https://mega.nz/file/9qVwHJ4b#oH9E-4NmUL0gd6ZkRrU-Hg6ifC_p4f2Hib0YyBojRBw"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.57 GB", "Link": "https://ddl.sinhalasub.net/AgADex1194"},
    "HD720p":{ "size" : "1.27 GB", "Link": "https://ddl.sinhalasub.net/AgADMh1192"},
    "SD480p":{ "size" : "659 MB", "Link": "https://ddl.sinhalasub.net/AgADMR1190"}
   }
},

{
  id : 59988657,
    "Title" : "Afwaah", 
  "IMDbRating" : "5.6", 
  "RunningTime" : "126 minutes", 
  "Genres" : "Drama, Thriller", 
  "Introduction" : "An advertising professional and a political heiress whose lives are upended by a viral social media rumour.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2Y3YzUyYmMtNTQzNC00NWM0LThiYzgtYmM0OTc2ZDU4Yjk4XkEyXkFqcGdeQXVyMTE0MTY2Mzk2._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.06 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Afwaah%20(2023)/Afwaah%20(2023)%20Hindi%20HQ%20HDRip%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.51 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Afwaah%20(2023)/Afwaah%20(2023)%20Hindi%20HQ%20HDRip%20-%20720p.mp4"},
    "SD480p":{ "size" : "780 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Afwaah%20(2023)/Afwaah%20(2023)%20Hindi%20HQ%20HDRip%20-%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.06 GB", "Link": "https://mega.nz/file/MulgnK4D#AaqGCF5VpatIKvEyEybJ6eUMWHWjcOId-xrMnuQmDec"},
    "HD720p":{ "size" : "1.51 GB", "Link": "https://mega.nz/file/kiMBQZyC#_mFw4vCZz-CxIH40ZNwhUsdjdHdql_Y5UiWwc0qoZhk"},
    "SD480p":{ "size" : "780 MB", "Link": "https://mega.nz/file/ln1hCaAb#i9VlcimrWM-xnUx2_PiXnWnyVu8wG_abPATXrzErT4I"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.06 GB", "Link": "https://ddl.sinhalasub.net/AgADXx1182"},
    "HD720p":{ "size" : "1.51 GB", "Link": "https://ddl.sinhalasub.net/AgADWx1180"},
    "SD480p":{ "size" : "780 MB", "Link": "https://ddl.sinhalasub.net/AgADWh1178"}
   }
},

{
  id : 76883110,
    "Title" : "Chandramukhi 2", 
  "IMDbRating" : "2.6", 
  "RunningTime" : "172 minutes", 
  "Genres" : "Comedy, Drama, Horror", 
  "Introduction" : "A wealthy family faces numerous problems and reunites with their long-lost relatives to pray to their deity for solutions, unaware it will reawaken the feud between a classical dancer, Chandramukhi and Vettaiyan Raja from centuries ago.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTg1NzhkODQtOTI3Yi00NDdlLTk1YTEtNjEwM2VmZGI1YzQ2XkEyXkFqcGdeQXVyMTU0ODI1NTA2._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.69 GB", "Link": "https://mega.nz/file/dj9kyZhA#JKVhzKjbK4LzsAFryESQ5GozvqI-BqBojYEREhOSFeI"},
    "HD720p":{ "size" : "1.92 GB", "Link": "https://mega.nz/file/gqdQGbLa#55w2KOhvjVW-zp4Oa-XcZGdzMJlIxvN8Q9AoIdgaeIA"},
    "SD480p":{ "size" : "995 MB", "Link": "https://mega.nz/file/Bq8wibJA#_SwJQDAGCMLuKL8ol9Tn6k58VtkkScPGlePJgnJGExM"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.71 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chandramukhi%202%20(2023)/Chandramukhi%202%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.92 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chandramukhi%202%20(2023)/Chandramukhi%202%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "995 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chandramukhi%202%20(2023)/Chandramukhi%202%20(2023)%20Tamil%20TRUE%20WEB-DL%20-%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.71 GB", "Link": "https://ddl.sinhalasub.net/AgADDh1174"},
    "HD720p":{ "size" : "1.92 GB", "Link": "https://ddl.sinhalasub.net/AgADLh1176"},
    "SD480p":{ "size" : "995 MB", "Link": "https://ddl.sinhalasub.net/AgADLR1172"}
   }
},

{
  id : 79079402,
    "Title" : "The Childe", 
  "IMDbRating" : "6.9", 
  "RunningTime" : "118 minutes", 
  "Genres" : "Action, Drama, Thriller", 
  "Introduction" : "The Childe focuses on a man born to a Korean father and a Filipino mother who aspires to be a boxer. He travels back to Korea in the hopes of tracking down his father, and in the process, he gets mixed up with some bad people.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BN2EyYmZlMGMtZmFkOC00ODA1LTk0NjItYjFkZWYxNTRlYzdkXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.51 GB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "1.25 GB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "659 MB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20480p.mp4"},
    "LD 360p":{ "size" : "557 MB", "Link": "https://001.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20360p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.51 GB", "Link": "https://002.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "1.25 GB", "Link": "https://002.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "659 MB", "Link": "https://002.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20480p.mp4"},
    "LD 360p":{ "size" : "557 MB", "Link": "https://002.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20360p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.51 GB", "Link": "https://003.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "1.25 GB", "Link": "https://003.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "659 MB", "Link": "https://003.gihancloudflare.workers.dev/0:/Movie/Korean/The%20Childe%20(2023)/The%20Childe%20(2023)%20Korean%20HDRip%20480p.mp4"},
   }
},

{
  id : 30744234,
    "Title" : "Rama Banam", 
  "IMDbRating" : "4.3", 
  "RunningTime" : "140 minutes", 
  "Genres" : "Action, Drama", 
  "Introduction" : "Two siblings, who despite their difference in ideologies come together to fight for their common goal.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BN2E0ZDBmZGQtZmFlZS00NmM3LTg1MzQtYjI1ZWJiNDJlOTgzXkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.47 GB", "Link": "https://mega.nz/file/BudhBAaQ#XiCVdpZEdFd1s_GrqXhbIjSwMB6FV3t6rxCZCO7gS84"},
    "HD720p":{ "size" : "1.71 GB", "Link": "https://mega.nz/file/MnsC3aIQ#UIbi1jBLV5-olRztUF6gATadzwYGp_6MGVoG0fIGLRg"},
    "SD480p":{ "size" : "886 MB", "Link": "https://mega.nz/file/E710BCaL#DDz2AhFbLE_BQEencpjcIIB0OJ-2N8e9rFygkMfe5vw"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.47 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ramabanam%20(2023)/Ramabanam%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.71 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ramabanam%20(2023)/Ramabanam%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "886 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Ramabanam%20(2023)/Ramabanam%20(2023)%20Telugu%20TRUE%20WEB-DL%20-%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.47 GB", "Link": "https://ddl.sinhalasub.net/AgADtB1163"},
    "HD720p":{ "size" : "1.71 GB", "Link": "https://ddl.sinhalasub.net/AgADdB1161"},
    "SD480p":{ "size" : "886 MB", "Link": "https://ddl.sinhalasub.net/AgADax1159"}
   }
},





{
  id : 33853193,
    "Title" : "Tatsama Tadbhava", 
  "IMDbRating" : "6.9", 
  "RunningTime" : "122 minutes", 
  "Genres" : "Crime, Thriller", 
  "Introduction": "A seasoned police inspector delves into a perplexing missing person's investigation that reveals a web of secrets, lies, and mystery.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYWUxMGQzMTAtMDQxNy00OWZjLWIxNDItMWI3NzE0MDU5Y2MzXkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/biggp39o3haaqzs"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/2fg5ulmd537xqbd"},
    "SD480p":{ "size" : "733 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/jpz0z2nugyboofa"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thathsama%20Thathbhava%20(2023)/Thathsama%20Thathbhava%20(2023)%20Kannada%20WEB-DL%20-%201080p.mp4"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thathsama%20Thathbhava%20(2023)/Thathsama%20Thathbhava%20(2023)%20Kannada%20WEB-DL%20-%20720p.mp4"},
    "SD480p":{ "size" : "733 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Thathsama%20Thathbhava%20(2023)/Thathsama%20Thathbhava%20(2023)%20Kannada%20WEB-DL%20-%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://ddl.sinhalasub.net/AgAD9B1149"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://ddl.sinhalasub.net/AgADxB1147"},
    "SD480p":{ "size" : "733 MB", "Link": "https://ddl.sinhalasub.net/AgADwx1145"}
   }
},

{
  id : 31025204,
    "Title" : "Werewolf by Night", 
  "IMDbRating" : "7.1", 
  "RunningTime" : "52 minutes", 
  "Genres" : "Action, Adventure, Drama, Fantasy, Horror, Mystery, Thriller", 
  "Introduction" : "Follows a lycanthrope superhero who fights evil using the abilities given to him by a curse brought on by his bloodline.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZjI3NjI3NDQtY2RmYS00NTJjLWI0YTQtNTE4NmZiN2UzMWJjXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "1.29 GB", "Link": "https://mega.nz/file/ByMFCboT#QDn_7Fu55LhGjGPXmfnpjLVHsqdAluldj4xPNjB9tOg"},
    "HD720p":{ "size" : "653 MB", "Link": "https://mega.nz/file/cjd1VDgA#IRbs8u5OYHeOVao9nh40ADbwZpV36Lt3BD6sxH2xHCY"},
    "SD480p":{ "size" : "332 MB", "Link": "https://mega.nz/file/hvlGHapB#DDMxjALnHDwz_gUsbLgrDATFbDOyNXawng7zO_WqDy8"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "1.29 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Werewolf%20by%20Night%20(2022)/Werewolf%20by%20Night%20(2022)%20%5Bcolour%5D%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "653 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Werewolf%20by%20Night%20(2022)/Werewolf%20by%20Night%20(2022)%20%5Bcolour%5D%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "332 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Werewolf%20by%20Night%20(2022)/Werewolf%20by%20Night%20(2022)%20%5Bcolour%5D%20WEBRip%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "1.29 GB", "Link": "https://ddl.sinhalasub.net/AgADkx1122"},
    "HD720p":{ "size" : "653 MB", "Link": "https://ddl.sinhalasub.net/AgADih1120"},
    "SD480p":{ "size" : "332 MB", "Link": "https://ddl.sinhalasub.net/AgADiB1118"}
   }
},

{
  id : 76605144,
    "Title" : "Paayum Oli Nee Yenakku", 
  "IMDbRating" : "4.8", 
  "RunningTime" : "119 minutes", 
  "Genres" : "Action, Drama, Family, Thriller", 
  "Introduction" : "Following a catastrophe in his life, Aravind, an entrepreneur with low light blindness, embarks on a relentless hunt utilising only audio clues.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYWU4ODRiODAtMTYzZi00ZjJlLWE3YTItMTcxMTUyMzZlZDgyXkEyXkFqcGdeQXVyMTY1MzAyNjU4._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.91 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/mq6788gsue17q7l"},
    "HD720p":{ "size" : "1.43 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/cx95n1av0a4bx4a"},
    "SD480p":{ "size" : "746 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/gp0x40tsbpfggfd"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.91 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paayum%20Oli%20Nee%20Yenakku%20(2023)/Paayum%20Oli%20Nee%20Yenakku%20(2023)%20Tamil%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.43 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paayum%20Oli%20Nee%20Yenakku%20(2023)/Paayum%20Oli%20Nee%20Yenakku%20(2023)%20Tamil%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "746 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Paayum%20Oli%20Nee%20Yenakku%20(2023)/Paayum%20Oli%20Nee%20Yenakku%20(2023)%20Tamil%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.91 GB", "Link": "https://ddl.sinhalasub.net/AgADfB1086"},
    "HD720p":{ "size" : "1.43 GB", "Link": "https://ddl.sinhalasub.net/AgADyx1084"},
    "SD480p":{ "size" : "746 MB", "Link": "https://ddl.sinhalasub.net/AgADyR1082"}
   }
},

{
  id : 10575802,
    "Title" : "The Miracle Worker", 
  "IMDbRating" : "8.1", 
  "RunningTime" : "106 minutes", 
  "Genres" : "Biography, Drama", 
  "Introduction": "The story of 'Anne Sullivan' (qv)'s struggle to teach the blind and deaf 'Helen Keller' (qv) how to communicate.", 
   "MovieImage" : "https://media0029.elcinema.com/uploads/_315x420_8e3c21a65f27bc6dcd07a5d13db20970893bfb8c2b90e6634f04c938a850144f.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.66 GB", "Link": "https://mega.nz/file/g6UXCIJQ#SYByG93cXUp6ePke1gkRqTvVxN0NGmZ6fq6bw0yKXjA"},
    "HD720p":{ "size" : "1.31 GB", "Link": "https://mega.nz/file/1qMRRAiD#Jc1nqMjnOH5Mq4ZZHboPeQWjvhMcbQSVzbIwEy8Cwx0"},
    "SD480p":{ "size" : "676 MB", "Link": "https://mega.nz/file/871CiKxK#9hqAwtWbaZE8bRpZF6y_DeO2A-x_9TJ1unwC5W-INyw"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.66 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Miracle%20Worker%20(1962)/The%20Miracle%20Worker%20(1962)%20English%20BluRay%201080p.mp4"},
    "HD720p":{ "size" : "1.31 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Miracle%20Worker%20(1962)/The%20Miracle%20Worker%20(1962)%20English%20BluRay%20720p.mp4"},
    "SD480p":{ "size" : "676 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Miracle%20Worker%20(1962)/The%20Miracle%20Worker%20(1962)%20English%20BluRay%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.66 GB", "Link": "https://ddl.sinhalasub.net/AgADkh1080"},
    "HD720p":{ "size" : "1.31 GB", "Link": "https://ddl.sinhalasub.net/AgADzR1078"},
    "SD480p":{ "size" : "676 MB", "Link": "https://ddl.sinhalasub.net/AgADzB1076"}
   }
},

{
  id : 88127823,
    "Title" : "Harkara", 
  "IMDbRating" : "7.4", 
  "RunningTime" : "125 minutes", 
  "Genres" : "Drama, History", 
  "Introduction" : "Traces the history of the postal service from the times of the first postman to the current times.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BOTk5YjFiMTgtYzI1YS00NDZiLWJlYmEtYTZkOGJjYmYyOTRmXkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.79 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/5ydohcok10n62qr"},
    "HD720p":{ "size" : "1.37 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/301b507npxe31r3"},
    "SD480p":{ "size" : "712 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/hr0i0zjdlw9ouyo"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.79 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Harkara%20(2023)/Harkara%20(2023)%20Tamil%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.37 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Harkara%20(2023)/Harkara%20(2023)%20Tamil%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "712 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Harkara%20(2023)/Harkara%20(2023)%20Tamil%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.79 GB", "Link": "https://ddl.sinhalasub.net/AgADlx1092"},
    "HD720p":{ "size" : "1.37 GB", "Link": "https://ddl.sinhalasub.net/AgAD2B1090"},
    "SD480p":{ "size" : "712 MB", "Link": "https://ddl.sinhalasub.net/AgAD1x1088"}
   }
},

{
  id : 76227780,
    "Title" : "Pranaya Vilasam", 
  "IMDbRating" : "6.9", 
  "RunningTime" : "123 minutes", 
  "Genres" : "Comedy, Drama, Romance", 
  "Introduction" : "Portrays love stories of different characters who are at different stages of their lives.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTEyOTJkZjgtNmM0Yi00M2RhLTk0ZjEtMTE1N2JmY2UyYTI0XkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/kmyhej3puvbgt8s"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/onb2f0iqy1t9fqt"},
    "SD480p":{ "size" : "734 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/j0ndxgb97jookg5"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Pranaya%20Vilasam%20(2023)/Pranaya%20Vilasam%20(2023)%20Malayalam%20HQ%20HD%201080p.mp4"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Pranaya%20Vilasam%20(2023)/Pranaya%20Vilasam%20(2023)%20Malayalam%20HQ%20HD%20720p.mp4"},
    "SD480p":{ "size" : "734 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Pranaya%20Vilasam%20(2023)/Pranaya%20Vilasam%20(2023)%20Malayalam%20HQ%20HD%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.87 GB", "Link": "https://ddl.sinhalasub.net/AgADkh1024"},
    "HD720p":{ "size" : "1.41 GB", "Link": "https://ddl.sinhalasub.net/AgAD1x1022"},
    "SD480p":{ "size" : "734 MB", "Link": "https://ddl.sinhalasub.net/AgAD1h1020"}
   }
},

{
  id : 26269528,
    "Title" : "Kasargold", 
  "IMDbRating" : "5.7", 
  "RunningTime" : "139 minutes", 
  "Genres" : "Action, Drama, Thriller", 
  "Introduction" : "Albert ,a young and unassuming gold smuggler.Alongside his companion Faisal, they embark on an outrageously risky plan with the hope of gaining a fortune that could secure them a lifetime of wealth.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNzVhMDk0ZWYtOGY3MC00ZTc0LWE4Y2UtMTE2YzdhMWM4ZjQ1XkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.41 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/3qtd0tvifv9jenm"},
    "HD720p":{ "size" : "1.68 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/lvzovcvhlrnrf2c"},
    "SD480p":{ "size" : "869 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/dtwhj85b9tktjkr"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.41 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kasargold%20(2023)/Kasargold%20(2023)%20Malayalam%C2%A0TRUE%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.68 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kasargold%20(2023)/Kasargold%20(2023)%20Malayalam%C2%A0TRUE%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "869 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Kasargold%20(2023)/Kasargold%20(2023)%20Malayalam%C2%A0TRUE%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.41 GB", "Link": "https://ddl.sinhalasub.net/AgADiR1018"},
    "HD720p":{ "size" : "1.68 GB", "Link": "https://ddl.sinhalasub.net/AgADwx1016"},
    "SD480p":{ "size" : "869 MB", "Link": "https://ddl.sinhalasub.net/AgADwh1014"}
   }
},

{
  id : 12890934,
    "Title" : "Chattambi", 
  "IMDbRating" : "4.3", 
  "RunningTime" : "122 minutes", 
  "Genres" : "Drama", 
  "Introduction" : "Set in 1995, Chattambi follows the lives of abrasive outlaws in rural Idukki. When Kariah feels he is being excluded from the clan, he decides to lead his life on his own terms without realizing the grave consequences of his decision.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMjNiMTZlODgtODE2Zi00MzhjLWJiMDItNjQ2MjljMjRhN2UzXkEyXkFqcGdeQXVyMTU2NDkwOTAw._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.03 GB", "Link": "https://mega.nz/file/ZD8TBLoa#m5suzb65qSW4C1mF55tgDEHEW5zDpo7REo9j2PDJi_0"},
    "HD720p":{ "size" : "1.49 GB", "Link": "https://mega.nz/file/BCtymTJL#9oDMGQ1deF7qAuHQ96PzDy-6vrDhKJ8Ezh8eoIQxYgE"},
    "SD480p":{ "size" : "770 MB", "Link": "https://mega.nz/file/UG1i0SBK#wGov7_Mruztv0RwzkVnZ_k3F7_cK6hD0zb_TAG1hLXs"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.03 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chattambi%20(2022)/Chattambi%20(2022)%20Malayalam%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "1.49 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chattambi%20(2022)/Chattambi%20(2022)%20Malayalam%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "770 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chattambi%20(2022)/Chattambi%20(2022)%20Malayalam%20HDRip%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.03 GB", "Link": "https://ddl.sinhalasub.net/AgAD-A1007"},
    "HD720p":{ "size" : "1.49 GB", "Link": "https://ddl.sinhalasub.net/AgADVg1005"},
    "SD480p":{ "size" : "770 MB", "Link": "https://ddl.sinhalasub.net/AgADVQ1003"}
   }
},

{
  id : 17344203,
    "Title" : "Prema Vimanam", 
  "IMDbRating" : "6.1", 
  "RunningTime" : "146 minutes", 
  "Genres" : "Comedy, Drama, Romance", 
  "Introduction" : "Two kids with a dream to board a flight cross paths with a young couple who must urgently catch the flight to start a new life.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMDEwZjBkOGUtNTkzZC00M2RkLWEzNDItNzgwZDg2OGEzMDEzXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.59 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/pgcvj32ga1w8el5"},
    "HD720p":{ "size" : "1.77 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/uh6485nupoxr7l9"},
    "SD480p":{ "size" : "922 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/13pufbfikcpwkf9"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.59 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Vimanam%20(2023)/Prema%20Vimanam%20(2023)%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.77 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Vimanam%20(2023)/Prema%20Vimanam%20(2023)%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "922 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Prema%20Vimanam%20(2023)/Prema%20Vimanam%20(2023)%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.59 GB", "Link": "https://ddl.sinhalasub.net/AgAD9x985"},
    "HD720p":{ "size" : "1.77 GB", "Link": "https://ddl.sinhalasub.net/AgADfR983"},
    "SD480p":{ "size" : "922 MB", "Link": "https://ddl.sinhalasub.net/AgADex981"}
   }
},

   
{
  id : 17515091,
    "Title" : "The Fabelmans", 
  "IMDbRating" : "7.5", 
  "RunningTime" : "151 minutes", 
  "Genres" : "Drama", 
  "Introduction" : "Growing up in post-World War II era Arizona, young Sammy Fabelman aspires to become a filmmaker as he reaches adolescence, but soon discovers a shattering family secret and explores how the power of films can help him see the truth.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZGM1MzczNmQtMjBmYS00NTRhLWI0MzctNTFkZDc4OGUyODdjXkEyXkFqcGdeQXVyMjMxOTE0ODA@._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Fabelmans%20(2022)/The%20Fabelmans%20(2022)%20English%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "1.86 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Fabelmans%20(2022)/The%20Fabelmans%20(2022)%20English%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "962 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/The%20Fabelmans%20(2022)/The%20Fabelmans%20(2022)%20English%20WEBRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ddl.sinhalasub.net/AgADxR979"},
    "HD720p":{ "size" : "1.86 GB", "Link": "https://ddl.sinhalasub.net/AgADYx977"},
    "SD480p":{ "size" : "962 MB", "Link": "https://ddl.sinhalasub.net/AgADYh975"}
   },
  "severid1":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ddl.sinhalasub.net/AgADxR979"},
    "HD720p":{ "size" : "1.86 GB", "Link": "https://ddl.sinhalasub.net/AgADYx977"},
    "SD480p":{ "size" : "962 MB", "Link": "https://ddl.sinhalasub.net/AgADYh975"}
   }
},

{
  id : 1646203,
    "Title" : "Surongo", 
  "IMDbRating" : "6.7", 
  "RunningTime" : "150 minutes", 
  "Genres" : "Crime, Drama, Mystery, Romance, Thriller", 
  "Introduction" : "A simple electrician from a village turns to crime and goes to extreme lengths to satisfy his love.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYzQ5YTMxZWEtMWY3ZC00OTA4LWI5OGUtZjM3N2M5MTFhYWYwXkEyXkFqcGdeQXVyMTY3MTgzMjA0._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.96 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/2xx06m1blbz7ykv"},
    "HD720p":{ "size" : "1.95 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/nfslqvmfok8rwmb"},
    "SD480p":{ "size" : "1 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/goqs5vx838j780p"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Surongo%20(2023)/Surongo%20(2023)%20Bengali%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.95 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Surongo%20(2023)/Surongo%20(2023)%20Bengali%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "1 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Surongo%20(2023)/Surongo%20(2023)%20Bengali%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "3.96 GB", "Link": "https://ddl.sinhalasub.net/AgADCh961"},
    "HD720p":{ "size" : "1.95 GB", "Link": "https://ddl.sinhalasub.net/AgADLB959"},
    "SD480p":{ "size" : "1 GB", "Link": "http://ddl.sinhalasub.net/AgADKx957"}
   }
},

{
  id : 20428887,
    "Title" : "Chhota Bheem and the Curse of Damyaan", 
  "IMDbRating" : "N/A", 
  "RunningTime" : "N/A minutes", 
  "Genres" : "Action, Adventure, Comedy, Family, Fantasy", 
  "Introduction" : "Live action in which Bheem and his friend should destroy Damyaan from dark magic.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BZWZiOWUzNDgtMjlkMi00ZjFmLTk1OTgtMGEzMDE3YjVjNTYzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "1.60 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)%20English%20HQ%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "807 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)%20English%20HQ%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "409 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)/Chhota%20Bheem%20Adventure%20of%20Persia%20(2023)%20English%20HQ%20HDRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "1.60 GB", "Link": "https://ddl.sinhalasub.net/AgADJh955"},
    "HD720p":{ "size" : "807 MB", "Link": "https://ddl.sinhalasub.net/AgADJR953"},
    "SD480p":{ "size" : "409 MB", "Link": "https://ddl.sinhalasub.net/AgADJB951"}
   },

  "severid1":{
    "FHD1080p":{ "size" : "1.60 GB", "Link": "https://ddl.sinhalasub.net/AgADJh955"},
    "HD720p":{ "size" : "807 MB", "Link": "https://ddl.sinhalasub.net/AgADJR953"},
    "SD480p":{ "size" : "409 MB", "Link": "https://ddl.sinhalasub.net/AgADJB951"}
   }
},

{
  id : 26964564,
    "Title" : "Scarygirl", 
  "IMDbRating" : "5.3", 
  "RunningTime" : "90 minutes", 
  "Genres" : "Animation, Adventure, Fantasy, Mystery, Sci-Fi", 
  "Introduction" : "As her world is shrouded in darkness, a young girl must overcome her fears and travel to a mysterious city of light, save her father from a dangerous scientist and prevent the destruction of her planet.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTA2NTlkNDUtYjRmYS00ZjQ0LWIzMjctNDQxOTYzZDAzOGVlXkEyXkFqcGdeQXVyMTUzMzU4Nw@@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.19 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/8kv2rnf7lhf79he"},
    "HD720p":{ "size" : "1.09 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/mti19oqrw4gjo8e"},
    "SD480p":{ "size" : "573 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/0q2z1armzj8hpks"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.19 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scarygirl%20(2023)/Scarygirl%20(2023)%20English%20Web.DL%201080p.mp4"},
    "HD720p":{ "size" : "1.09 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scarygirl%20(2023)/Scarygirl%20(2023)%20English%20Web.DL%20720p.mp4"},
    "SD480p":{ "size" : "573 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scarygirl%20(2023)/Scarygirl%20(2023)%20English%20Web.DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.19 GB", "Link": "https://ddl.sinhalasub.net/AgADDx967"},
    "HD720p":{ "size" : "1.09 GB", "Link": "https://ddl.sinhalasub.net/AgADNB965"},
    "SD480p":{ "size" : "573 MB", "Link": "https://ddl.sinhalasub.net/AgADMx963"}
   }
},

{
  id : 42349006,
    "Title" : "OMG 2", 
  "IMDbRating" : "7.7", 
  "RunningTime" : "156 minutes", 
  "Genres" : "Comedy, Drama", 
  "Introduction" : "An unhappy civilian asks the court to mandate comprehensive education in schools in a dramatic yet amusing courtroom play.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYmI0NmI1NzgtODZiNy00OTVmLWJjNDctYzdjMzUyNmQ4ZjJlXkEyXkFqcGdeQXVyMTA1NTY0NzQ0._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.68 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/OMG%202%20(2023)/OMG%202%20(2023)%20Hindi%20HDRip%201080p.mp4"},
    "HD720p":{ "size" : "1.91 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/OMG%202%20(2023)/OMG%202%20(2023)%20Hindi%20HDRip%20720p.mp4"},
    "SD480p":{ "size" : "988 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/OMG%202%20(2023)/OMG%202%20(2023)%20Hindi%20HDRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.68 GB", "Link": "https://ddl.sinhalasub.net/AgAD9x943"},
    "HD720p":{ "size" : "1.91 GB", "Link": "https://ddl.sinhalasub.net/AgADHB941"},
    "SD480p":{ "size" : "988 MB", "Link": "https://ddl.sinhalasub.net/AgADGx939"}
   }
},

{
  id : 21287354,
    "Title" : "Saw X", 
  "IMDbRating" : "6.7", 
  "RunningTime" : "118 minutes", 
  "Genres" : "Horror, Mystery, Thriller", 
  "Introduction" : "A sick and desperate John travels to Mexico for a risky and experimental medical procedure in hopes of a miracle cure for his cancer only to discover the entire operation is a scam to defraud the most vulnerable.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BODVhNDMyNDctNjg0Yi00ZDFlLThjMzctNzZmMTE2ZmE2YzBlXkEyXkFqcGdeQXVyMTY3ODkyNDkz._V1_.jpg",
"severid2":{
    "FHD1080p":{ "size" : "2.94 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/yobui24iii4oitc"},
    "HD720p":{ "size" : "1.46 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/kva0dlyfci3y5of"},
    "SD480p":{ "size" : "755 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/0fhx75uv3ch1pe0"}
  },
  "severid3":{
    "FHD1080p":{ "size" : "2.94 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20X%20(2023)/Saw%20X%20(2023)%20English%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "1.46 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20X%20(2023)/Saw%20X%20(2023)%20English%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "755 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Saw%20X%20(2023)/Saw%20X%20(2023)%20English%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "2.94 GB", "Link": "https://ddl.sinhalasub.net/AgADlw862"},
    "HD720p":{ "size" : "1.46 GB", "Link": "https://ddl.sinhalasub.net/AgAD9Q860"},
    "SD480p":{ "size" : "755 MB", "Link": "https://ddl.sinhalasub.net/AgAD6w858"}
   }
},

{
  id : 56553335,
    "Title" : "Romeo Akbar Walter", 
  "IMDbRating" : "6.6", 
  "RunningTime" : "141 minutes", 
  "Genres" : "Action, Drama, Thriller", 
  "Introduction" : "Prior to the India-Pakistan war of 1971, an Indian banker is recruited by the Research and Analysis Wing for a covert operation deep inside Pakistan.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BMGFkYTlmNzYtN2M4YS00MDUyLWI4MWItMjA2MjI1MGM0ZmU4XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.70 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Romeo%20Akbar%20Walter%20(2019)/Romeo%20Akbar%20Walter%20(2019)%20Censor%20Board%20Copy%201080p.mp4"},
    "HD720p":{ "size" : "1.67 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Romeo%20Akbar%20Walter%20(2019)/Romeo%20Akbar%20Walter%20(2019)%20Censor%20Board%20Copy%20720p.mp4"},
    "SD480p":{ "size" : "876 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Romeo%20Akbar%20Walter%20(2019)/Romeo%20Akbar%20Walter%20(2019)%20Censor%20Board%20Copy%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.70 GB", "Link": "https://ddl.sinhalasub.net/AgAD1Q847"},
    "HD720p":{ "size" : "1.67 GB", "Link": "https://ddl.sinhalasub.net/AgADKQ845"},
    "SD480p":{ "size" : "876 MB", "Link": "https://ddl.sinhalasub.net/AgADKA843"}
   }
},

{
  id : 31386914,
    "Title" : "Jo Pil-ho: The Dawning Rage", 
  "IMDbRating" : "6.4", 
  "RunningTime" : "127 minutes", 
  "Genres" : "Action, Crime, Drama, Thriller", 
  "Introduction": "A corrupt cop enlists a teenage girl's help to expose a corporate scandal and eventually becomes her unlikely protector.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTIxOTYyY2MtMDI0Yy00MDllLWE2ZTEtZjNkZTczOTAyOGQ3XkEyXkFqcGdeQXVyNjc3MjQzNTI@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.40 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)%20Korean%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "1.54 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)%20Korean%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "810 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)/Jo%20Pil-ho.The%20Dawning%20Rage%20(2019)%20Korean%20WEBRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.40 GB", "Link": "https://ddl.sinhalasub.net/AgAD0g841"},
    "HD720p":{ "size" : "1.54 GB", "Link": "https://ddl.sinhalasub.net/AgADJw839"},
    "SD480p":{ "size" : "810 MB", "Link": "https://ddl.sinhalasub.net/AgADJg837"}
   },
  "severid1":{
    "FHD1080p":{ "size" : "3.40 GB", "Link": "https://ddl.sinhalasub.net/AgAD0g841"},
    "HD720p":{ "size" : "1.54 GB", "Link": "https://ddl.sinhalasub.net/AgADJw839"},
    "SD480p":{ "size" : "810 MB", "Link": "https://ddl.sinhalasub.net/AgADJg837"}
   }
},

{
  id : 34242090,
    "Title" : "Jersey", 
  "IMDbRating" : "8.5", 
  "RunningTime" : "157 minutes", 
  "Genres" : "Drama, Sport", 
  "Introduction": "A failed cricketer decides to revive his cricketing career in his late 30's despite everyone being skeptical of his ability to do so.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BM2U2ZDcwMWYtNGM4My00Njk3LWEwYTYtNjI3MDc2YmUxNGY3XkEyXkFqcGdeQXVyMTM1MTE1NDMx._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.65 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jersey%20(2019)/Jersey%20(2019)%20Telugu%20HDRIP%201080p.mp4"},
    "HD720p":{ "size" : "1.82 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jersey%20(2019)/Jersey%20(2019)%20Telugu%20HDRIP%20720p.mp4"},
    "SD480p":{ "size" : "957 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Jersey%20(2019)/Jersey%20(2019)%20Telugu%20HDRIP%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.65 GB", "Link": "https://ddl.sinhalasub.net/AgAD0A835"},
    "HD720p":{ "size" : "1.82 GB", "Link": "https://ddl.sinhalasub.net/AgADJA833"},
    "SD480p":{ "size" : "957 MB", "Link": "https://ddl.sinhalasub.net/AgADIw831"}
   },
  "severid1":{
    "FHD1080p":{ "size" : "3.65 GB", "Link": "https://ddl.sinhalasub.net/AgAD0A835"},
    "HD720p":{ "size" : "1.82 GB", "Link": "https://ddl.sinhalasub.net/AgADJA833"},
    "SD480p":{ "size" : "957 MB", "Link": "https://ddl.sinhalasub.net/AgADIw831"}
   }
},



  
  
   

   
{
  id : 18890558,
    "Title" : "Totally Killer", 
  "IMDbRating" : "6.6", 
  "RunningTime" : "106 minutes", 
  "Genres" : "Comedy, Horror", 
  "Introduction" : "When the infamous Sweet Sixteen Killer returns 35 years after his first murder spree to claim another victim, 17-year-old Jamie accidentally travels back in time to 1987, determined to stop the killer before he can start.", 
   "MovieImage" : "https://m.media-amazon.com/images/S/pv-target-images/bb1058aabe33927d5e5952d18a17aad66dd94e109b113bdafadff91bfa78d526.png",
"severid3":{
    "FHD1080p":{ "size" : "2.59 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Totally%20Killer%20(2023)/Totally%20Killer%20(2023)%20English%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "1.28 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Totally%20Killer%20(2023)/Totally%20Killer%20(2023)%20English%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "662 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Totally%20Killer%20(2023)/Totally%20Killer%20(2023)%20English%20WEBRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.59 GB", "Link": "https://ddl.sinhalasub.net/AgADzQ829"},
    "HD720p":{ "size" : "1.28 GB", "Link": "https://ddl.sinhalasub.net/AgADIQ827"},
    "SD480p":{ "size" : "662 MB", "Link": "https://ddl.sinhalasub.net/AgADIA825"}
   }
},

{
  id : 31084686,
    "Title" : "Scooby-Doo! And Krypto, Too!", 
  "IMDbRating" : "6.0", 
  "RunningTime" : "76 minutes", 
  "Genres" : "Animation, Action, Adventure, Comedy, Crime, Family, Fantasy, Mystery, Sci-Fi", 
  "Introduction" : "Follows Scooby-Doo and Mystery Inc. as they are looking into the disappearance of the Justice League.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYWM0MjYxMzgtNGZmYi00NDE2LTkxYTktY2QzMmRlZDI5NzhjXkEyXkFqcGdeQXVyMTk2OTAzNTI@._V1_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "1.96 GB", "Link": "https://buddylinker.onrender.com/r/mdf/d/9unlgt9bw4fgwxr"},
    "HD720p":{ "size" : "985 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/0wyau4e9sek6cak"},
    "SD480p":{ "size" : "498 MB", "Link": "https://buddylinker.onrender.com/r/mdf/d/mx02hhwww2stcbw"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "1.96 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scooby-Doo%20and%20Krypto%20Too%20(2023)/Scooby-Doo%20and%20Krypto%20Too%20(2023)%20English%20WEB-DL%201080p.mp4"},
    "HD720p":{ "size" : "985 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scooby-Doo%20and%20Krypto%20Too%20(2023)/Scooby-Doo%20and%20Krypto%20Too%20(2023)%20English%20WEB-DL%20720p.mp4"},
    "SD480p":{ "size" : "498 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Scooby-Doo%20and%20Krypto%20Too%20(2023)/Scooby-Doo%20and%20Krypto%20Too%20(2023)%20English%20WEB-DL%20480p.mp4"}
  },
  "severid1":{
    "FHD1080p":{ "size" : "1.96 GB", "Link": "https://ddl.sinhalasub.net/AgAD0h819"},
    "HD720p":{ "size" : "985 MB", "Link": "https://ddl.sinhalasub.net/AgAD9A817"},
    "SD480p":{ "size" : "498 MB", "Link": "https://ddl.sinhalasub.net/AgAD7g815"}
   }
},

{
  id : 43325744,
    "Title" : "Winnie the Pooh: Blood and Honey", 
  "IMDbRating" : "2.9", 
  "RunningTime" : "84 minutes", 
  "Genres" : "Horror", 
  "Introduction" : "After Christopher Robin abandons them for college, Pooh and Piglet embark on a bloody rampage as they search for a new source of food.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BNGMwNjBlM2MtYzFlNi00ZDgwLTkwN2QtMjgzOWVjMWQ4NzAzXkEyXkFqcGdeQXVyNDA1NDA2NTk@._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "2.71 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Blood%20(2022)/Blood%20(2022)%20English%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "1.34 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Blood%20(2022)/Blood%20(2022)%20English%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "692 MB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Blood%20(2022)/Blood%20(2022)%20English%20WEBRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "2.71 GB", "Link": "https://ddl.sinhalasub.net/AgADqB813"},
    "HD720p":{ "size" : "1.34 GB", "Link": "https://ddl.sinhalasub.net/AgADuQ811"},
    "SD480p":{ "size" : "692 MB", "Link": "https://ddl.sinhalasub.net/AgADuA809"}
   }
},

{
  id : 40358183,
    "Title" : "Gadar: Ek Prem Katha", 
  "IMDbRating" : "7.3", 
  "RunningTime" : "170 minutes", 
  "Genres" : "Action, Drama, Romance", 
  "Introduction" : "During the Partition of India in 1947, a Sikh truck driver falls in love with a Muslim girl belonging to an aristocratic family.", 
   "MovieImage" : "https://m.media-amazon.com/images/M/MV5BYTgxMzAwZTMtMTY5Yi00NWVmLTk1OGItNjAwYTAzOGU2OWNiXkEyXkFqcGdeQXVyMTYzMTU3Njgx._V1_FMjpg_UX1000_.jpg",
"severid3":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Gadar%20Ek%20Prem%20Katha%20(2001)/Gadar%20Ek%20Prem%20Katha%20(2001)%20Hindi%20WEBRip%201080p.mp4"},
    "HD720p":{ "size" : "2.22 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Gadar%20Ek%20Prem%20Katha%20(2001)/Gadar%20Ek%20Prem%20Katha%20(2001)%20Hindi%20WEBRip%20720p.mp4"},
    "SD480p":{ "size" : "1.12 GB", "Link": "https://ssl.gihancloudflare.workers.dev/0:/Movies/Gadar%20Ek%20Prem%20Katha%20(2001)/Gadar%20Ek%20Prem%20Katha%20(2001)%20Hindi%20WEBRip%20480p.mp4"}
  },
  "severid2":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ddl.sinhalasub.net/AgADMh805"},
    "HD720p":{ "size" : "2.22 GB", "Link": "https://ddl.sinhalasub.net/AgADMB803"},
    "SD480p":{ "size" : "1.12 GB", "Link": "https://ddl.sinhalasub.net/AgADWR801"}
   },
  "severid1":{
    "FHD1080p":{ "size" : "3.78 GB", "Link": "https://ddl.sinhalasub.net/AgADMh805"},
    "HD720p":{ "size" : "2.22 GB", "Link": "https://ddl.sinhalasub.net/AgADMB803"},
    "SD480p":{ "size" : "1.12 GB", "Link": "https://ddl.sinhalasub.net/AgADWR801"}
   }
},
   // Add more movie data as needed
 ];
 
 export default data;
 