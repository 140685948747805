import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styletv.css';

const data =  [
  {id:2318458994,name:'English Club TV',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/pgldcz.png'},
  {id:1615086710,name:'Event TV',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/event-tv.png'},
  {id:7678646335,name:'Times Now',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/qigtck.png'},
  {id:10286132299,name:'Al-Jazeera',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/aljazeera-aljazeera.png'},
  {id:1503124820,name:'FRANCE 24',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/france-france-24.png'},
  {id:10835612687,name:'Nflix',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/nflix-nflix.png'},
  {id:6755218736,name:'SONY SPORTS TEN 5',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sony-sports-ten-5.png'},
  {id:5341487014,name:'SONY SPORTS TEN 1',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sony-sports-ten-1.png'},
  {id:4360034788,name:'SONY SPORTS TEN 2',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sudpjb.png'},
  {id:3158049974,name:'Planet FUN',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/planet-fun.png'},


  
];

const itemsPerPage = 12;

const SearchAndPaginate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
   <div class="section">
   <div class="container">
    <div class="section-header">
              Peotv Go
              
        
        <div class="searchbox">


        <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
        </div>
      </div>
    
     

     <div class="carousel-nav-center">
       <ul>
       {paginatedData.map(item => (
            <div class="tvcontent">
            <li class="tvcontent"> <Link to={'/TVVideoPlayer-/'+ item.id}>
            <a href='./play/?playid={$vid}'  class='movie-item'>
             <img src={item.bg} alt=''/>
             <div class='movie-item-content'>
                 <div class='movie-item-title'>
                 {item.name}
                 </div>
                 <div class='movie-infos'>
                    Peotv go Live
                    
                 </div>
             </div>
         </a>
   
   
   
   
       </Link>
      </li></div>
          ))}
        </ul></div>

      <div class="pagebuttonrow">
        <button class="pagebutton"
          onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span  class="pagebuttontext">{`Page ${currentPage} of ${totalPages}`}</span>
        <button class="pagebutton"
          onClick={() =>
            setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div></div>
    </ div>
  );
};

export default SearchAndPaginate;
