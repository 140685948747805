import Header from '../component/header';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';

const Homepage = () => {
  const location = useLocation();
  const a = location.state && location.state.id ? location.state.id : 'User';

  return (
    <div>
      <Header/>
      {a}
      <div class="page">
        <div class="fistrow">

       
          <Link to="/TV/#dlg">
            <div class="containerh">
            
              <p><img src="https://d216901qxcl0k5.cloudfront.net/s3fs-public/2021-04/experience.jpg" alt="Pineapple"/>
              <div class="condata"><h1>Dialog TV</h1>  <br/><br/>
                Dialog TV is a direct broadcast satellite pay TV service provider based in Sri Lanka. A fully owned subsidiary of Dialog Axiata PLC, Dialog TV was launched in July 2005 under the name "CBNsat". It was…

              </div></p>
            </div>
          </Link>
          <Link to="/TV/#peotvgo">
            <div class="containerh">
            
              <p><img src="https://selfcare.peotv.com/static/media/PeoTVGOLogo.00548174.PNG" alt="Pineapple"/>
              <div class="condata"> <h1>PEOTVGO</h1><br/><br/>
                PEOTVGO is a platform that lets you watch TV on multiple screens with local and international channels, movies, education, music and more. You can enjoy PEO TV, …
            </div></p>
            </div>
          </Link>
          <Link to="/TV/#peotv">
            <div class="containerh">
            
              <p><img id="peotvgo"src="https://tns.lk/wp-content/uploads/2021/04/new-peo-tv-connection.jpg" alt="Pineapple"/>
              <div class="condata"> <h1>PEO TV</h1><br/><br/>
                SLT PEO TV gives the platform to enjoy the best of local and international news and entertainment from around the world at a click of a button ...
              </div></p>
            </div>
          </Link>
         
        </div>

      </div>
      
      
      
    </div>
  );
}


export default Homepage;
