import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styletv.css';

const data =  [
  {id:523447252,name:'Sanda TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/qtdibj.png'},
  {id:370012224,name:'Videsa DP Grade 6 - 8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/mtsiod.png'},
  {id:9233795705,name:'Videsa DP Grade 9 - OL', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/olxxcx.png'},
  {id:1344084174,name:'Event TV Xtra', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/tszdbs.png'},
  {id:8777431996,name:'SLTMobitel Group Watch', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twkwpt.png'},
  {id:8387097379,name:'Rupavahini', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/rupavahini.png'},
  {id:5762112710,name:'Channel Eye', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/fhuhab.png'},
  {id:6216137779,name:'ITN', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ducchq.png'},
  {id:3654184649,name:'Derana', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/derana.png'},
  {id:6230831064,name:'CHARANA TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/djwqoh.png'},
  {id:5967957404,name:'Swarnawahini', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ddcjju.png'},
  {id:8589163623,name:'Vasantham TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ngfsik.png'},
  {id:10093748842,name:'Siyatha TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twchnq.png'},
  {id:9972904076,name:'Sirasa TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ucihva.png'},
  {id:9581021513,name:'Shakthi TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/dmikwb.png'},
  {id:2143852054,name:'TV One', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/vjtiwc.png'},
  {id:1130175004,name:'TNL', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/tnl-tnl.png'},
  {id:9497358112,name:'ADA DERANA 24', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ada-derana-24.png'},
  {id:4974481385,name:'Music Plus Television', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/music-television.png'},
  {id:3439869828,name:'Nethra TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/udbacm.png'},
  {id:5681585309,name:'Three Vision', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/dwuwdr.png'},
  {id:7940320287,name:'Hiru TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/hiru-tv.png'},
  {id:9213982144,name:'Star Tamil', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/vztqoe.png'},
  {id:5039980164,name:'Eurosport', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ygkwap.png'},
  {id:2285793745,name:'Ten Cricket', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/jyiuvy.png'},
  {id:8371737279,name:'Parliament Channel', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/parliament-channel.png'},
  {id:9479140528,name:'Verbum TV',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/verbum-tv.png'},
  {id:4068272072,name:'Jaya TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ppkeyw.png'},
  {id:9087076841,name:'The Buddhist', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/the-buddhist.png'},
  {id:871471008,name:'Shraddha TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/mvfdxj.png'},
  {id:632093687,name:'PRAGNA TV', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/pragna-tv.png'},
  


  
];

const itemsPerPage = 8;

const SearchAndPaginate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
   <div class="section" id="peotvgo">
   <div class="container">
    <div class="section-header">
              Peotv Go
              
        
        <div class="searchbox">


        <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
        </div>
      </div>
    
     

     <div class="carousel-nav-center">
       <ul>
       {paginatedData.map(item => (
            <div class="tvcontent" >
            <li class="tvcontent"> <Link to={'/TVVideoPlay/'+ item.id}>
            <a href='./play/?playid={$vid}'  class='movie-item'>
             <img src={item.bg} alt=''/>
             <div class='movie-item-content'>
                 <div class='movie-item-title'>
                 {item.name}
                 </div>
                 <div class='movie-infos'>
                    Peotv Go Live
                    
                 </div>
             </div>
         </a>
   
   
   
   
       </Link>
      </li></div>
          ))}
        </ul></div>

      <div class="pagebuttonrow">
        <button class="pagebutton"
          onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span  class="pagebuttontext">{`Page ${currentPage} of ${totalPages}`}</span>
        <button class="pagebutton"
          onClick={() =>
            setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div></div>
    </ div>
  );
};

export default SearchAndPaginate;
