import Header from './component/header';
import React from 'react';
// import {  useLocation } from 'react-router-dom';

const Homepage = () => {
  // const location = useLocation();

  return (
    <div>
      <Header/>
      <h1 className='name'>hi</h1>
      <h1>comeing soon</h1>
   
    </div>
  );
}


export default Homepage;
