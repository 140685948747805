

// export default VideoPlayer;
import React,  { useEffect }from 'react';
import { useParams} from 'react-router-dom';
import './css/player.css'
import './css/skin.css'
// Make sure your component name starts with an uppercase letter
const VideoPlayer = () => {
  //select play id
  const { id } = useParams();
  const data = [
  //peotv go vid
  {id:523447252,name:'Sanda TV',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:sandatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/qtdibj.png'},
{id:370012224,name:'Videsa DP Grade 6 - 8',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:videsa10-11.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/mtsiod.png'},
{id:9233795705,name:'Videsa DP Grade 9 - OL',link:'https://edge3-moblive.yuppcdn.net/trans1sd/smil:videsadpeducation.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/olxxcx.png'},
{id:1344084174,name:'Event TV Xtra',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:eventtvextra.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/tszdbs.png'},
{id:8777431996,name:'SLTMobitel Group Watch',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:sltgroupwatchaes.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twkwpt.png'},
{id:8387097379,name:'Rupavahini',link:'https://edge3-moblive.yuppcdn.net/transsd/smil:rupavahini.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/rupavahini.png'},
{id:5762112710,name:'Channel Eye',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:channeleye.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/fhuhab.png'},
{id:6216137779,name:'ITN',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:itn.smil/playlist.', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ducchq.png'},
{id:3654184649,name:'Derana',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:deranatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/derana.png'},
{id:6230831064,name:'CHARANA TV',link:'https://edge4-moblive.yuppcdn.net/transhd/smil:charana.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/djwqoh.png'},
{id:5967957404,name:'Swarnawahini',link:'https://edge4-moblive.yuppcdn.net/transd/smil:swarnawahini.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ddcjju.png'},
{id:8589163623,name:'Vasantham TV',link:'https://edge3-moblive.yuppcdn.net/trans1sd/smil:vasanthamtv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ngfsik.png'},
{id:10093748842,name:'Siyatha TV',link:'https://edge4-moblive.yuppcdn.net/transhd/smil:siyathatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twchnq.png'},
{id:9972904076,name:'Sirasa TV',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:sirasatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ucihva.png'},
{id:9581021513,name:'Shakthi TV',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:shakthitv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/dmikwb.png'},
{id:2143852054,name:'TV One',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:tv1.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/vjtiwc.png'},
{id:1130175004,name:'TNL',link:'https://edge3-moblive.yuppcdn.net/transsd/smil:tnl.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/tnl-tnl.png'},
{id:9497358112,name:'ADA DERANA 24',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:adaderana24.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ada-derana-24.png'},
{id:4974481385,name:'Music Plus Television',link:'https://edge3-moblive.yuppcdn.net/trans1sd/smil:musicplustelevision.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/music-television.png'},
{id:3439869828,name:'Nethra TV',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:nethra.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/udbacm.png'},
{id:5681585309,name:'Three Vision',link:'https://moblive.yuppcdn.net/transsd/smil:knowledgetv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/dwuwdr.png'},
{id:7940320287,name:'Hiru TV',link:'https://edge4-moblive.yuppcdn.net/transhd/smil:hirutv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/hiru-tv.png'},
{id:9213982144,name:'Star Tamil',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:startamil.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/vztqoe.png'},
{id:5039980164,name:'Eurosport',link:'https://moblive.yuppcdn.net/transhd/smil:eurosport.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ygkwap.png'},
{id:2285793745,name:'Ten Cricket',link:'https://edge4-moblive.yuppcdn.net/transhd/smil:tencricket.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/jyiuvy.png'},
{id:8371737279,name:'Parliament Channel',link:'https://edge2-moblive.yuppcdn.net/transsd/smil:parliamentchannel.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/parliament-channel.png'},
{id:9479140528,name:'Verbum TV',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:verbumtv.smil/playlist.m3u8',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/verbum-tv.png'},
{id:4068272072,name:'Jaya TV',link:'https://edge4-moblive.yuppcdn.net/transsd/smil:jayamagatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/ppkeyw.png'},
{id:9087076841,name:'The Buddhist',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:thebuddhist.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/the-buddhist.png'},
{id:871471008,name:'Shraddha TV',link:'https://edge4-moblive.yuppcdn.net/trans1sd/smil:shraddhatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/mvfdxj.png'},
{id:632093687,name:'PRAGNA TV',link:'https://edge3-moblive.yuppcdn.net/trans1sd/smil:pragnatv.smil/playlist.m3u8', bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/pragna-tv.png'},



//peotv


{ id:734754, name: 'Colors',link: 'https://live.peotv.com/bpk-tv/101/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/101-Colors_0.jpg' },
{ id:734754,name: 'Kalaignar TV',link: 'https://live.peotv.com/bpk-tv/106/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/106-KalaignarTV_0%20%281%29.jpg' },
{ id:116894,name: 'Isaiaruvi TV',link: 'https://live.peotv.com/bpk-tv/107/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/107-IsaiaruviTV_0%20%281%29.jpg' },
{ id:77818,name: 'Sirippoli TV',link: 'https://live.peotv.com/bpk-tv/108/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/108-SirippoliTV%20%281%29.jpg' },
{ id:659942,name: 'Sangamam TV',link: 'https://live.peotv.com/bpk-tv/110/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/110-SangamamTV_0.jpg' },
{ id:565189,name: 'Meth TV',link: 'https://live.peotv.com/bpk-tv/111/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/111-MethTV.jpg' },
{ id:418200,name: 'Pragna TV',link: 'https://live.peotv.com/bpk-tv/112/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/112-PragnaTV_0.png' },
{ id:313401,name: 'Karma',link: 'https://live.peotv.com/bpk-tv/113/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/113-Karma.jpg' },
{ id:786328,name: 'Sathi TV',link: 'https://live.peotv.com/bpk-tv/115/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/115-Sathi%20TV.jpg' },
{ id:616112,name: 'TV Didula',link: 'https://live.peotv.com/bpk-tv/117/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/117-TVDidula_0.jpg' },
{ id:198393,name: 'ThinethaTV',link: 'https://live.peotv.com/bpk-tv/118/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/118-ThinethaTV.png' },
{ id:287133,name: 'Event TV Plus',link: 'https://live.peotv.com/bpk-tv/120/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/120-EventTVPlus_0.jpg' },
{ id:565151,name: 'SANDA TV',link: 'https://live.peotv.com/bpk-tv/121/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/121-SANDATV.jpg' },
{ id:58179,name: 'Damsathara TV',link: 'https://live.peotv.com/bpk-tv/126/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/126-DamsatharaTV%20%281%29.jpg' },
{ id:425092,name: 'Supreme TV',link: 'https://live.peotv.com/bpk-tv/140/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/140-SupremeTV%20%281%29.jpg' },
{ id:356640,name: 'Bloomberg',link: 'https://live.peotv.com/bpk-tv/160/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/160-Bloomberg_0.jpg' },
{ id:273188,name: 'NHK WORLD-JAPAN',link: 'https://live.peotv.com/bpk-tv/161/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_0_0.jpg' },
{ id:745338,name: 'CCTV 4',link: 'https://live.peotv.com/bpk-tv/180/output/index.m3u8', bg: 'https://mobitel.peotv.com/images/channel/180-CCTV4.jpg' },
{ id:458256,name: 'CGTN',link: 'https://live.peotv.com/bpk-tv/181/output/index.m3u8', bg: 'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/035.png' },
{ id:310585,name: 'English Club TV',link: 'https://live.peotv.com/bpk-tv/190/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/190-EnglishClubTV%20%281%29.jpg' },
{ id:772456,name: 'Videsa 3-5',link: 'https://live.peotv.com/bpk-tv/205/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/205-Videsa3-5.jpg' },
{ id:187211,name: 'Videsa DP Grade 6 - 8',link: 'https://live.peotv.com/bpk-tv/208/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/208-Videsa%20DP%20Grade%206-8.jpg' },
{ id:34426,name: 'Videsa DP Grade 9 - OL',link: 'https://live.peotv.com/bpk-tv/209/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/209-Videsa%20DP%209-OL.jpg' },
{ id:620886,name: 'AL Kuppiya Television',link: 'https://live.peotv.com/bpk-tv/210/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelLogo_3.jpg' },
{ id:552435,name: 'Event TV Xtra',link: 'https://live.peotv.com/bpk-tv/220/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/220-EventTVXtra.jpg' },
{ id:694113,name: 'Tata Play IPL',link: 'https://live.peotv.com/bpk-tv/241/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/TATAIPL.png' },
{ id:617591,name: 'SLTMobitel Group Watch',link: 'https://live.peotv.com/bpk-tv/250/output/index.m3u8', bg: 'https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twkwpt.png' },
{ id:603597,name: 'Hiru TV HD',link: 'https://live.peotv.com/bpk-tv/207/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/307-HiruTVHD.jpg' },
{ id:671970,name: 'Rupavahini',link: 'https://live.peotv.com/bpk-tv/001/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/Rupawahini_0_0.jpg' },
{ id:593510,name: 'Channel Eye',link: 'https://live.peotv.com/bpk-tv/002/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelEye_0_0.jpg' },
{ id:315997,name: 'ITN',link: 'https://live.peotv.com/bpk-tv/003/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/003-ITN_0.jpg' },
{ id:152114,name: 'Derana',link: 'https://live.peotv.com/bpk-tv/004/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/004-Derana_0.jpg' },
{ id:396426,name: 'CHARANA TV',link: 'https://live.peotv.com/bpk-tv/005/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/005-CHARANATV_0.jpg' },
{ id:689906,name: 'Swarnawahini',link: 'https://live.peotv.com/bpk-tv/006/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/006-Swarnawahini_0.jpg' },
{ id:454684,name: 'Vasantham',link: 'https://live.peotv.com/bpk-tv/007/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/vasanthan_0.png' },
{ id:654909,name: 'Siyatha TV',link: 'https://live.peotv.com/bpk-tv/008/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/021-Siyatha%20TV_0.jpg' },
{ id:248547,name: 'Haritha TV',link: 'https://live.peotv.com/bpk-tv/009/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/HarithaTV_0.jpg' },
{ id:540769,name: 'Sirasa TV',link: 'https://live.peotv.com/bpk-tv/010/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/sirasaTv_0.jpg' },
{ id:455464,name: 'Shakthi TV',link: 'https://live.peotv.com/bpk-tv/011/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/011_Shakthi%20TV_0.jpg' },
{ id:647824,name: 'TV One',link: 'https://live.peotv.com/bpk-tv/012/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/12-TV1.jpg' },
{ id:20844,name: 'TNL',link: 'https://live.peotv.com/bpk-tv/013/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/TNL_0.jpg' },
{ id:145187,name: 'ADA DERANA 24',link: 'https://live.peotv.com/bpk-tv/014/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/014-AdaDerana24x7_0_0.jpg' },
{ id:636543,name: 'Music Plus Television',link: 'https://live.peotv.com/bpk-tv/015/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/015_Music%20Plus%20Television_0.jpg' },
{ id:83264,name: 'Nethra TV',link: 'https://live.peotv.com/bpk-tv/016/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/016-NethraTV_0.jpg' },
{ id:383700,name: 'Art TV',link: 'https://live.peotv.com/bpk-tv/017/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/017-Art-TV_0.jpg' },
{ id:575621,name: 'Three Vision',link: 'https://live.peotv.com/bpk-tv/018/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/018-Three%20Vision.jpg' },
{ id:578779,name: 'Hiru TV',link: 'https://live.peotv.com/bpk-tv/019/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/Hiru%20TV_0.png' },
{ id:452514,name: 'Event TV',link: 'https://live.peotv.com/bpk-tv/020/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/020-EventTV_0.jpg' },
{ id:520346,name: 'Star Tamil',link: 'https://live.peotv.com/bpk-tv/021/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/008-Star%20Tamil_0.jpg' },
{ id:771884,name: 'Rangiri TV',link: 'https://live.peotv.com/bpk-tv/022/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_2.jpg' },
{ id:722716,name: 'CNN',link: 'https://live.peotv.com/bpk-tv/023/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/023-cnn_0.jpg' },
{ id:722176,name: 'Times Now',link: 'https://live.peotv.com/bpk-tv/162/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/162-TIMESNOW_0.jpg' },
{ id:422384,name: 'Al-Jazeera',link: 'https://live.peotv.com/bpk-tv/026/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/alJazeera.jpg' },
{ id:719979,name: 'France 24',link: 'https://live.peotv.com/bpk-tv/027/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/27-France24_0.jpg' },
{ id:89599,name: 'CinemaWorld',link: 'https://live.peotv.com/bpk-tv/033/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/33-CinemaWorld.jpg' },
{ id:726573,name: 'Nflix',link: 'https://live.peotv.com/bpk-tv/034/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/034-NFlix_0.jpg' },
{ id:403936,name: 'SONY SPORTS TEN 1',link: 'https://live.peotv.com/bpk-tv/038/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/038-SONYSportsTen1.jpg' },
{ id:364864,name: 'Star Sports 1',link: 'https://live.peotv.com/bpk-tv/041/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/041-StarSports1%20%281%29.jpg' },
{ id:20647,name: 'Eurosport',link: 'https://live.peotv.com/bpk-tv/042/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/042-Eurosport_0.jpg' },
{ id:368458,name: 'Ten Cricket',link: 'https://live.peotv.com/bpk-tv/044/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/tenCricket_0.png' },
{ id:472737,name: 'SONY SPORTS TEN 2',link: 'https://live.peotv.com/bpk-tv/045/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/045-SONY%20Sports%20Ten%202_6.jpg' },
{ id:490522,name: 'Premier Sports',link: 'https://live.peotv.com/bpk-tv/046/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelLogo_0_0.jpg' },
{ id:162212,name: 'FOX Life',link: 'https://live.peotv.com/bpk-tv/052/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/052-FOXlife.jpg' },
{ id:179279,name: 'TravelChannel',link: 'https://live.peotv.com/bpk-tv/053/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/Travel%20Channel_0.jpg' },
{ id:195208,name: 'Discovery Tamil',link: 'https://live.peotv.com/bpk-tv/056/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/056-DiscoveryTamil_0.jpg' },
{ id:427254,name: 'NatGeo Wild',link: 'https://live.peotv.com/bpk-tv/057/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/NatGeoWild_0.png' },
{ id:765559,name: 'Da Vinci',link: 'https://live.peotv.com/bpk-tv/058/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/058-DaVinci_0.jpg' },
{ id:532378,name: 'Nickelodeon',link: 'https://live.peotv.com/bpk-tv/060/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/nick_0.png' },
{ id:100621,name: 'Planet FUN',link: 'https://live.peotv.com/bpk-tv/061/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/061-PlanetFUN_0.jpg' },
{ id:162761,name: 'Cbeebies',link: 'https://live.peotv.com/bpk-tv/062/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/062-Cbeebies_0.jpg' },
{ id:404236,name: 'BabyTV',link: 'https://live.peotv.com/bpk-tv/063/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/babyTv_0.png' },
{ id:38549,name: 'Cartoon Network',link: 'https://live.peotv.com/bpk-tv/065/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/cn_1.png' },
{ id:773081,name: 'Pogo',link: 'https://live.peotv.com/bpk-tv/066/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/pogo_0.png' },
{ id:594195,name: 'MTV',link: 'https://live.peotv.com/bpk-tv/067/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/Mtv_0.gif' },
{ id:596260,name: 'Stingray CMusic',link: 'https://live.peotv.com/bpk-tv/069/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/069-StingrayCmusic_0.jpg' },
{ id:147949,name: 'ZEE TAMIL',link: 'https://live.peotv.com/bpk-tv/072/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/072-ZEETAMIL_0.jpg' },
{ id:96557,name: 'Star Vijay',link: 'https://live.peotv.com/bpk-tv/073/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/073-StarVijay.jpg' },
{ id:613976,name: 'Colors Tamil',link: 'https://live.peotv.com/bpk-tv/074/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/074-ColorsTamil_0.jpg' },
{ id:86035,name: 'ZEE CINEMA',link: 'https://live.peotv.com/bpk-tv/076/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/076-ZEECINEMA_0.jpg' },
{ id:553947,name: 'ZEE TV',link: 'https://live.peotv.com/bpk-tv/079/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/079-ZEETV_0.jpg' },
{ id:423113,name: 'zoom',link: 'https://live.peotv.com/bpk-tv/082/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/082-Zoom_0.jpg' },
{ id:602657,name: 'ABC Australia',link: 'https://live.peotv.com/bpk-tv/083/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_1_0.jpg' },
{ id:29961,name: 'ZEE CAFE',link: 'https://live.peotv.com/bpk-tv/085/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_1_0.jpg' },
{ id:504322,name: 'Colors INFINITY',link: 'https://live.peotv.com/bpk-tv/086/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/086-Colors%20INFINITI_0.jpg' },
{ id:593060,name: 'KBS World',link: 'https://live.peotv.com/bpk-tv/087/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/kbsWorld_0.png' },
{ id:399568,name: 'Star Plus',link: 'https://live.peotv.com/bpk-tv/088/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/088_Star%20Plus_0.png' },
{ id:585503,name: 'TV5MONDE Asie',link: 'https://live.peotv.com/bpk-tv/090/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/090-TV5MONDEAsie.jpg' },
{ id:317363,name: 'Parliament',link: 'https://live.peotv.com/bpk-tv/091/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/091-Parliament-Channel1_0.jpg' },
{ id:285432,name: 'DW',link: 'https://live.peotv.com/bpk-tv/092/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/092-Deutsche-Welle_0.jpg' },
{ id:146779,name: 'Verbum TV',link: 'https://live.peotv.com/bpk-tv/093/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/093_Verbum%20TV_0.jpg' },
{ id:650107,name: 'Jaya TV',link: 'https://live.peotv.com/bpk-tv/094/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/094-JayaTV.jpg' },
{ id:30063,name: 'Siyesa TV',link: 'https://live.peotv.com/bpk-tv/095/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/095-SiyesaTV%20%281%29.png' },
{ id:284021,name: 'The Buddhist',link: 'https://live.peotv.com/bpk-tv/096/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/Buddhist_0.gif' },
{ id:785588,name: 'God',link: 'https://live.peotv.com/bpk-tv/097/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/theGODChannel_0.jpg' },
{ id:253551,name: 'Sharddha TV',link: 'https://live.peotv.com/bpk-tv/099/output/index.m3u8', bg: 'https://www.slt.lk/sites/default/files/channel_images/shardhaTv_0.png'},




  ];
  const aaa= parseInt(id);
  const specificId = aaa;
  const playdata = data.find(array => array.id === specificId);
  const linkx = (playdata.link);
  const backg = (playdata.bg);

  
  // finding the object whose id is '3'
  useEffect(() => {

    const script = document.createElement('script');
    script.src = 'https://ssl.p.jwpcdn.com/player/v/8.8.6/jwplayer.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      const player = window.jwplayer('jw_player').setup({
        file: linkx,
        image: backg,
       
    });
      const bumpIt = () => {
        const vol = player.getVolume();
        player.setVolume(vol + 10);
      }
      bumpIt();
    };
    return () => {
      document.head.removeChild(script);
    };
  },[ linkx, backg]);

  // printing object on the console
  return (
    <div>
            <div id="jw_player">Loading the player...</div>

    </div>
  );
};

export default VideoPlayer;
