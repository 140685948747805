import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


import About from './pages/about'
import Home from './pages/homepage';
import Contact from './pages/contact';
import TV from './TV/Main';
import TVVideoPlayer from './TV/Play/VideoPlayer';
import TVVideoPlayerpeo from './TV/Playm3u8/VideoPlayer';
import TVVideoPlayerm from './TV/Play/VideoPlayerpeogo';
import Films from './Films/Main';
import Series from './Series/Main';
// import FilmVideoPlayer from './Films/Play/VideoPlayer';
import FilmPlayer from './Films/Play/play';

import Sample from './pages/sample';
import VideoPlayer from './pages/react/VideoPlayer';
import Login from './pages/login';
import Singup from './pages/singup';
import Test1 from './pages/test1';
import Test2 from './pages/test2';








function App() {
  return (
    <Router>
   <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/About" element={<About/>}/>
        <Route exact path="/TV" element={<TV/>}/>
        <Route exact path="/Films" element={<Films/>}/>
        <Route exact path="/Series" element={<Series/>}/>
        <Route exact path="/TVVideoPlayer/:id" element={<TVVideoPlayer/>}/>
        <Route exact path="/TVVideoPlayer-/:id" element={<TVVideoPlayerm/>}/>
        <Route exact path="/TVVideoPlay/:id" element={<TVVideoPlayerpeo/>}/>
        <Route exact path="/Films/FilmVideoPlayer/:id" element={<FilmPlayer/>}/>
        {/* <Route exact path="/Fil" element={<FilmPlayer/>}/> */}
       
        <Route exact path="/Contact" element={<Contact/>}/>
        <Route exact path="/Sample" element={<Sample/>}/>
        <Route exact path="/VideoPlayer/:id" element={<VideoPlayer/>}/>
        <Route exact path="/Login" element={<Login/>}/>
        <Route exact path="/Singup" element={<Singup/>}/>
        <Route exact path="/Test1" element={<Test1/>}/>
        <Route exact path="/Test2/:id" element={<Test2/>}/>


        
      </Routes>
  </Router>
  );
}

export default App;
