import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styletv.css';

const data =  [
  {  id: 232468,  name: 'Sirasa TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/008.png'},

  {  id: 425550,  name: 'ITN', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/004.png'},
  {  id: 248187,  name: 'Rupavahini', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/002.png'},
  {  id: 733926,  name: 'Hiru TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/011.png'},
  {  id: 292042,  name: 'Swarnawahini', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/007.png'},
  {  id: 468728,  name: 'TV Derana', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/006.png'},

  {  id: 914406,  name: 'Channel one', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/001.png'},
  {  id: 364698,  name: 'Nethra TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/003.png'},
 
  {  id: 448726,  name: 'Vasantham TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/005.png'},
  {  id: 188470,  name: 'Shakthi TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/009.png'},
  {  id: 163672,  name: 'TV1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/010.png'},
  {  id: 599372,  name: 'TNL', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/012.png'},
  {  id: 813924,  name: 'ART Television', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/013.png'},
  {  id: 219127,  name: 'AdaDerana 24x7', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/014.png'},
  {  id: 345695,  name: 'Siyatha TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/015.png'},
  {  id: 733780,  name: 'Haritha TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/016.png'},
  {  id: 97247,  name: 'TV Didula', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/017.png'},
  {  id: 153780,  name: 'Ridee TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/018.png'},
  {  id: 760869,  name: 'Citi Hitz', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/019.png'},
  {  id: 743138,  name: 'Supreme TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/118.png'},
  {  id: 842687,  name: 'Rangiri Sri Lanka', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/021.png'},
  {  id: 744223,  name: 'Hi TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/121.png'},
  {  id: 226861,  name: 'Pragna TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/029.png'},
  {  id: 686595,  name: 'Damsathara TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/028.png'},
  {  id: 765573,  name: 'Jaya TV', bg:'https://s3.ap-southeast-1.amazonaws.com/dlg.dialog.lk/s3fs-public/2022-06/jayamaga.jpg'},
  {  id: 943396,  name: 'Buddhist TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/026.png'},
  {  id: 503907,  name: 'Shraddha TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/027.png'},
  {  id: 281820,  name: 'GOD TV / Swarga TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/030.png'},
  {  id: 390092,  name: 'EWTN / Verbum', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/031.png'},
  {  id: 432761,  name: 'Al Jazeera', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/032.png'},
  {  id: 419361,  name: 'ABC Australia', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/033.png'},
  {  id: 653435,  name: 'BBC World', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/034.png'},
  {  id: 84418,  name: 'CGTN', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/035.png'},
  {  id: 667199,  name: 'CNN', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/036.png'},
  {  id: 470862,  name: 'NDTV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/037.png'},
  {  id: 974280,  name: 'France 24', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/038.png'},
  {  id: 177185,  name: 'EURO News', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/062.png'},
  {  id: 969512,  name: 'Bloomberg', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/040.png'},
  {  id: 784407,  name: 'CNBC', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/041.png'},
  {  id: 594084,  name: 'B4U Music', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/043.png'},
  {  id: 232113,  name: 'VH1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/044.png'},
  {  id: 112164,  name: 'Channel C', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/045.png'},
  {  id: 485682,  name: 'Sun Music', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/046.png'},
  {  id: 675548,  name: 'Zing TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/117.png'},
  {  id: 285310,  name: 'Da Vinci', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/048.png'},
  {  id: 411671,  name: 'Cheebies', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/049.png'},
  {  id: 681420,  name: 'Cartoon Network', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/051.png'},
  {  id: 313901,  name: 'A Plus TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/052.png'},
  {  id: 963105,  name: 'Nickelodeon', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/053.png'},
  {  id: 938966,  name: 'Baby TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/054.png'},
  {  id: 620285,  name: 'Disney Jr', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/055.png'},
  {  id: 57746,  name: 'Nick JR', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/056.png'},
  {  id: 684598,  name: 'BBC Earth', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/057.png'},
  {  id: 477686,  name: 'NGC Wild', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/058.png'},
  {  id: 467842,  name: 'Animal Planet', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/059.png'},
  {  id: 557829,  name: 'Discovery', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/060.png'},
  {  id: 696935,  name: 'Discovery Science', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/061.png'},
  {  id: 142120,  name: 'TLC', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/063.png'},
  {  id: 394601,  name: 'NAT Geo', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/064.png'},
  {  id: 97252,  name: 'History TV 18', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/121.png'},
  {  id: 924264,  name: 'Travel XP', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/066.png'},
  {  id: 975687,  name: 'HGTV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/099.png'},
  {  id: 91381,  name: 'Food Network', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/096.png'},
  {  id: 354651,  name: 'Sony Sports 1', bg:'https://s3.ap-southeast-1.amazonaws.com/dlg.dialog.lk/s3fs-public/2023-03/64-Sony-Sports-1.png'},
  {  id: 869663,  name: 'Ten Cricket', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/069.png'},
  {  id: 981559,  name: 'Sony Sports 2', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/070.png'},
  {  id: 757246,  name: 'Eurosport', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/071.png'},
  {  id: 599747,  name: 'Star Sports 2', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/074.png'},
  {  id: 372043,  name: 'HBO Signature', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/081.png'},
  {  id: 554312,  name: 'HBO Family', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/083.png'},
  {  id: 677956,  name: '&flix', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/085.png'},
  {  id: 702649,  name: 'HITS Movies', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/086.png'},
  {  id: 182561,  name: 'SONY PIX', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/088.png'},
  {  id: 781427,  name: 'Hits', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/089.png'},
  {  id: 35924,  name: 'ZEE Cafe', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/090.png'},
  {  id: 419827,  name: 'WarnerTV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/092.png'},
  {  id: 411839,  name: 'AXN', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/093.png'},
  {  id: 721605,  name: 'Colors Infinity', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/094.png'},
  {  id: 547614,  name: 'Fashion TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/ftv-logo.jpg'},
  {  id: 525536,  name: 'FOX Life', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/097.png'},
  {  id: 439799,  name: 'Comedy Central', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/098.png'},
  {  id: 886940,  name: 'Arirang TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/Arirang.png'},
  {  id: 964444,  name: 'Star Bharat', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/134.png'},
  {  id: 46534,  name: 'SONY Set', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/101.png'},
  {  id: 631077,  name: 'SET Max', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/086.png'},
  {  id: 170794,  name: 'Star Gold', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/103.png'},
  {  id: 667378,  name: 'Colors', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/104.png'},
  {  id: 649106,  name: 'Star Plus', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/105.png'},
  {  id: 794702,  name: 'B4U Movies', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/114.png'},
  {  id: 731520,  name: 'Star Gold Romance', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/113.png'},
  {  id: 231916,  name: 'ZEE Cinema', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/112.png'},
  {  id: 142092,  name: 'Colors Tamil', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/107.png'},
  {  id: 53871,  name: 'Sun TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/108.png'},
  {  id: 705785,  name: 'KTV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/109.png'},
  {  id: 527510,  name: 'Star Vijay', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/110.png'},
  {  id: 910138,  name: 'Kalaignar TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/111.png'},
  {  id: 16960,  name: 'ZEE Tamil', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/115.png'},
  {  id: 473977,  name: 'Sirippoli TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/116.png'},
  {  id: 174849,  name: 'Guru TV', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/022.png'},
  {  id: 238748,  name: 'Nenasa Sinhala Grade 1-5', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/22-Nenasa-Sinhala.png'},
  {  id: 214110,  name: 'Nenasa Tamil Grade 1-5', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/22-Nenasa-Sinhala.png'},
  {  id: 465498,  name: 'Nenasa Sinhala Grade 6-9', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/22-Nenasa-Sinhala.png'},
  {  id: 107262,  name: 'Nenasa Tamil Grade 6-9', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/23-Nenasa-Tamil-OL.png'},
  {  id: 605191,  name: 'Nenasa Sinhala Grade O/L', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/22-Nenasa-Sinhala.png'},
  {  id: 816410,  name: 'Nenasa Tamil Grade O/L', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/23-Nenasa-Tamil-OL.png'},
  {  id: 955956,  name: 'Nenasa Sinhala Grade A/L', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/NANASA10.png'},
  {  id: 114641,  name: 'Nenasa Tamil Grade A/L', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/NANASA10.png'},
  {  id: 404720,  name: 'Discovery HD World', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/125.png'},
  {  id: 205456,  name: 'Animal Planet HD', bg:'https://dialogselfcare.s3.ap-southeast-1.amazonaws.com/dialogdigitizationservices/productcatalogue/dtv/4/127-Animal-Planet-HD.png'},
  {  id: 672152,  name: 'AXN HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/128.png'},
  {  id: 291678,  name: 'ROCK Entertainment HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/095.png'},
  {  id: 339372,  name: 'Star Movies Select HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/126.png'},
  {  id: 760299,  name: 'HBO HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/080.png'},
  {  id: 662507,  name: 'Star Movies HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/079.png'},
  {  id: 565762,  name: 'Cinema World HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/087.png'},
  {  id: 527873,  name: 'CineMax HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/084.png'},
  {  id: 319292,  name: 'HBO Hits HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/082.png'},
  {  id: 199629,  name: 'Sri Dalada Maligawa Telecast', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/1.png'},
  {  id: 763087,  name: 'Star Sports HD 1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/130.png'},
  {  id: 677135,  name: 'Star S Select HD 1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/132.png'},
  {  id: 127757,  name: 'Star S Select HD 2', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/133.png'},
  {  id: 721272,  name: 'Sony Sports 2 HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/sony_sports_2_HD.jpeg'},
  {  id: 610885,  name: 'Sony Sports 5 HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/sony_sports_5.jpeg'},
  {  id: 346708,  name: 'Premier Sports HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/129_PRE.png'},
  {  id: 864716,  name: 'Papare HD', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/1.png'},
  {  id: 366342,  name: 'Star Sports 1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/073.png'},
  {  id: 436398,  name: 'Star Sports 1', bg:'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/074.png' }
  

];

const itemsPerPage = 8;

const SearchAndPaginate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
   <div class="section" id="dlg">
   <div class="container">
    <div class="section-header">
              Dialog Tv 
              
        
        <div class="searchbox">


        <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
        </div>
      </div>
    
     

     <div class="carousel-nav-center">
       <ul>
       {paginatedData.map(item => (
            <div class="tvcontent">
            <li class="tvcontent"> <Link to={'/TVVideoPlayer/'+ item.id}>
            <a href='./play/?playid={$vid}'  class='movie-item'>
             <img src={item.bg} alt=''/>
             <div class='movie-item-content'>
                 <div class='movie-item-title'>
                 {item.name}
                 </div>
                 <div class='movie-infos'>
                    Dialog TV Live
                    
                 </div>
             </div>
         </a>
   
   
   
   
       </Link>
      </li></div>
          ))}
        </ul></div>

      <div class="pagebuttonrow">
        <button class="pagebutton"
          onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span  class="pagebuttontext">{`Page ${currentPage} of ${totalPages}`}</span>
        <button class="pagebutton"
          onClick={() =>
            setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div></div>
    </ div>
  );
};

export default SearchAndPaginate;
