import React, { useState } from 'react';
import data from './data/data';          
import { Link } from 'react-router-dom';
import Header from './component/header';
import './style.css';



const itemsPerPage = 20;

const SearchAndPaginate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter(item =>
    item.Title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
                 <Header/>
    <div class="headdata">
    <div class="section-header">
              ALL
              
        
        <div class="searchbox">


        <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
        </div>
      </div>
     </ div>
     

      {/* <ul>
        {paginatedData.map(item => (
          <li key={item.id}>{item.title}</li>
        ))}
      </ul> */}
       <ul>
       {paginatedData.map(item => (
            
            <li > <Link to={'/Films/filmVideoPlayer/'+ item.id}>
                
              <a title={item.Introduction} href="./#" class="movie-item">
                    <img src={item.MovieImage} alt=""/>
                    <div class="movie-item-content">
                        <div class="movie-item-title">
                        {item.Title}
                        </div>
                        <div class="movie-infos">
                            <div class="movie-info">
                                {/* <i class="bx bxs-star"></i> */}
                                
                             
                            </div>
                            
                            <div class="movie-info">
                            <i class='bx bxl-imdb' ></i>
                            {item.IMDbRating}  

                            </div>
                            <div class="movie-info">
                            <i class='bx bxs-time'></i>{item.RunningTime}
                            </div>
                        </div>
                    </div>
                </a>
        
        
        
        
            </Link>
           </li>
          ))}
        </ul>

      <div class="pagebuttonrow">
        <button class="pagebutton"
          onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span  class="pagebuttontext">{`Page ${currentPage} of ${totalPages}`}</span>
        <button class="pagebutton"
          onClick={() =>
            setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SearchAndPaginate;
