import React from 'react'
// import { Link } from 'react-router-dom';
import Header from './component/header';
import Peotvgo from './peotv';
import Peotv from './peotvor';
import Peotvgo2 from './peotv2';
import Dlgtv from './dlgtv';
import './styletv.css';
          
           
export default function Main() {
  return (
    <div>
      
      <Header/>
      <Dlgtv/>
      <Peotvgo/>
      <Peotvgo2/>
      <Peotv/>
    </div>
  )
}
