import React, {  useState,  useRef } from 'react';
import { useParams} from 'react-router-dom';
import data from '../data/data';



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';






const VideoPlayer = () => {

    const { id } = useParams();
  
 
    const aaa= parseInt(id);
    const specificId = aaa;
    const playdata = data.find(array => array.id === specificId);
    // const linkx = (playdata.severid1.HD720p.Link);
  const backg = (playdata.MovieImage);
//   const Titlevid = (playdata.Title);
//   const Introduction = (playdata.Introduction);
  const S1v1080p = (playdata.severid1.FHD1080p.Link);
  const S1v720p = (playdata.severid1.HD720p.Link);
  const S1v360p = (playdata.severid1.SD480p.Link);
  const S2v1080p = (playdata.severid2.FHD1080p.Link);
  const S2v720p = (playdata.severid2.HD720p.Link);
  const S2v360p = (playdata.severid2.SD480p.Link);
  const S3v1080p = (playdata.severid3.FHD1080p.Link);
  const S3v720p = (playdata.severid3.HD720p.Link);
  const S3v360p = (playdata.severid3.SD480p.Link);
  
  const S1v1080pSize = (playdata.severid1.FHD1080p.size);
  const S1v720pSize = (playdata.severid1.HD720p.size);
  const S1v360pSize = (playdata.severid1.SD480p.size);
  const S2v1080pSize = (playdata.severid2.FHD1080p.size);
  const S2v720pSize = (playdata.severid2.HD720p.size);
  const S2v360pSize = (playdata.severid2.SD480p.size);
  const S3v1080pSize = (playdata.severid3.FHD1080p.size);
  const S3v720pSize = (playdata.severid3.HD720p.size);
  const S3v360pSize = (playdata.severid3.SD480p.size);
  
  
  
  




  const qualityOptions = [
    { label: 'Sever 1 Low Quality ' + S1v360pSize, src: S1v360p },
    { label: 'Sever 1 Medium Quality ' + S1v720pSize, src: S1v720p },
    { label: 'Sever 1 High Quality ' + S1v1080pSize, src: S1v1080p  },
    { label: 'Sever 2 Low Quality ' + S2v360pSize, src: S2v360p },
    { label: 'Sever 2 Medium Quality ' + S2v720pSize, src: S2v720p },
    { label: 'Sever 2 High Quality ' + S2v1080pSize, src: S2v1080p  },
    { label: 'Sever 3 Low Quality ' + S3v360pSize, src: S3v360p },
    { label: 'Sever 3 Medium Quality ' + S3v720pSize, src: S3v720p },
    { label: 'Sever 3 High Quality ' + S3v1080pSize, src: S3v1080p  },
  ];

//   const [currentQuality, setCurrentQuality] = useState(qualityOptions[0]);
//   const [isPlaying, setIsPlaying] = useState(false);

//   const videoRef = useRef(null);

//   const handleQualityChange = (index) => {
//     setCurrentQuality(qualityOptions[index]);
//     // Pause the video before changing the source
//     setIsPlaying(false);
//     videoRef.current.load();
//   };

//   const handleVideoLoaded = () => {
//     // Play the video once it's loaded
//     setIsPlaying(true);
//     videoRef.current.play();
//   };
const [currentQuality, setCurrentQuality] = useState(qualityOptions[0]);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);

  const handleQualityChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    setCurrentQuality(qualityOptions[selectedIndex]);
    // Pause the video before changing the source
    setIsPlaying(false);
    videoRef.current.load();
  };

  const handleVideoLoaded = () => {
    // Play the video once it's loaded
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  return (
    <div>
         <div class="datatop">

{[false].map((expand) => (
    <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
      <Container fluid>
        <Navbar.Brand href="../"> GHOST STREAM</Navbar.Brand>
        <div class="dextopserversel">
        <Navbar.Brand > Change Sever:    
        <select onChange={handleQualityChange}>
          {qualityOptions.map((option, index) => (
            <option key={index} value={index}>
              {option.label}
            </option>
          ))}
        </select>
      </Navbar.Brand> 
   
          
          
          </div>  
        
        
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
            GHOST STREAM
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="#action1">Home</Nav.Link>
              <Nav.Link>
              Change Sever:    
        <select onChange={handleQualityChange}>
          {qualityOptions.map((option, index) => (
            <option key={index} value={index}>
              {option.label}
            </option>
          ))}
        </select>
              </Nav.Link>
           
 
           
  <NavDropdown title="Download" id="basic-nav-dropdown">
          <NavDropdown.Item href={S1v1080p}>SEVER 01 Size {S1v1080pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S1v720p}>SEVER 01 Size {S1v720pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S1v360p}>SEVER 01 Size {S1v360pSize}</NavDropdown.Item>
          
          <NavDropdown.Divider />
          <NavDropdown.Item href={S2v1080p}>SEVER 02 Size {S2v1080pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S2v720p}>SEVER 02 Size {S2v720pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S2v360p}>SEVER 02 Size {S2v360pSize}</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href={S3v1080p}>SEVER 01 Size {S3v1080pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S3v720p}>SEVER 01 Size {S3v720pSize}</NavDropdown.Item>
          <NavDropdown.Item href={S3v360p}>SEVER 01 Size {S3v360pSize}</NavDropdown.Item>
        </NavDropdown>

            </Nav>
           
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
      ))}








</div>

{/*  */}

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
      <video
        ref={videoRef}
        controls
        width="80%"
        style={{ margin: '0 auto' }}
        height="70%"
        poster={backg}
        onEnded={() => console.log('Video ended')}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onLoadedData={handleVideoLoaded}
        onClick={handleVideoClick}

      >
        <source src={currentQuality.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      
    </div>
    </div>
  );
};

export default VideoPlayer;