import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styletv.css';

const data =  [
  { id:734754, name: 'Colors' , bg: 'https://www.slt.lk/sites/default/files/channel_images/101-Colors_0.jpg' },
  { id:734754,name: 'Kalaignar TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/106-KalaignarTV_0%20%281%29.jpg' },
  { id:116894,name: 'Isaiaruvi TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/107-IsaiaruviTV_0%20%281%29.jpg' },
  { id:77818,name: 'Sirippoli TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/108-SirippoliTV%20%281%29.jpg' },
  { id:659942,name: 'Sangamam TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/110-SangamamTV_0.jpg' },
  { id:565189,name: 'Meth TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/111-MethTV.jpg' },
  { id:418200,name: 'Pragna TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/112-PragnaTV_0.png' },
  { id:313401,name: 'Karma' , bg: 'https://www.slt.lk/sites/default/files/channel_images/113-Karma.jpg' },
  { id:786328,name: 'Sathi TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/115-Sathi%20TV.jpg' },
  { id:616112,name: 'TV Didula' , bg: 'https://www.slt.lk/sites/default/files/channel_images/117-TVDidula_0.jpg' },
  { id:198393,name: 'ThinethaTV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/118-ThinethaTV.png' },
  { id:287133,name: 'Event TV Plus' , bg: 'https://www.slt.lk/sites/default/files/channel_images/120-EventTVPlus_0.jpg' },
  { id:565151,name: 'SANDA TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/121-SANDATV.jpg' },
  { id:58179,name: 'Damsathara TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/126-DamsatharaTV%20%281%29.jpg' },
  { id:425092,name: 'Supreme TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/140-SupremeTV%20%281%29.jpg' },
  { id:356640,name: 'Bloomberg' , bg: 'https://www.slt.lk/sites/default/files/channel_images/160-Bloomberg_0.jpg' },
  { id:273188,name: 'NHK WORLD-JAPAN' , bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_0_0.jpg' },
  { id:745338,name: 'CCTV 4' , bg: 'https://mobitel.peotv.com/images/channel/180-CCTV4.jpg' },
  { id:458256,name: 'CGTN' , bg: 'https://d3hhg1rtk1dmrv.cloudfront.net/dialogdigitizationservices/productcatalogue/dtv/4/035.png' },
  { id:310585,name: 'English Club TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/190-EnglishClubTV%20%281%29.jpg' },
  { id:772456,name: 'Videsa 3-5' , bg: 'https://www.slt.lk/sites/default/files/channel_images/205-Videsa3-5.jpg' },
  { id:187211,name: 'Videsa DP Grade 6 - 8' , bg: 'https://www.slt.lk/sites/default/files/channel_images/208-Videsa%20DP%20Grade%206-8.jpg' },
  { id:34426,name: 'Videsa DP Grade 9 - OL' , bg: 'https://www.slt.lk/sites/default/files/channel_images/209-Videsa%20DP%209-OL.jpg' },
  { id:620886,name: 'AL Kuppiya Television' , bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelLogo_3.jpg' },
  { id:552435,name: 'Event TV Xtra' , bg: 'https://www.slt.lk/sites/default/files/channel_images/220-EventTVXtra.jpg' },
  { id:694113,name: 'Tata Play IPL' , bg: 'https://www.slt.lk/sites/default/files/channel_images/TATAIPL.png' },
  { id:617591,name: 'SLTMobitel Group Watch' , bg: 'https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/twkwpt.png' },
  { id:603597,name: 'Hiru TV HD' , bg: 'https://www.slt.lk/sites/default/files/channel_images/307-HiruTVHD.jpg' },
  { id:671970,name: 'Rupavahini' , bg: 'https://www.slt.lk/sites/default/files/channel_images/Rupawahini_0_0.jpg' },
  { id:593510,name: 'Channel Eye' , bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelEye_0_0.jpg' },
  { id:315997,name: 'ITN' , bg: 'https://www.slt.lk/sites/default/files/channel_images/003-ITN_0.jpg' },
  { id:152114,name: 'Derana' , bg: 'https://www.slt.lk/sites/default/files/channel_images/004-Derana_0.jpg' },
  { id:396426,name: 'CHARANA TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/005-CHARANATV_0.jpg' },
  { id:689906,name: 'Swarnawahini' , bg: 'https://www.slt.lk/sites/default/files/channel_images/006-Swarnawahini_0.jpg' },
  { id:454684,name: 'Vasantham' , bg: 'https://www.slt.lk/sites/default/files/channel_images/vasanthan_0.png' },
  { id:654909,name: 'Siyatha TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/021-Siyatha%20TV_0.jpg' },
  { id:248547,name: 'Haritha TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/HarithaTV_0.jpg' },
  { id:540769,name: 'Sirasa TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/sirasaTv_0.jpg' },
  { id:455464,name: 'Shakthi TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/011_Shakthi%20TV_0.jpg' },
  { id:647824,name: 'TV One' , bg: 'https://www.slt.lk/sites/default/files/channel_images/12-TV1.jpg' },
  { id:20844,name: 'TNL' , bg: 'https://www.slt.lk/sites/default/files/channel_images/TNL_0.jpg' },
  { id:145187,name: 'ADA DERANA 24' , bg: 'https://www.slt.lk/sites/default/files/channel_images/014-AdaDerana24x7_0_0.jpg' },
  { id:636543,name: 'Music Plus Television' , bg: 'https://www.slt.lk/sites/default/files/channel_images/015_Music%20Plus%20Television_0.jpg' },
  { id:83264,name: 'Nethra TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/016-NethraTV_0.jpg' },
  { id:383700,name: 'Art TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/017-Art-TV_0.jpg' },
  { id:575621,name: 'Three Vision' , bg: 'https://www.slt.lk/sites/default/files/channel_images/018-Three%20Vision.jpg' },
  { id:578779,name: 'Hiru TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/Hiru%20TV_0.png' },
  { id:452514,name: 'Event TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/020-EventTV_0.jpg' },
  { id:520346,name: 'Star Tamil' , bg: 'https://www.slt.lk/sites/default/files/channel_images/008-Star%20Tamil_0.jpg' },
  { id:771884,name: 'Rangiri TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_2.jpg' },
  { id:722716,name: 'CNN' , bg: 'https://www.slt.lk/sites/default/files/channel_images/023-cnn_0.jpg' },
  { id:722176,name: 'Times Now' , bg: 'https://www.slt.lk/sites/default/files/channel_images/162-TIMESNOW_0.jpg' },
  { id:422384,name: 'Al-Jazeera' , bg: 'https://www.slt.lk/sites/default/files/channel_images/alJazeera.jpg' },
  { id:719979,name: 'France 24' , bg: 'https://www.slt.lk/sites/default/files/channel_images/27-France24_0.jpg' },
  { id:89599,name: 'CinemaWorld' , bg: 'https://www.slt.lk/sites/default/files/channel_images/33-CinemaWorld.jpg' },
  { id:726573,name: 'Nflix' , bg: 'https://www.slt.lk/sites/default/files/channel_images/034-NFlix_0.jpg' },
  { id:403936,name: 'SONY SPORTS TEN 1' , bg: 'https://www.slt.lk/sites/default/files/channel_images/038-SONYSportsTen1.jpg' },
  { id:364864,name: 'Star Sports 1' , bg: 'https://www.slt.lk/sites/default/files/channel_images/041-StarSports1%20%281%29.jpg' },
  { id:20647,name: 'Eurosport' , bg: 'https://www.slt.lk/sites/default/files/channel_images/042-Eurosport_0.jpg' },
  { id:368458,name: 'Ten Cricket' , bg: 'https://www.slt.lk/sites/default/files/channel_images/tenCricket_0.png' },
  { id:472737,name: 'SONY SPORTS TEN 2' , bg: 'https://www.slt.lk/sites/default/files/channel_images/045-SONY%20Sports%20Ten%202_6.jpg' },
  { id:490522,name: 'Premier Sports' , bg: 'https://www.slt.lk/sites/default/files/channel_images/ChannelLogo_0_0.jpg' },
  { id:162212,name: 'FOX Life' , bg: 'https://www.slt.lk/sites/default/files/channel_images/052-FOXlife.jpg' },
  { id:179279,name: 'TravelChannel' , bg: 'https://www.slt.lk/sites/default/files/channel_images/Travel%20Channel_0.jpg' },
  { id:195208,name: 'Discovery Tamil' , bg: 'https://www.slt.lk/sites/default/files/channel_images/056-DiscoveryTamil_0.jpg' },
  { id:427254,name: 'NatGeo Wild' , bg: 'https://www.slt.lk/sites/default/files/channel_images/NatGeoWild_0.png' },
  { id:765559,name: 'Da Vinci' , bg: 'https://www.slt.lk/sites/default/files/channel_images/058-DaVinci_0.jpg' },
  { id:532378,name: 'Nickelodeon' , bg: 'https://www.slt.lk/sites/default/files/channel_images/nick_0.png' },
  { id:100621,name: 'Planet FUN' , bg: 'https://www.slt.lk/sites/default/files/channel_images/061-PlanetFUN_0.jpg' },
  { id:162761,name: 'Cbeebies' , bg: 'https://www.slt.lk/sites/default/files/channel_images/062-Cbeebies_0.jpg' },
  { id:404236,name: 'BabyTV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/babyTv_0.png' },
  { id:38549,name: 'Cartoon Network' , bg: 'https://www.slt.lk/sites/default/files/channel_images/cn_1.png' },
  { id:773081,name: 'Pogo' , bg: 'https://www.slt.lk/sites/default/files/channel_images/pogo_0.png' },
  { id:594195,name: 'MTV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/Mtv_0.gif' },
  { id:596260,name: 'Stingray CMusic' , bg: 'https://www.slt.lk/sites/default/files/channel_images/069-StingrayCmusic_0.jpg' },
  { id:147949,name: 'ZEE TAMIL' , bg: 'https://www.slt.lk/sites/default/files/channel_images/072-ZEETAMIL_0.jpg' },
  { id:96557,name: 'Star Vijay' , bg: 'https://www.slt.lk/sites/default/files/channel_images/073-StarVijay.jpg' },
  { id:613976,name: 'Colors Tamil' , bg: 'https://www.slt.lk/sites/default/files/channel_images/074-ColorsTamil_0.jpg' },
  { id:86035,name: 'ZEE CINEMA' , bg: 'https://www.slt.lk/sites/default/files/channel_images/076-ZEECINEMA_0.jpg' },
  { id:553947,name: 'ZEE TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/079-ZEETV_0.jpg' },
  { id:423113,name: 'zoom' , bg: 'https://www.slt.lk/sites/default/files/channel_images/082-Zoom_0.jpg' },
  { id:602657,name: 'ABC Australia' , bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_1_0.jpg' },
  { id:29961,name: 'ZEE CAFE' , bg: 'https://www.slt.lk/sites/default/files/channel_images/chImage_1_0.jpg' },
  { id:504322,name: 'Colors INFINITY' , bg: 'https://www.slt.lk/sites/default/files/channel_images/086-Colors%20INFINITI_0.jpg' },
  { id:593060,name: 'KBS World' , bg: 'https://www.slt.lk/sites/default/files/channel_images/kbsWorld_0.png' },
  { id:399568,name: 'Star Plus' , bg: 'https://www.slt.lk/sites/default/files/channel_images/088_Star%20Plus_0.png' },
  { id:585503,name: 'TV5MONDE Asie' , bg: 'https://www.slt.lk/sites/default/files/channel_images/090-TV5MONDEAsie.jpg' },
  { id:317363,name: 'Parliament' , bg: 'https://www.slt.lk/sites/default/files/channel_images/091-Parliament-Channel1_0.jpg' },
  { id:285432,name: 'DW' , bg: 'https://www.slt.lk/sites/default/files/channel_images/092-Deutsche-Welle_0.jpg' },
  { id:146779,name: 'Verbum TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/093_Verbum%20TV_0.jpg' },
  { id:650107,name: 'Jaya TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/094-JayaTV.jpg' },
  { id:30063,name: 'Siyesa TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/095-SiyesaTV%20%281%29.png' },
  { id:284021,name: 'The Buddhist' , bg: 'https://www.slt.lk/sites/default/files/channel_images/Buddhist_0.gif' },
  { id:785588,name: 'God' , bg: 'https://www.slt.lk/sites/default/files/channel_images/theGODChannel_0.jpg'},
  { id:253551,name: 'Sharddha TV' , bg: 'https://www.slt.lk/sites/default/files/channel_images/shardhaTv_0.png'}

];

const itemsPerPage = 20;

const SearchAndPaginate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
   <div class="section"id="peotv">
   <div class="container">
    <div class="section-header">
              Peotv
              
        
        <div class="searchbox">


        <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
        </div>
      </div>
    
     

     <div class="carousel-nav-center">
       <ul>
       {paginatedData.map(item => (
            <div class="tvcontent">
            <li class="tvcontent"> <Link to={'/TVVideoPlay/'+ item.id}>
            <a href='./play/?playid={$vid}'  class='movie-item'>
             <img src={item.bg} alt=''/>
             <div class='movie-item-content'>
                 <div class='movie-item-title'>
                 {item.name}
                 </div>
                 <div class='movie-infos'>
                    PeoTV Live
                    
                 </div>
             </div>
         </a>
   
   
   
   
       </Link>
      </li></div>
          ))}
        </ul></div>

      <div class="pagebuttonrow">
        <button class="pagebutton"
          onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span  class="pagebuttontext">{`Page ${currentPage} of ${totalPages}`}</span>
        <button class="pagebutton"
          onClick={() =>
            setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div></div>
    </ div>
  );
};

export default SearchAndPaginate;
