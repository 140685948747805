

// export default VideoPlayer;
import React,  { useEffect }from 'react';
import { useParams} from 'react-router-dom';
import './css/player.css'
import './css/skin.css'
// Make sure your component name starts with an uppercase letter
const VideoPlayer = () => {
  //select play id
  const { id } = useParams();
  const data = [
    {id:6179107746,name:'Kalaignar TV',link:'https://edge4-moblive.yuppcdn.net/drm1/smil:kalaignartvdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/kalaignar-tv.png'},
    {id:10206584814,name:'Isaiaruvi TV',link:'https://edge3-moblive.yuppcdn.net/drm1/smil:isaiaruvitvdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/isaiaruvi-tv.png'},
    {id:3429360995,name:'Sirippoli TV',link:'https://edge4-moblive.yuppcdn.net/drm1/smil:sirippolitvdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sirippoli-tv.png'},
    {id:10703093150,name:'Event TV Plus',link:'https://edge4-moblive.yuppcdn.net/drm/smil:eventtvplusdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/event-tv-plus.png'},
    {id:2318458994,name:'English Club TV',link:'https://edge3-moblive.yuppcdn.net/drm1/smil:englishclubtvdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/pgldcz.png'},
    {id:1615086710,name:'Event TV',link:'https://sltlivetestgcp.yuppcdn.net/drm/smil:eventtvdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/event-tv.png'},
    {id:7678646335,name:'Times Now',link:'https://edge4-moblive.yuppcdn.net/drm/smil:timesnowdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/qigtck.png'},
    {id:10286132299,name:'Al-Jazeera',link:'https://edge4-moblive.yuppcdn.net/drm/smil:aljazeeradrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/aljazeera-aljazeera.png'},
    {id:1503124820,name:'FRANCE 24',link:'https://edge4-moblive.yuppcdn.net/drm/smil:france24drm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/france-france-24.png'},
    {id:10835612687,name:'Nflix',link:'https://edge4-moblive.yuppcdn.net/drm/smil:nflixdrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/nflix-nflix.png'},
    {id:6755218736,name:'SONY SPORTS TEN 5',link:'https://edge4-moblive.yuppcdn.net/drm1/smil:tensports5drm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sony-sports-ten-5.png'},
    {id:5341487014,name:'SONY SPORTS TEN 1',link:'https://edge4-moblive.yuppcdn.net/drm1/smil:tensports1drm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sony-sports-ten-1.png'},
    {id:4360034788,name:'SONY SPORTS TEN 2',link:'https://edge4-moblive.yuppcdn.net/drm1/smil:tensports2drm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/sudpjb.png'},
    {id:3158049974,name:'Planet FUN',link:'https://edge4-moblive.yuppcdn.net/drm/smil:planetfundrm.smil/manifest.mpd',key:'0cdfcfe0d0f1fbe100554ce3ef4c4665',kid:'9872e439f21f4a299cab249c6554daa3',bg:' https://mobond.yuppcdn.net/peotvgo/320/280/content/common/channel/logos/planet-fun.png'},


    
   ];
  const aaa= parseInt(id);
  const specificId = aaa;
  const playdata = data.find(array => array.id === specificId);
  const linkx = (playdata.link);
  const keyx = (playdata.key);
  const kidx = (playdata.kid);
  const backg = (playdata.bg);

  
  // finding the object whose id is '3'
  useEffect(() => {

   


  //   // Load JWPlayer script dynamically
    const script = document.createElement('script');
    script.src = 'https://ssl.p.jwpcdn.com/player/v/8.8.6/jwplayer.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
  //     // Setup the player
      const player = window.jwplayer('jw_player').setup({
        file: linkx,
        image: backg,
        drm: {
          clearkey: {
            key: keyx,
            keyId: kidx
          }
      }
    });
     
     

      const bumpIt = () => {
        const vol = player.getVolume();
        player.setVolume(vol + 10);
      }
      bumpIt();
    };

  //   // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
    };
  },[keyx, kidx, linkx, backg]);

  // printing object on the console
  return (
    <div>
     {/* {playdata ? (
        <div>
         <p>Name: {playdata.name}</p>
         </div>
      ) : (
        <p>Array not found</p>
      )} */}
            <div id="jw_player">Loading the player...</div>

    </div>
  );
};

export default VideoPlayer;
